.easy-breadcrumb {
  @include font-size(12);
  &_segment-front {
    text-decoration: none;
    padding-right: 1rem;
    color: transparent !important;
    @include icon(home, $replace: true) {
     color: $color-grey-darker;
    }
  }
  &_segment {
    color: $color-grey-darker;
    &:not(&-front) {
      padding: 0 0.5rem;
    }
    &:not(&-title),
    &-separator {
      color: $color-grey-dark;
    }
  }
}