.field--jp-vacsol-ds-vac-admin-menu {
  legend {
    border-bottom: none !important;
    cursor: pointer;
    position: absolute;
    top: $gutter-mobile/2;
    right: $gutter-mobile/2;
    width: auto !important;
    z-index: 1005;

    @include bp(md) {
      top: $gutter/2;
      right: $gutter/2;
    }

    @include icon('more') {
      transform: rotate(90deg);
    };

    .fieldset-legend {
      display: none;
    }
  }

  .fieldset-wrapper {
    background: $color-white !important;
    border: $default-border;
    display: none;
    padding: 1rem !important;
    position: absolute;
    right: $gutter-mobile/4;
    top: $gutter-mobile/4;
    z-index: 1000;

    @include bp(md) {
      right: $gutter/4;
      top: $gutter/4;
    }

    ul {
      margin: 0;
      list-style: none;

      a {
        border-bottom: $default-border;
        display: inline-block;
        padding: .5rem;
        text-decoration: none;
        width: 100%;
      }

      li {
        margin-bottom: 0;

        &:last-of-type {
          a {
            border-bottom: none;
          }
        }
      }
    }
  }

  fieldset.open {
    > legend {
      @include icon('close');
    }

    > .fieldset-wrapper {
      display: block !important;
    }
  }
}
