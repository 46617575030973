.p-item, .p-column {
  div[class^="field--"] + div[class^="field--"] {
    margin-top: 0.75rem;
    @include bp(md) {
      margin-top: 1.5rem;
    }
  }
}

.field--paragraph-tekst {
  // See also _nodes.scss
  line-height: 2rem;
  @extend .ul-in-text;
  a {
    @extend .inline-link;
  }
}

.field--paragraph-embed {
  margin: 0 auto;
  max-width: ($max-site-width / 3) * 2;
}

.field--paragraphs-columns {
  @include row();
}



