
// Menu
// --------------------------------------------------

ul.menu {
  li {
    margin-bottom: 0.5rem;
  }
  a {
    color: $color-text;
    font-weight: 400;
    &:hover {
      color: $color-link-hover;
    }
  }
  a.active-trail,
  a.active {
    color: $color-primary;
    // font-weight: 800;
  }
}

// Horizontal menu
// --------------------------------------------------

.horizontal-menu {
  ul.menu {
    li {
      float: left;
      margin-right: 1rem;
    }
  }
}

// Centered menu
// --------------------------------------------------

.centered-menu {
  ul.menu {
    position: relative;
    display: block;
    float: left;
    left: 50%;
    li {
      position: relative;
      display: block;
      float: left;
      right: 50%;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}


// Dropdown menu
// --------------------------------------------------

.dropdown-menu {

  // Globals
  ul.menu {
    li {
      position: relative;
      float: left;
      margin: 0 1rem 0 0;
      padding: 0;
    }
    > li:last-child {
      margin-right: 0;
    }
    a {
      display: block;
      padding: 0.5rem;
      @include font-size(16, 0);
    }
  }
  ul.menu ul { 
    display: none;
  }
  ul.menu li:hover > ul {
    display: block;
  }

  // Level 1 (Top level, always shown)
  ul.menu {
    > li {
      &.expanded > a {
        @include icon('arrow-down', after) {
          margin: 0 0 0 0.5rem;
        }
      }
    }
  }

  // Level 2+ (Sub levels, shown on hover)
  ul.menu ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 101;
    border: $default-border;
    background: $color-white;
    box-shadow: 0 3px 0 3px rgba($color-black, 0.03);
    > li {
      position: relative;
      float: none;
      margin: 0;
      width: 240px;
    }
    > li:not(:last-child) > a {
      border-bottom: $default-border;
    }
    > li.expanded > a {
      padding-right: 2rem;
      @include icon('arrow-right', after) {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  // Level 3+ (Sub sub levels)
  .menu-block-wrapper > ul.menu ul ul {
    top: 0;
    left: 100%;
  }
}

// Sidebar-nav (block)
.sidebar-nav {
  margin-bottom: $gutter-mobile;
  @include bp(md) {
    margin-bottom: $gutter;
  }
  ul[class*="menu"]:not(.contextual-links) {
    margin: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      a {
        @include font-size(14);
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        padding: 1rem 1.25rem;
        border-bottom: 1px solid $color-border;
        background-color: transparent;

        &:hover, &.active {
          color: $color-text;
          background-color: $color-grey;
        }
      }
    }
  }
}

.pane-ie-net-user-userprofile-sub-menu,
.pane-ie-net-user-userprofile-admin-menu {
  > .pane-content {
    > fieldset.form-wrapper {
      margin-top: $gutter-mobile;

      @include bp(md) {
        margin-top: $gutter;
      }

      > legend {
        border-bottom: 1px solid $color-border;
        background: $color-grey;
        cursor: pointer;
        font-weight: bold;
        margin-bottom: 0;
        padding: 1rem 1.25rem;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        transition: $default-transition;
        width: 100%;

        @include icon('plus', after) {
          @extend .field-set-before;
          position: absolute;
          right: 1rem;
          top: 11px;
        }

        &:hover,
        &:active {
          background-color: darken($color-grey, 10);
        }
      }

      &.open {
        > legend {
          @include icon('minus', after) {
            @extend .field-set-before;
          }
        }
      }
    }
  }
}

.field--jp-vacsol-ds-companypartner-menu {
  margin-bottom: $gutter-mobile;
  @include bp(md) {
    margin-bottom: $gutter;
  }
  ul {
    margin: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      a {
        @include font-size(14);
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        padding: 1rem 1.25rem;
        border-bottom: 1px solid $color-border;
        background-color: transparent;

        &:hover, &.active {
          color: $color-text;
          background-color: $color-grey;
        }
      }
    }
  }
}
