// View Companies
.view-search-companies {
  &.view-display-id-sticky_companies_pane {
    > .view-content {
      @include row();
      display: flex;
      flex-wrap: wrap;

      > .views-row {
        @include col();
        display: flex;
        margin-bottom: $gutter-mobile;

        @include bp(md) {
          @include cols(4);
          margin-bottom: $gutter;
        }
      }
    }
  }

  &.view-display-id-sticky_companies_pane {
    .more-link {
      a {
        @include button();
        @include jp-button-secondary();

        &:after {
          color: $color-white;
        }
      }
    }
  }
}


// Facet filters
.pane-current-search {
  .pane-title {
    cursor: initial !important;

    &:after {
      content: none;
    }
  }

  li {
    a {
      position: absolute;
      left: 0;
      top: 2px;
      text-indent: 200%;
      overflow: hidden;
      display: inline-block;
      height: 16px;
      width: 16px;
      padding-left: 25.5px;
      line-height: 16px;
      vertical-align: sub;
      text-decoration: none;

      @include icon('close') {
        color: black;
        font-size: 16px;
        position: absolute;
        left: 0;
        top: 0;
        margin-right: .5rem;
        text-indent: 0;
        transition: color $default-transition;
        text-decoration: none;
      }

      &:hover {
        &:before {
          color: $jp-color-red;
        }
      }
    }

    b {
      @include font-size(15 );

      text-transform: uppercase;
    }
  }
}

.pane-current-search,
.pane-facetapi {
  border-bottom: 1px solid $color-grey;
  transition: $default-transition;

  margin-bottom: $gutter-mobile/2;
  padding-bottom: $gutter-mobile/2;
  @include bp(md) {
    margin-bottom: $gutter/2;
    padding-bottom: $gutter/2;
  }

  .pane-title {
    cursor: pointer;
    font-weight: bold;
    position: relative;
    text-transform: uppercase;

    @include icon('arrow-up', after) {
      position: absolute;
      right: 0;
      transition: $default-transition;
    };
  }

  &.closed {
    padding-bottom: 0rem;

    h3:after {
      transform: rotate(180deg);
    }
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      position: relative;
      padding-left: 23.5px;
    }

    input.facetapi-checkbox {
      appearance: checkbox;
      position: absolute;
      left: 0;
      top: 0;
      height: 1rem;
      width: 1rem;
    }

    a.facetapi-checkbox {
      display: inline-block;
      text-decoration: none;
    }
  }

  .readmore-link {
    display: inline-block;
    margin-top: .5rem;
    text-decoration: none;

    @include icon('arrow-down', after) {
      margin-left: .25rem;
      transition: $default-transition;
    };

    &.open {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}
