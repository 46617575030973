.pane-jobportaal-vacatures-my-flagged-vacancies-pane,
.pane-jp-vacsol-jp-company-get-sollicaties {
  > .pane-content > .item-list {
    > ul {
      list-style: none;
      margin: 0;
    }
  }

  h2 {
    @include font-size(14, 22);
    background-color: $color-grey;
    border-bottom: $default-border;
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-bottom: 0;
    padding: 0.75rem;
    width: 100%;
    text-transform: uppercase;
    text-decoration: underline;
    transition: $default-transition;

    @include icon('minus') {
      @extend .field-set-before;
    }

    @include bp(md) {
      &:before {
        float: right !important;
        position: static !important;
      }
    }

    &:hover, &:active {
      background-color: darken($color-grey, 10);
    }
  }

  &.open {
    h2 {
      @include icon('plus') {
        @extend .field-set-before;
      }
    }
  }

  .pane-content {
    background-color: $color-grey;
    padding: $gutter-mobile;
  }
}


.pane-jp-vacsol-jp-company-get-sollicaties {
  .node--vacancy--small-teaser .flag-outer-vacancy-flag {
    display: none;
  }
}


.view-solr-vacatures{
  // Homepage vacatures
  &.view-display-id-sticky_vacancies_pane {
    .view-header {
      display: none;
    }

  }

  &.view-display-id-solr_vacatures_pane {
    > .view-content {
      > .views-row {
        margin-bottom: $gutter-mobile/2;

        @include bp(md) {
          margin-bottom: $gutter/2;
        }
      }
    }
  }
}

#views-exposed-form-solr-vacatures-solr-vacatures-pane {
  .views-exposed-form .views-exposed-widget {
    .page-jobportaal-vacatures & {
      .form-submit {
        margin-top: 1px;
        padding: 0.77rem 1.25rem;
      }
    }
  }

  .views-exposed-form > .views-exposed-widgets {
    @include bp(md) {
      display: flex;
    }

    > .views-exposed-widget {
      .form-select-wrapper,
      input,
      select {
        width: 100%;
      }

      input[type="text"] {
        padding: 10.6px;
      }

      .form-submit {
        margin-top: 4px;
      }

      .description {
        display: none;
      }

      .page-jobportaal-vacatures & {
        width: 100%;
        padding-right: 0;

        @include bp(md) {
          padding-right: 1rem;
          width: auto;
        }
      }
    }

    > .views-exposed-options {
      width: 100%;
      float: left;

      @include bp(md) {
        width: auto;
        display: flex;
      }

      > .views-reset-button,
      > .views-submit-button {
        width: 100%;
        padding-right: 0;

        @include bp(md) {
          width: auto;
          padding-right: 1em;
        }

        > button {
          width: 100%;

          @include bp(md) {
            width: auto;
          }
        }
      }
    }
  }
}


.view-jobportaal-vacatures {
  &.view-display-id-company_vacancies_pane {
    margin-bottom: $gutter-mobile/2;

    @include bp(md) {
      margin-bottom: $gutter/2;
    }
  }

  &.view-display-id-company_vacs_admin_topublish,
  &.view-display-id-company_vacs_admin_published,
  &.view-display-id-company_vacancies_backend {
    .view-content {

      .fieldset-wrapper {
        background: $color-grey;
      }

      .fieldset-title {
        cursor: pointer;
      }

      .views-row {
        margin-bottom: $gutter-mobile/2;

        @include bp(md) {
          margin-bottom: $gutter/2;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}


.page-profiel-jobportaal-cv {
  .pane-custom.pane-1 {
    @include jp-cta-block();
  }
}

.page-profiel-jobportaal-mijn-cv {
  .webform-component--talenkennis {
    .fieldset-wrapper .fieldset-wrapper {
      padding: 0 !important;

      @include row();

      > div {
        @include col();

        @include bp(md) {
          width: 50%;
        }
      }

      .form-item.label-inline {
        margin-bottom: 0;

        label {
          width: 7rem;
          margin-top: .5rem;
        }

        .form-select-wrapper {
          width: 100%;

          @include bp(md) {
            width: calc(100% - 7rem);
          }
        }
      }
    }
  }
}
