.node--vacancy {

  .field-offer,
  .field-requirements,
  .field--text-contactpers,
  .field--location {
    line-height: 1.625rem;
  }

  .field--jp-vacsol-ds-contracttype-postdate {
    .flag-wrapper,
    .field--civi-contracttype {
      display: inline-block;
    }

    .flag-wrapper {
      &:before {
        content: "-";
        margin: 0 0.1rem 0 .25rem;
      }
    }
  }

  // Shared stuff
  &--vacancy-soll-admin,
  &--small-teaser,
  &--teaser-alt,
  &--teaser {
    .field--civi-regio {
      display: inline !important;
      float: none !important;


      .field-items {
        display: inline;
        font-weight: bold;
        line-height: 26px;

        &:before {
          content: "-";
          display: inline-block;
          margin: 0 .25rem;
        }

        .field-item {
          display: inline-block;

          &:not(:last-of-type):after {
            content: ',';
            margin-right: .25rem;
          }
        }
      }
    }

    .field--civi-vactype {
      display: inline-block;
    }

    .vac-btn-wrapper {
      display: inline-block;

      > a {
        display: inline-block;

        &:before {
          content: "-";
          display: inline-block;
          margin: 0 .25rem 0 0;
        }
      }
    }
  }

  &--teaser-alt,
  &--teaser {
    .field--civi-regio .field-items {
      line-height: 16.8px;
    }
  }


  //&--teaser-alt,
  &--vacancy-soll-admin,
  &--small-teaser {
    padding: $gutter-mobile/2;
    margin: 0;
    background: $color-white;

    @include bp(md) {
      padding: $gutter/2;
    }

    .ds-header {
      > div {
        float: left;
      }

      .field--title {
        margin-right: 1rem;

        h3 {
          font-weight: normal;
        }

        a {
          text-decoration: underline;

          &:hover {
            color: $jp-color-red;
          }
        }
      }

      .field--views {
        color: $color-muted;
        line-height: 26px;
        display: inline-block;

        &:after {
          content: "-";
          display: inline-block;
          margin: 0 .25rem 0 .4rem;
        }
      }

      .field--company-ref,
      .field--location {
        .locality,
        a {
          line-height: 26px;
          display: inline-block;
        }
      }

      .field--company-ref {
        a {
          color: $color-muted;
          text-decoration: none;

          &:hover {
            color: $jp-color-red;
            text-decoration: underline;
          }
        }
      }
    }

    .field--body {
      margin: 1rem 0 !important;
    }
  }


  // View mode teaser: used on homepage, overviews, etc
  &--teaser-alt,
  &--teaser {
    background: $color-white;
    border: 1px solid transparent;
    border-left: 3px solid transparent;
    box-shadow: 0 0 0 transparent;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $gutter-mobile/2;
    transition: $default-transition;

    @include bp(md) {
      margin-bottom: $gutter/2;
    }

    &.node--sticky {
      border: 1px solid $color-grey;
      border-left: 3px solid $color-primary;
    }

    &:hover {
      box-shadow: $jp-box-shadow;
    }

    .image-wrapper {
      align-content: center;
      align-items: center;
      display: flex;
      order: 2;
      padding: $gutter-mobile;
      width: 100%;

      @include bp(md) {
        width: 198px;
      }

      > .ds-image {
        text-align: center;
        width: 100%;
      }
    }

    .content-wrapper {
      order: 1;
      padding: $gutter-mobile $gutter-mobile 0 $gutter-mobile;
      width: 100%;

      @include bp(md) {
        padding: $gutter-mobile 0 $gutter-mobile $gutter-mobile;
        width: calc(100% - 198px);
      }
    }

    .ds-center {
      margin-bottom: .5rem;

      > div {
        float: left;
      }
    }

    .ds-footer {
      .field--body {
        margin-bottom: .5rem;
      }
    }

    .field--title h2 a {
      text-decoration: underline;

      &:hover {
        color: $color-primary;
      }
    }

  }

  // View mode teaser alt: used on the general overview page
  // /jobportaal/vacatures
  //&--teaser-alt
  &--vacancy-soll-admin {
    border-bottom: 1px solid $color-grey;

    .field--jp-vacsol-ds-contracttype-postdate {
      > .field-items {
        > .field-item {
          > a {
            display: none;
          }
        }
      }
    }

    &.node--sticky {
      border: 1px solid $color-grey;
      border-left: 3px solid $color-primary;
    }

    .ds-header {
      .field--title {
        width: 100%;
        margin-right: 0;

        h3 {
          margin-bottom: 0;
        }
      }
    }
  }

  /*&--teaser-alt {
    padding: 0;
  }*/

  // View mode used on partner vacancies overview
  &--vacancy-soll-admin {
    padding: 0;

    .ds-header {
      padding: $gutter-mobile/2 $gutter-mobile/2 0 $gutter-mobile/2;

      @include bp(md) {
        padding: $gutter/2 $gutter/2 0 $gutter/2 ;
      }
    }

    .ds-center {
      padding: 0 $gutter-mobile/2;

      @include bp(md) {
        padding: 0 $gutter/2;
      }
    }

    .ds-footer {
      background: $color-white;
      border-top: 2px solid $color-grey;

      fieldset.form-wrapper {
        legend,
        .fieldset-wrapper {
          padding: $gutter-mobile/4 $gutter-mobile/2;

          @include bp(md) {
            padding: $gutter/4 $gutter/2;
          }
        }

        legend {
          border-bottom: 0 !important;
          font-weight: bold;
          position: relative;

          @include icon('arrow-down', after) {
            position: absolute;
            top: $gutter-mobile/4;
            right: $gutter-mobile/2;

            @include bp(md) {
              top: $gutter/4;
              right: $gutter/2;
            }
          };
        }

        &.closed {
          legend {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .field--jp-vacsol-ds-soll-overview {
      .form-wrapper {
        margin: 0;
      }

      legend,
      .fieldset-legend {
        cursor: pointer;
      }

      .fieldset-wrapper {
        border-top: 2px solid $color-grey;
        background: $color-white !important;
      }
    }
  }


  // View mode small teaser: used on overview pages (my jobs)
  &--small-teaser {
    border: 1px solid $color-grey;

    .ds-header {
      padding-right: 1.7rem;
    }

    .field--text-contactpers {
      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .flag-outer-vacancy-flag {
      margin-top: 1rem;
    }
  }


  // View mode sollicitation
  &--vacancy-soll {
    .field--logo {
      border: 1px solid $color-grey;
      padding: 2rem;
      display: flex;
      justify-content: center;

      margin-bottom: $gutter-mobile;
      @include bp(md) {
        margin-bottom: $gutter;
      }
    }

    .group-meta-wrapper {
      > div {
        background-color: $color-grey;
        padding: 1rem;

        .field-label {
          margin-bottom: .25rem;
        }

        & + div {
          padding-top: 0;
        }
      }

      .group-hidden {
        display: none;
      }
    }

    .field--infobox {
      border-bottom: 1px solid $color-grey;
      margin-bottom: $gutter-mobile/2;
      padding-bottom: $gutter-mobile/2;
      @include bp(md) {
        margin-bottom: $gutter/2;
        padding-bottom: $gutter/2;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .view-jobportaal-civicrm-contacts {
      .views-field {
        display: inline-block;
        margin-bottom: 1rem;
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }

        .views-label {
          display: inline-block;
          font-weight: bold;
          min-width: 150px;
          padding-right: 1rem;
        }
      }
    }

    .webform-client-form {
      .user--soll-profile-anonymous,
      .user--soll-profile {
        .ds-footer,
        .ds-header {
          > div {
            display: none;

            &.field-label-inline {
              display: block;
            }
          }
        }
      }
    }
  }


  // View mode full | default
  &--full {
    > .ds-center > .ds-second {
      margin-bottom: $gutter-mobile;
      @include bp(md) {
        margin-bottom: $gutter;
      }
    }

    .field--jp-vacsol-ds-companypartner-menu {
      .field-item img {
        display: none;
      }
    }

    .field--civi-vactype {
      display: inline-block;
    }

    .vac-btn-wrapper {
      display: inline-block;
      float: right;

      @include bp(sm, max) {
        width: 100%;
      }

      > span {
        &:before {
          content: none !important;
        }
      }

      > a {
        display: inline-block;

        @include bp(sm, max) {
         // float: none !important;
        }

        @include bp(md) {
          margin-left: 1rem;
        }
      }
    }

    .group-job-wrapper {
      border: 1px solid $color-grey;
      padding: $gutter-mobile/2;

      @include bp(md) {
        padding: $gutter/2;
      }
    }

    .group-vac-details {
      background: $color-grey;
      padding: 1rem 1rem 0 1rem;
      display: flex;
      flex-wrap: wrap;

      > div {
        margin-bottom: 1rem;
        width: 100%;

        @include bp(md) {
          width: 50%;

          &.field--vac-files {
            width: 100%;
          }
        }
      }

      .field-label {
        margin-bottom: .25rem;
      }
    }

    .field--jp-vacsol-ds-contracttype-postdate {
      border-bottom: 1px solid #e4e4e4;
      display: inline-block;
      line-height: 41px;
      margin-bottom: 1rem;
      width: 100%;

      a {
        float: right;

        @extend .button;
        @extend .button-grey-dark;
      }

      .flag-throbber {
        display: inline-block;
        width: 0;
      }
    }

    .field--vac-files {
      border-top: 1px solid #e4e4e4;
      padding-top: 1rem;
    }
  }
}

// Front-end form
.node-vacancy-form {
  .horizontal-tabs fieldset.horizontal-tabs-pane {
    padding: 0 $gutter-mobile/2;

    @include bp(md) {
      padding: 0 $gutter/2;
    }
  }

  .ds-2col-stacked-fluid {
    > .group-right,
    > .group-left {
      width: 100% !important;
      padding: 0;
    }

    > .group-header,
    > .group-right,
    > .group-left {
      margin-bottom: $gutter-mobile;

      @include bp(md) {
        margin-bottom: $gutter;
      }
    }

    > .group-right {
      padding: $gutter-mobile/2;

      @include bp(md) {
        padding: $gutter/2;
      }
    }
  }

  .form-item-title {
    input {
      width: 100% !important;
    }
  }

  .group-vac-gegevens {
    margin-top: 1.75rem;
  }

  .field-type-text-long,
  .field-type-text-with-summary {
    .filter-wrapper {
      padding: 0;
    }
  }

  .field-type-location,
  .field-type-link-field,
  .field-type-text-long,
  .field-type-text-with-summary,
  .field-type-list-integer,
  .field-type-list-text {
    margin-bottom: $gutter-mobile/2;

    @include bp(md) {
      margin-bottom: $gutter/2;
    }
  }

  .field-name-field-civi-talenkennis {
    margin-bottom: 0;
  }

  .field-name-field-civi-functieniv,
  .field-name-field-civi-vactype,
  .field-name-field-civi-contracttype,
  .field-name-field-civi-arbeidsregime {
    width: 50%;
    float: left;

    .form-select-wrapper,
    input {
      width: 100%;
    }
  }

  @include bp(md) {
    .field-name-field-civi-functieniv,
    .field-name-field-civi-contracttype {
      padding-right: $gutter/2;
    }

    .field-name-field-civi-vactype,
    .field-name-field-civi-arbeidsregime {
      padding-left: $gutter/2;
    }
  }

  .location .form-item {
    label.option,
    label {
      display: block;
      float: none;
      width: auto;
    }
  }

  .field-type-location {
    .fieldset-wrapper {
      @include row();

      display: flex;
      flex-wrap: wrap;

      > .form-item {
        .form-select-wrapper,
        input {
          width: 100%;
        }
      }

      > .form-item {
        clear: none;
        @include col();

        @include bp(md) {
          width: 50%;
        }
      }
    }
  }

  .field-name-field-sollicitatieprocedure {
    @include row();
    display: flex;
    flex-wrap: wrap;

    .form-type-select {
      @include col();
    }

    @include bp(md) {
      .form-type-select {
        width: 50%;
      }
    }
  }

  .form-select-wrapper {
    width: 100%;
  }

  .form-checkboxes {
    @include row();
    display: flex;
    flex-wrap: wrap;

    > .form-type-checkbox {
      @include col();

      @include bp(md) {
        width: 50%;
      }

      @include bp(lg) {
        width: 25%;
      }
    }
  }

  .link-field-subrow {
    @include row();
    display: flex;
    flex-wrap: wrap;

    .link-field-column {
      @include col();

      @include bp(md) {
        width: 50%;
      }

      .form-text {
        width: 100%;
      }
    }
  }

  .field-name-field-vac-files,
  fieldset.location {
    .fieldset-legend {
      @include font-size(18);
      font-weight: bold;
      color: $color-black;
    }
  }

  .field-name-field-vac-files,
  .form-item-field-sol-extern-link-und-0 {
    border-top: $default-border;
    padding-top: $gutter-mobile/2;

    @include bp(md) {
      padding-top: $gutter/2;
    }
  }

  .form-item-field-sol-extern-link-und-0 {
    > label {
      @include font-size(18);
      font-weight: bold;
    }
  }

  .form-actions {
    #edit-preview {
      display: none;
    }
  }
}

.webform-confirmation + .links {
  margin-bottom: $gutter-mobile;

  @include bp(md) {
    margin-bottom: $gutter;
  }
}
