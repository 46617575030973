#block-ie-net-afdeling-afdelingen-dropdown,
#block-ie-net-afdeling-afdelingen-dropdown-mobile {
  padding: 1.375rem 0;
  display: none;
  .ajax-progress {
    right: 1rem;
    left: auto;
    background-color: $color-grey-light;
  }

  .form-type-select {
    margin: 0;
  }
  form, label {
    margin: 0;
  }
  .form-select-wrapper {
    border: none;
    color: $color-grey-dark;
    background-color: $color-grey-light;
    border-radius: $button-border-radius;
    min-width: 100%;
    max-width: 8rem;
    &:hover, &:active {
      background-color: darken($color-grey-light, 5%);
    }
  }
  select {
    @include font-size(12);
    @include bp(md) {
      @include font-size(14);
    }
  }
}

#block-ie-net-afdeling-afdelingen-dropdown {
  @include bp(lg) {
    display: inline-block;
    width: 12rem;
  }
}

#block-ie-net-afdeling-afdelingen-dropdown-mobile {
  padding: 0;
  select {
    height: $toolbar-height-sm;
  }
  .form-select-wrapper {
    border-radius: 0;
  }
  @include bp(sm, max) {
    select {
      text-transform: uppercase;
      text-align: center;
      text-align-last: center;
    }
  }
  @include bp(md) {
    display: inline-block;
    float: right;
    width: 12rem;
    margin-right: -$gutter-mobile;
    .form-select-wrapper {
      //background-color: $color-white;
    }
    select {
      height: $toolbar-height-md;
      //height: 2.3125rem;
    }
  }
  @include bp(lg) {
    display: none;
  }
}