.pane-civicrm-membership-card {
  @include clearfix();

  .form-submit,
  #civicrm-membership-card-form {
    margin-bottom: 0;
  }

  .form-submit {
    float: right;
  }
}
