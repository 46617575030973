.paragraphs-item-werkervaring {
  .field--werkervaring-taken,
  .field-group-div,
  .field--werkervaring-company {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;

    > .description,
    > .field-label {
      width: 100%;
      margin-right: 0;
      margin-top: 0;
      padding-right: 1rem;

      @include bp(md) {
        width: 200px;
      }
    }

    > .field-content {
      @include bp(md) {
        width: calc(100% - 200px);
      }
    }

    > .description {
      color: #666;
      font-weight: bold;
      @include font-size(14);
    }
  }

  .ds-center + .ds-footer {
    margin-top: 0;
  }

  .field-group-div {
    margin-bottom: 0;
  }

  .field--werkervaring-taken,
  .field--werkervaring-company {
    > .field-label {
      margin-bottom: 0;
    }
  }

  .field--werkervaring-einde,
  .field--werkervaring-start {
    margin-right: .5rem;

    .field-label {
      font-weight: normal;
    }
  }

  .field--werkervaring-taken {
    margin-bottom: 0;

    &.label-inline {
      font-weight: initial;
    }
  }
}

.paragraphs-items-field-werkervaringen-full {
  .field--werkervaringen > .field-items > .field-item {
    margin: 0;
    border-bottom: 1px solid $color-border;
    margin-bottom: $gutter-mobile/2;
    padding-bottom: $gutter-mobile/2;

    @include bp(md) {
      margin-bottom: $gutter/2;
      padding-bottom: $gutter/2;
    }

    &:last-of-type {
      border-bottom: 0 !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
