// Mixins
// --------------------------------------------------

@mixin bp($point, $limiter: min) {
  // $point:    xs, sm, md, lg, xl
  // $limiter:  min (default), max, only

  @if $point == retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (min-device-pixel-ratio: 2) {
      @content;
    }
  } @else {

    @if $limiter == min {
      @if $point == xs {
        @media only screen and (min-width: $xs-width) {
          @content;
        }
      } @else if $point == sm {
        @media only screen and (min-width: $sm-width) {
          @content;
        }
      } @else if $point == md {
        @media only screen and (min-width: $md-width) {
          @content;
        }
      } @else if $point == lg {
        @media only screen and (min-width: $lg-width) {
          @content;
        }
      } @else if $point == xl {
        @media only screen and (min-width: $xl-width) {
          @content;
        }
      }
    }

    @if $limiter == max {
      @if $point == xs {
        @media only screen and (max-width: $sm-width - 0.01) {
          @content;
        }
      } @else if $point == sm {
        @media only screen and (max-width: $md-width - 0.01) {
          @content;
        }
      } @else if $point == md {
        @media only screen and (max-width: $lg-width - 0.01) {
          @content;
        }
      } @else if $point == lg {
        @media only screen and (max-width: $xl-width - 0.01) {
          @content;
        }
      } @else if $point == xl {
        @content;
      }
    }

    @if $limiter == only {
      @if $point == xs {
        @media only screen and (min-width: $xs-width) and (max-width: $sm-width - 0.01) {
          @content;
        }
      } @else if $point == sm {
        @media only screen and (min-width: $sm-width) and (max-width: $md-width - 0.01) {
          @content;
        }
      } @else if $point == md {
        @media only screen and (min-width: $md-width) and (max-width: $lg-width - 0.01) {
          @content;
        }
      } @else if $point == lg {
        @media only screen and (min-width: $lg-width) and (max-width: $xl-width - 0.01) {
          @content;
        }
      } @else if $point == xl {
        @media only screen and (min-width: $xl-width) {
          @content;
        }
      } @else {
        @content;
      }
    }

  }
}

// GRID
// ...
@mixin gutter($element, $half: false, $negative: false) {
  @if $half != false and $negative == false {
    #{$element} : $gutter-mobile/2;
  } @else if $half != false and $negative != false {
    #{$element} : -$gutter-mobile/2;
  } @else if $half == false and $negative != false {
    #{$element} : -$gutter-mobile;
  } @else {
    #{$element} : $gutter-mobile;
  }
  @include bp(lg) {
    @if $half != false and $negative == false {
      #{$element} : $gutter/2
    } @else if $half != false and $negative != false {
      #{$element} : -$gutter/2
    } @else if $half == false and $negative != false {
      #{$element} : -$gutter
    } @else {
      #{$element} : $gutter
    }
  }
}

@mixin container() {
  position: relative;
  margin: auto;
  @include gutter(padding-left);
  @include gutter(padding-right);
  max-width: $max-site-width;
}

@mixin row() {
  @extend .clearfix;
  @include gutter(margin-left, true, true);
  @include gutter(margin-right, true, true);
}

@mixin col() {
  position: relative;
  float: left;
  @include gutter(padding-left, true);
  @include gutter(padding-right, true);
  width: 100%;
}

@mixin cols($values...) {
  $max: length($values);
  @if ($max != 0) {
    $cols: nth($values, 1);
    width: percentage($cols / $grid-columns);
  } @else {
    width: 100%;
  }
}

@mixin cols-offset($values...) {
  $max: length($values);
  @if ($max != 0) {
    $cols: nth($values, 1);
    margin-left: 100% / ($grid-columns/$cols);
  }
}

@mixin cols-push($values...) {
  $max: length($values);
  @if ($max != 0) {
    $cols: nth($values, 1);
    left: 100% / ($grid-columns/$cols);
  }
}

@mixin cols-pull($values...) {
  $max: length($values);
  @if ($max != 0) {
    $cols: nth($values, 1);
    right: 100% / ($grid-columns/$cols);
  }
}

// UNIT CONVERSION
// Convert rems to pixels etc.

// Strip any unit from numeric variables
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// Pixel to rem
@function ptr($num, $showUnit: false) {
  @if ($showUnit) {
    @return #{strip-unit($num) / 16}rem;
  }
  @return strip-unit($num) / 16;
}

// Rem to pixels
@function rtp($num, $showUnit: false) {
  @if ($showUnit) {
    @return #{strip-unit($num) * 16}px;
  }
  @return strip-unit($num) * 16;
}

// FONT SIZE
// Calculate rem value from px size

// FONT SIZING
// Adds a default line-height for a font-size.
@mixin font-size($values...) {
  $max: length($values);
  $font-size: 16 !default;
  $line-height: false !default;

  // If font-size is supplied, override default
  @if $max != 0 {
    $font-size: nth($values, 1);
  }
  // If line-height is supplied, override default
  @if $max > 1 and type-of(nth($values, 2)) == number {
    $line-height: nth($values, 2);
  }
    // Else calculate line-height using $font-size
  @else if $line-height == false {
    $line-height: $font-size * $line-height-factor;
  }
  // Output supplied values
  @if $max != 0 {
    @if nth($values, $max) == "!important" {
      font-size: $font-size / 16 + rem !important;
      @if $line-height != 0 {
        line-height: $line-height / 16 + rem !important;
      }
    } @else {
      font-size: $font-size / 16 + rem;
      @if $line-height != 0 {
        line-height: $line-height / 16 + rem;
      }
    }
  }
    // Else output default values
  @else {
    font-size: $font-size / 16 + rem;
    line-height: $line-height / 16 + rem;
  }
}

// FONT SMOOTHING
// Better text rendering for modern browsers

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// APPEARANCE
// https://github.com/postcss/autoprefixer#why-doesnt-autoprefixer-support-appearance
@mixin appearance($style) {
  -moz-appearance: $style;
  -webkit-appearance: $style;
  appearance: $style;
}

// ICONS
// Add icon font character to element

@mixin icon($icon, $position: "before", $replace: false, $breakpoint: false) {
  @if $replace {
    font-size: 1px;
    color: transparent;
  }
  &:#{$position} {
    @extend .icon-#{$icon}:before;
    font-family: $icon-font;
    speak: none;
    @include font-size($icon-font-size, 0);
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    @if $replace and $breakpoint == false {
      font-size: 1rem;
    } @else if $breakpoint {
      font-size: 0;
    }
    @content;
  }
  @if $breakpoint {
    @include breakpoint($breakpoint) {
      &:#{$position} {
        @include font-size($default-font-size, 0);
      }
    }
  }
}

@mixin hide-icon-text($font-size: $icon-font-size, $color: $color-text) {
  font-size: 1px;
  line-height: 1px;
  letter-spacing: -1px;
  color: transparent;
  &:before,
  &:after {
    @include font-size($font-size, 0);
    color: $color;
    margin: 0;
    @content;
  }
}

// Gradients
// Example: @include linear-gradient(left, rgba($color-black, 1) 0%, rgba($color-white, 1) 100%);

@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

// Description

@mixin description() {
  font-weight: 300;
  @include font-size(12,20);
  color: $color-muted;
}

// clearfix;
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// Button

@mixin button() {
  display: inline-block;
  padding: 0.6rem 1.25rem;
  color: $color-button;
  text-align: center;
  @include font-size(12);
  font-weight: 700;
  //letter-spacing: 0.2em;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border-radius: $button-border-radius;
  border: $button-border;
  cursor: pointer;
  transition: $default-transition;
  margin-bottom: 1rem;
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
    color: $color-white;
    border-color: $color-link-hover;
    background-color: $color-link-hover;
    a {
      color: $color-white;
    }
  }
  &[class^='icon-'],
  &[class*=' icon-'] {
    line-height: 1rem;
    &:before,
    &:after {
      display: inline-block;
      margin-bottom: -0.2rem;
    }
    &:before {
      margin-right: 0.4rem;
    }
    &:after {
      margin-left: 0.4rem;
    }
  }
  &.hide-text {
    @include hide-icon-text(16, $color-button);
    width: 2.45rem;
    height: 2.45rem;
    padding: 0.6rem;
  }
}

// Jobportaal mixins

@mixin jp-button-primary() {
  color: $color-white;
  background-color: $jp-color-red;
  border: solid 1px $jp-color-red;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: lighten($jp-color-red, 10%);
    border-color: lighten($jp-color-red, 10%);
  }
}

@mixin jp-button-primary-alt() {
  color: $color-white;
  background-color: $jp-color-red-dark;
  border: solid 1px $jp-color-red-dark;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: lighten($jp-color-red-dark, 10%);
    border-color: lighten($jp-color-red-dark, 10%);
  }
}

@mixin hide-element() {
  display: block;
  height: 0;
  margin: 0;
  visibility: hidden;
  width: 0;
}

@mixin jp-button-secondary() {
  color: $color-white;
  background-color: $jp-color-blue;
  border: solid 1px $jp-color-blue;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: lighten($jp-color-blue, 10%);
    border-color: lighten($jp-color-blue, 10%);
  }
}

@mixin jp-button-white() {
  color: $color-black !important;
  background-color: $color-white;
  border: solid 1px $color-white;

  &:hover,
  &:focus {
    color: $color-white !important;;
    background-color: lighten($color-grey-darkest, 10%);
    border-color: lighten($color-grey-darkest, 10%);
  }
}

@mixin jp-cta-block() {
  background: $jp-color-blue;
  color: $color-white;
  padding: $gutter-mobile/2;
  text-align: center;

  @include bp(md) {
    padding: $gutter/2;
  }

  a {
    @include button();
    @include jp-button-primary();
    margin-bottom: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}
