.civi-view.ledenlijst {

  .views-exposed-form {
    text-align: left;
    .views-exposed-widget {
      float: left;
      margin-bottom: $default-block-margin-bottom-mobile;
      @include bp(md) {
        margin-bottom: $default-block-margin-bottom;
      }
    }
    .views-widget {
      display: block;
    }
    label {
      margin-bottom: 0.5rem;
    }
  }

  .views-exposed-options {
    clear: both;
    @include clearfix();
    /*    @include bp(lg) {
          clear: right;
          float: right;
        }*/
  }
  .views-exposed-widget {
    float: left;
    margin-right: 1.75rem;
    padding: 0;
    &:last-child {
      margin-right: 0;
    }
  }
  .views-row {
    @extend .clearfix;
    border: $default-border;
    border-left: 0.25rem solid $color-primary;
    padding: 0;
    margin: $default-block-margin-bottom-mobile 0;
    @include bp(md) {
      margin: $default-block-margin-bottom 0;
    }
  }
  .views-field {
    @include clearfix();
  }

  .field-content p {
    margin-bottom: 0;
  }

  .views-label,
  .field-content, fieldset legend {
    @include font-size(14, 24);
    display: inline-block;
  }

  .views-label,
  fieldset legend {
    font-weight: 600;
    margin-right: 0.75rem;
  }
  // Sidebars
  .left-sidebar,
  .right-sidebar {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include bp(md) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .right-sidebar {
    background-color: $color-grey-light;
  }

  // Fieldset-fields-groups
  .personal-data {
    text-align: center;
  }
  .ledenlijst-contact {
    margin-bottom: 1rem;
    @include bp(md) {
      margin-bottom: 0;
    }
  }
  .ledenlijst-private {
    border-bottom: $default-border;
    @include bp(md) {
      margin-bottom: 1.25rem;
      padding-bottom: 1.25rem;
    }
    > .views-field {
      margin-bottom: 0.5rem;
    }

  }
  .address-fields {
    @include icon('marker') {
      top: 0.25rem !important;
      bottom: 0 !important;
    }
    span {
      display: inline-block;
    }
  }
  .ledenlijst-contact {

  }

  .ledenlijst-opleiding.views-fieldset {
    > .views-fieldset {
      margin-bottom: 1rem;
    }
  }

  // Fields
  .views-field-industrieel-ingenieur-147,
  .views-field-burgelijk-ingenieur-148,
  .views-field-burgelijk-ingenieur-architect-150,
  .views-field-bio-ingenieur-149 {
    .views-label {
      @include font-size(16);
      margin-bottom: 0.4rem;
      color: darken($color-text, 10%);
    }
    .field-content {
      display: none;
    }
  }

  .views-field-curriculum-vitae-136 {
    a {
      &:before,
      &:after {
        @include font-size(44, 0);
        top: 5px;
        position: relative;
      }
      &:hover, &:active {
        &:before,
        &:after {
          color: lighten($color-text, 10%);
        }
      }
    }
  }
  .views-field-image-URL {
    margin-bottom: 0.75rem;
    img {
      max-width: 6.25rem;
    }
  }
  .no-profile-image {
    @include icon('user') {
      @include font-size(100, 100);
      background-color: $color-primary;
      color: $color-white;
      border-radius: 50%;
    }

  }
  .profile-linkedin {
    display: block;
    &:hover:before {
      background-color: darken($color-social-linkedin, 10%);
    }
    @include icon('linkedin') {
      background-color: $color-social-linkedin;
      color: $color-white;
      @include font-size(30, 30);
      padding: 0.375rem;
    }
    @include bp(md) {
      margin-top: 2rem;
    }
  }
  .views-field-curriculum-vitae-136 a {
    @include hide-icon-text();
    @include icon('file-cv') {
      @include font-size(44, 30);
    }
  }
  .views-field-url,
  div[class*="views-field-curriculum"] {
    display: inline-block;
  }
  .views-field-url {
    margin-left: 0.25rem;
  }
  .views-field-first-name,
  .views-field-last-name {
    .field-content {
      font-weight: 600;
      @include font-size(18, 24);
    }
  }
  .views-field-street-address,
  .views-field-country {
    display: block !important;
  }
  .views-field-street-address {

  }
  .views-field-phone,
  .views-field-email,
  .address-fields {
    position: relative;
    padding-left: 2rem;
    &:before {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .views-field-phone {
    @include icon('phone')
  }
  .views-field-email {
    @include icon('mail');
  }
}