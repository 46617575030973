// Search
// --------------------------------------------------
.search-block-form, #seagull-mobilemenu-block-search-form {
  padding: 2.875rem 0;
  form {
    margin: 0;
    padding: 0;
  }
  form, .content {
    display: inline-block;
  }
  label {
    display: none;
  }
  .or {
    margin: 0 0.5rem;
    display: none;
    @include bp(lg) {
      display: inline-block;
    }
  }
  .views-exposed-widgets {
    margin: 0;
    .views-exposed-widget {
      padding: 0;
      margin: 0;
      float: none;
    }
    display: inline-block;
    position: relative;
  }
  .views-exposed-form {
    display: block;
    position: relative;
    .views-exposed-options {
      margin: 0;
    }
    button {
      padding: 0 0.5rem;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 1px 1px 1px 0;
      border: none;
      background-color: $color-white;
      color: $color-text;
      &:hover {
        background-color: $color-grey;
        color: $color-text;
      }
      @include icon('search', 'before', true) {
        font-weight: 700;
        color: $color-text;
      }
    }
  }

  @include bp(md) {
    display: inline-block;
    .views-exposed-form {
      max-width: 100%;;
      width: 8.125rem;
    }
  }
  @include bp(lg) {
    .views-exposed-form {
      width: 10rem;
    }
  }
  @include bp(xl) {
    .views-exposed-form {
      width: 12rem;
    }
  }
}

.search-block-form.mobile {
  display: block;
  padding-bottom: 1.5rem !important;
  padding-top: 0 !important;
  .views-exposed-widgets {
    display: block;
  }
  form {
    display: block;
  }
  .form-type-textfield {
    margin: 0 auto;
    display: block;
    input {
      @include font-size(13);
      height: 2.1875rem;
      width: 100%;
      border-radius: $button-border-radius;
      background: $color-white;
      line-height: normal;
      padding: $form-padding 2rem $form-padding $form-padding;
      @include appearance(none);
    }
  }
}

.search-block-form {
  .form-type-textfield {
    margin: 0;
    input {
      @include font-size(13);
      height: 2.1875rem;
      width: 100%;
      border-radius: $button-border-radius;
      background: transparent;
      line-height: normal;
      padding: $form-padding 2rem $form-padding $form-padding;
      @include appearance(none);
    }
  }
  @include bp(sm, max) {
    display: none;
  }
}
