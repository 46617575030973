// Fields
// --------------------------------------------------
.field--bean-text {
  // See also _nodes.scss
  margin-bottom: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-bottom: $default-block-margin-bottom;
  }

  ul {
    @extend .check-list;
    margin-left: 0;
  }
  .bean-adverteren-ilya-magazine & {
    ul li {
      margin-bottom: 2rem;
    }
  }
}

.field--bean-cta-multiple {
  .field-item {
    display: inline-block;
    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// BEANS
// --------------------------------------------------
.entity-bean:not(.bean-big-cta) {
  .field--bean-cta-multiple,
  .field--bean-cta {
    a {
      @extend .button;
      @extend .button-grey-dark;
      @extend .button-cta;
      margin-bottom: 0;
    }
  }
}

.bean-big-cta, .bean-button-grid {
  position: relative;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
  &-gray {
    background-color: $color-grey;
    &:hover {
      background-color: darken($color-grey, 5);
    }
  }
  &-primary {
    background-color: $color-primary;
    &:hover {
      background-color: darken($color-primary, 5);
    }
    a, div, h3 {
      color: $color-white;
    }
  }
  &-secondary {
    background-color: $color-secondary;
    &:hover {
      background-color: darken($color-secondary, 5);
    }
    &:not(.bg-inserted) {
      a, div, h3 {
        color: $color-white;
      }
    }
  }
  &-dark-gray {
    background-color: $color-grey-dark;
    &:hover {
      background-color: darken($color-grey-dark, 5);
    }
    a, div, h3 {
      color: $color-white;
    }
  }
  .ds-center, .ds-header, .ds-footer {
    padding: 0 1.5rem;
  }
  .ds-header {
    padding-top: 1.5rem;
  }
  .ds-footer {
    position: absolute !important;
    bottom: $default-block-margin-bottom-mobile;
    @include bp(md) {
      bottom: $default-block-margin-bottom;

    }
  }
  .field--bean-text {
    padding-bottom: 5rem;
  }
}


.bean-big-cta {
  .field--bean-cta-multiple,
  .field--bean-cta {
    position: relative;
    text-transform: uppercase;
    @include icon(arrow-right, after) {
      margin-left: 0.375rem;
      position: absolute;
      top: 0;
      right: -1.375rem;
    }
  }
  .ds-footer {
    @include bp(md) {
      width: calc(100% - 35px);
    }
  }
}

.bean-button-grid {
  direction: ltr;
  &.bg-inserted {
    position: relative;
    * {
      position: relative;
      z-index: 6;
    }
    &:before {
      z-index: 5;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.17) 22%, rgba(0, 0, 0, 0.75) 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.17) 22%, rgba(0, 0, 0, 0.75) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.17) 22%, rgba(0, 0, 0, 0.75) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#bf000000', GradientType=1);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-gray.entity-bean {
    &:hover {
      .field--bean-cta-multiple a,
      .field--bean-cta a {
        background-color: darken($color-grey, 15);
        border: 1px solid darken($color-grey, 15);
      }

    }
    div.field--bean-cta-multiple,
    div.field--bean-cta {
      a {
        background-color: darken($color-grey, 10);
        border: 1px solid darken($color-grey, 10);
      }
    }
  }
  &-primary.entity-bean {
    &:hover {
      .field--bean-cta-multiple a,
      .field--bean-cta a {
        background-color: darken($color-primary, 15);
        border: 1px solid darken($color-primary, 15);
      }

    }
    div.field--bean-cta-multiple,
    div.field--bean-cta {
      a {
        background-color: darken($color-primary, 10);
        border: 1px solid darken($color-primary, 10);
      }
    }
    a, div, h3 {
      color: $color-white;
    }
  }
  &-secondary.entity-bean {
    &:hover {
      .field--bean-cta-multiple a,
      .field--bean-cta a {
        background-color: lighten($color-secondary, 15);
        border: 1px solid lighten($color-secondary, 15);
      }

    }
    div.field--bean-cta-multiple,
    div.field--bean-cta {
      a {
        background-color: lighten($color-secondary, 10);
        border: 1px solid lighten($color-secondary, 10);
      }
    }
  }
  &-dark-gray.entity-bean {
    &:hover {
      .field--bean-cta-multiple a,
      .field--bean-cta a {
        background-color: darken($color-grey-dark, 15);
        border: 1px solid darken($color-grey-dark, 15);
      }

    }
    div.field--bean-cta-multiple,
    div.field--bean-cta {
      a {
        background-color: darken($color-grey-dark, 10);
        border: 1px solid darken($color-grey-dark, 10);
      }
    }
    a, div, h3 {
      color: $color-white;
    }
  }
  h3 a {
    text-decoration: none;
  }
  .field--bean-text {
    padding-bottom: 3rem;
    margin-bottom: $gutter;
  }
  .field--bean-cta-multiple a,
  .field--bean-cta a {
    &:after {
      content: none !important;
    }
  }
}

.bean-header {
  .content-wrapper {
    @extend .container;
    @include bp(md) {
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
  }
  .ds-center {
    @extend .row;
  }
  .field--bean-title,
  .field--bean-text {
    @include col();
    z-index: 999;
    position: relative;
    color: $color-white;
    h1 {
      color: $color-white;
      @include font-size(20, 28);
    }
    @include bp(md) {
      max-width: 34rem;
      h1 {
        @include font-size(36, 52);
      }
      body.logged-in & {
        @include bp(md) {
          max-width: 80% !important;
        }
      }
    }
  }
  .field--bean-afbeelding {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
      max-width: none;
      width: auto;
    }
  }
  .field--bean-title {
    clear: right;
  }
  .field--bean-text {
    clear: left;
    @include bp(xs, max) {
      p {
        height: 0;
        color: transparent;
        a {
          display: inline-block;
          color: $color-white;
        }
      }
    }
    @include bp(md) {
      @include font-size(16, 36);
    }
    a.button {
      font-weight: 600;
      @include font-size(12, 16);
      margin-top: 0.5rem;
      @include bp(md) {
        margin-top: 0;
        @include font-size(13, 24);
      }
      &:hover {
        text-shadow: none;
      }
    }
  }
}
.bean-header.view-mode-default,
.hero-image {
  height: 23rem;
  position: relative;
  max-width: 125rem;
  margin: 0 auto;
  @include bp(md) {
    max-width: 125rem;
    height: 33rem;
  }
  @include bp(lg) {
    height: 42.25rem;
  }
  * {
    height: 100%;
  }
}
body.logged-in {
  .bean-header.view-mode-default,
  .hero-image {
    height: 23rem;
    position: relative;
    max-width: 125rem;
    margin: 0 auto;
    @include bp(md) {
      max-width: 125rem;
      height: 33rem;
    }
    @include bp(lg) {
      height: 38rem;
    }
    * {
      height: 100%;
    }
  }
}

// Fields
// --------------------------------------------------
.field--bean-text {
  // See also _nodes.scss
  margin-bottom: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-bottom: $default-block-margin-bottom;
  }

  ul {
    @extend .check-list;
    margin-left: 0;
  }
  .bean-adverteren-ilya-magazine & {
    ul li {
      margin-bottom: 2rem;
    }
  }
}

// BEANS
// --------------------------------------------------
.entity-bean:not(.bean-big-cta) {
  .field--bean-cta-multiple,
  .field--bean-cta {
    a {
      @extend .button;
      @extend .button-grey-dark;
      @extend .button-cta;
      margin-bottom: 0;
    }
  }
}

.bean-big-cta, .bean-button-grid {
  position: relative;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
  &-gray {
    background-color: $color-grey;
    &:hover {
      background-color: darken($color-grey, 5);
    }
  }
  &-primary {
    background-color: $color-primary;
    &:hover {
      background-color: darken($color-primary, 5);
    }
    a, div, h3 {
      color: $color-white;
    }
  }
  &-secondary {
    background-color: $color-secondary;
    &:hover {
      background-color: darken($color-secondary, 5);
    }
    &:not(.bg-inserted) {
      a, div, h3 {
        color: $color-white;
      }
    }
  }
  &-dark-gray {
    background-color: $color-grey-dark;
    &:hover {
      background-color: darken($color-grey-dark, 5);
    }
    a, div, h3 {
      color: $color-white;
    }
  }
  .ds-center, .ds-header, .ds-footer {
    padding: 0 1.5rem;
  }
  .ds-header {
    padding-top: 1.5rem;
  }
  .ds-footer {
    position: absolute !important;
    bottom: $default-block-margin-bottom-mobile;
    @include bp(md) {
      bottom: $default-block-margin-bottom;
    }
  }
  .field--bean-text {
    padding-bottom: 5rem;
  }

}

.bean-big-cta {
  .field--bean-cta-multiple,
  .field--bean-cta {
    position: relative;
    text-transform: uppercase;
    @include icon(arrow-right, after) {
      margin-left: 0.375rem;
      //position: absolute;
      //bottom: -2px;
    }
  }
  .ds-footer {
    @include bp(md) {
      width: calc(100% - 35px);
    }
  }
}

.bean-button-grid {
  direction: ltr;
  &.bg-inserted {
    position: relative;
    * {
      position: relative;
      z-index: 6;
    }
    &:before {
      z-index: 5;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.17) 22%, rgba(0, 0, 0, 0.75) 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.17) 22%, rgba(0, 0, 0, 0.75) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.17) 22%, rgba(0, 0, 0, 0.75) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#bf000000', GradientType=1);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-gray.entity-bean {
    &:hover {
      .field--bean-cta-multiple a,
      .field--bean-cta a {
        background-color: darken($color-grey, 15);
        border: 1px solid darken($color-grey, 15);
      }

    }
    div.field--bean-cta-multiple,
    div.field--bean-cta {
      a {
        background-color: darken($color-grey, 10);
        border: 1px solid darken($color-grey, 10);
      }
    }
  }
  &-primary.entity-bean {
    &:hover {
      .field--bean-cta-multiple a,
      .field--bean-cta a {
        background-color: darken($color-primary, 15);
        border: 1px solid darken($color-primary, 15);
      }

    }
    div.field--bean-cta-multiple,
    div.field--bean-cta {
      a {
        background-color: darken($color-primary, 10);
        border: 1px solid darken($color-primary, 10);
      }
    }
    a, div, h3 {
      color: $color-white;
    }
  }
  &-secondary.entity-bean {
    &:hover {
      .field--bean-cta-multiple a,
      .field--bean-cta a {
        background-color: lighten($color-secondary, 15);
        border: 1px solid lighten($color-secondary, 15);
      }

    }
    div.field--bean-cta-multiple,
    div.field--bean-cta {
      a {
        background-color: lighten($color-secondary, 10);
        border: 1px solid lighten($color-secondary, 10);
      }
    }
  }
  &-dark-gray.entity-bean {
    &:hover {
      .field--bean-cta-multiple a,
      .field--bean-cta a {
        background-color: darken($color-grey-dark, 15);
        border: 1px solid darken($color-grey-dark, 15);
      }

    }
    div.field--bean-cta-multiple,
    div.field--bean-cta {
      a {
        background-color: darken($color-grey-dark, 10);
        border: 1px solid darken($color-grey-dark, 10);
      }
    }
    a, div, h3 {
      color: $color-white;
    }
  }
  h3 a {
    text-decoration: none;
  }
  .field--bean-text {
    padding-bottom: 3rem;
    margin-bottom: $gutter;
  }
  .field--bean-cta-multiple a,
  .field--bean-cta a {
    &:after {
      content: none !important;
    }
  }
}

.pane-bean-newsletter-opt-in-paragraph-bloc {
  .optin-item-title {
    display: none;
  }

  button {
    @extend .button-primary;
    @extend .button-cta;
  }
}
