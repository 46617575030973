// Contribution
// --------------------------------------------------

// GENERAL HTML
.memberreg-container {
  #billing-payment-block {
    display: none !important; // Asked by Wouter H.
  }
  fieldset {
    margin-bottom: 0;
    legend {
      margin: 0 0 1.5rem 0;
    }
    .crm-section + .crm-section{
      margin-top: 1rem;
    }
  }
}

.memberreg-intro,
.memberreg-preview,
.memberreg-footer {
  line-height: 1.625rem;
  border: $default-border;
  padding: 1rem;
  margin-bottom: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-bottom: $default-block-margin-bottom;
  }
  ul, ol, p, a, img {
    &:last-child {
      margin-bottom: 0;
    }
  }
  legend {
    @extend h3;
  }
}

.memberreg-footer {
  @include font-size(12, 23);
}

// Blocks
.memberreg-block {
  border: $default-border;
  border-left: 0.25rem solid $color-primary;
  margin: 1.75rem 0;
  padding: 1.25rem 1rem 1.25rem 1.1875rem;
  legend {
    @extend h2;
  }
  label {
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}

// On behalf of
#onBehalfOfOrg {
  .section.crm-public-form-item,
  .section.form-item {
    margin-bottom: 1rem;
  }
  .editrow_email-3-section {
    margin-bottom: 1rem;
  }
}

// Payment processor
.payment_processor-section {
  .label {
    label {
      display: none;
    }
  }
}

// ThankYou
/*.crm-contribution-thankyou-form-block .memberreg-block {

  .content,
  .label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .label {
    font-weight: bold;
  }
}*/
