.node--verslag--teaser {
  border: $default-border;
  padding: 1.25rem 1.25rem 1.75rem 1.25rem;

  &:hover {
    background-color: $color-grey;
  }
  .field--datum {
    margin-bottom: 1rem;
  }
}