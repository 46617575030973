
// Toggle More
// --------------------------------------------------

.toggle-more-container {
  margin-bottom: 1rem;
  a.toggle-more-anchor {
    display: inline-block;
    margin-bottom: 1rem;
    font-weight: 700;
    &:before {
      text-decoration: none;
      margin-right: 0.5rem;
    }
  }
  div.toggle-more-content {
    display: none;
    padding-left: 2rem;
  }

  &.toggle-more-active {
    a.toggle-more-anchor {
      
    }
  }
}