// Views
// --------------------------------------------------

// Header

.view-header {
  @extend .lead;
  margin-bottom: $default-block-margin-bottom;
}

// Footer

.view .more-link {
  a {
    @extend .read-more;
  }
}

// Content
.view-content {
  @extend .clearfix;
}

// Empty view

.view-empty {
  .view-empty-content {
    @extend .messages;
    p {
      margin-bottom: 0;
    }
  }

}

// Exposed filters
// --------------------------------------------------

.views-exposed-widget {
  float: left;

  @include bp(sm, max) {
    & + .views-exposed-options,
    & + .views-exposed-widget {
      margin-top: 15px;
    }
  }
}
#views-exposed-form-search-companies-all-companies-search-pane{
  .views-widget-filter-title {
    .description {
      display: none;
    }
  }
}

.view-filters {
  // reset
  form,
  .form-item {
    margin: 0;
    padding: 0;
  }
  .views-exposed-form {
    margin: 1rem 0;
    @include bp(md) {
      margin: 0;
      text-align: right;
    }
  }
  // inline labels
  .views-exposed-widget {
    float: none;
    display: inline-block;
    padding: 0 1rem 0 0;
    @include bp(md) {
      padding: 0 0 0 1rem;
    }

    // submit
    .form-submit {
      margin: 0;
    }

    label {
      display: inline-block;
      width: auto;
      margin-right: 0.5rem;
    }
    .form-text,
    .views-widget,
    .form-select-wrapper {
      display: inline-block;
      max-width: 12rem;
    }
  }

  .form-select-wrapper {
    border: none;
    color: $color-grey-dark;
    background-color: $color-grey-light;
    border-radius: $button-border-radius;
    min-width: 100%;
    max-width: 8rem;
    &:hover, &:active {
      background-color: darken($color-grey-light, 5%);
    }
  }
  select {
    @include font-size(14);
  }
}

// Infinite scroll
// --------------------------------------------------

@keyframes infinite-scroll-loop {
  0% {
    color: $color-grey;
  }
  50% {
    color: $color-black;
  }
  100% {
    color: $color-grey;
  }
}

#views_infinite_scroll-ajax-loader {
  text-align: center;
  @include icon('more') {
    @include font-size(36);
    animation: infinite-scroll-loop 0.5s $easeInOut infinite;
  }
  img {
    display: none;
  }
}

// Load more
ul.pager.pager-load-more {
  li a {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    line-height: 2rem;
    @include icon('more');
  }
  .ajax-progress {
    position: absolute;
    top: 0.5rem;
    right: -2rem;
  }
}

// Civi-views
// --------------------------------------------------
// Afdeling-events
.civi-view-event {

  .views-fieldset {
    @extend .clearfix;
  }
  .field--datum,
  .field--type,
  .field--categorie .field-item,
  .field--thema {
    float: left;
  }

}

.civi-view.event-teasers {
  @include bp(md) {
    padding-left: $gutter;
  }
  .view-header {
    @extend .clearfix;
    margin-bottom: 0;
  }
  .h1-replaced {
    h1, div.h1-view-results {
      float: left;
    }
    h1 {
      margin-right: 0.5rem;
    }
    div.h1-view-results {
      @extend h1;
    }
  }
  $event-circle: 0.875rem;
  .views-row {
    border: $default-border;
    padding: 0 1.5rem 0.5rem 1.5rem;
    margin: 3rem 0;
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      left: -$gutter-mobile;
      //bottom: #{ptr(rtp(-$gutter), true)};
      //bottom: -3rem;
      top: 0;
      display: block;
      border-left: $default-border;
    }
    &:after {
      content: ' ';
      width: $event-circle;
      height: $event-circle;
      border-radius: 50%;
      position: absolute;
      left: -$gutter-mobile - ($event-circle / 2);
      top: (-$event-circle / 2);
      border-color: $color-border;
      background-color: $color-border;
      z-index: 90;
    }
    @include bp(md) {
      margin: 4.5rem 0;
      &:before {
        left: -$gutter;
        //bottom: #{ptr(rtp(-$gutter), true)};
        bottom: -4.5rem;
      }
      &:after {
        left: -$gutter - ($event-circle / 2);
      }
    }
    &.views-row-first {
      margin-top: 0;
    }
    &.views-row-last {
      &:before {
        content: none;
      }
    }
  }
  .views-fieldset {
    &.content-header {
      margin: -0.87rem auto 0rem -1.55rem;
      float: left;

      .views-field {
        @include font-size(10);
      }
      @include bp(md) {
        @include font-size(16);
        margin-bottom: 1rem;
      }
      @include bp(sm, max) {
        .field--thema {
          margin-bottom: 1rem;
        }
      }
    }
    &.content-footer {
      @include row();
      &-left,
      &-right {
        @include col();
      }
      &-left {
        @include bp(lg) {
          @include cols(4);
          clear: left;
        }
      }
      &-right {
        margin-top: $default-block-margin-bottom-mobile;
        @include bp(lg) {
          margin-top: 0;
          text-align: right;
          @include cols(8);
          float: right;
        }
      }
    }
  }

  .content-main {
    margin-top: $default-block-margin-bottom-mobile;

    @include bp(sm, max) {
      margin-top: 2rem;
    }
  }
  //fields
  //.field--type,
  //.field--thema {
  //  @include bp(xs, max) {
  //    clear: left;
  //    margin-left: $gutter-mobile;
  //  }
  //}

  .views-field-title * {
    word-wrap: break-word;
  }

  .field--datum {
    padding: 0.625rem 0.75rem;
    margin-top: -0.25rem;
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      left: -$gutter-mobile;
      border-top: $default-border;
      top: 1.0625rem;
      width: $gutter-mobile;
    }
    @include bp(xs, max) {
      margin-bottom: 0;
    }
    @include bp(md) {
      &:before {
        left: -$gutter;
        width: $gutter;
      }
    }
  }

  .field--type {
    @include bp(xs, max) {
      height: 32px;
      margin-top: -4px;

      span {
        line-height: 21px;
      }
    }
  }

  .field--text-civi {
    margin-bottom: 1.25rem;
    @include bp(lg) {
      margin-bottom: 1.5rem;
    }
  }

  .field--afdeling {
    display: inline-block;
    border-bottom: $default-border;
    padding: 0 1.25rem 0.75rem 0;
    margin-bottom: 0.75rem;
    @include bp(lg) {
      padding: 0 1.25rem 1.25rem 0;
      margin-bottom: 1.25rem;
    }
  }
  .field--register,
  .field--read-more {
    @include bp(sm) {
      float: left;
      width: 48%;
    }

    a {
      display: block;
      line-height: 19px;
    }
    @include bp(lg) {
      display: inline-block;
      float: none;
      width: auto;
      a {
        display: inline-block;
      }
    }
  }
  .field--read-more {
    @include bp(sm) {
      margin-right: 4%;
    }

    @include bp(lg) {
      margin-right: 0;
    }
  }
  .field--read-more {
    @include bp(lg) {
      margin-right: 1rem;
    }
  }
  .views-field-end-date,
  .views-field-start-date-1,
  .views-field-city {
    font-weight: 300;
    @include font-size(13, 18);
  }
  .views-field-city {
    padding-bottom: 0.25rem;
  }

}

.civi-view.event-teasers-short {
  .views-row {
    margin: 1.25rem 0;
    padding-bottom: 1.25rem;
    border-bottom: $default-border;
    @include bp(md) {
    }
    &-first {
      margin-top: 0;
    }
    &-last {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  .views-fieldset {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }

  }
}

.civi-view.view-search {
  .content-main {
    margin-top: 0.75rem;
  }
  .field--title {
    h3 {
      margin-bottom: 0;
    }
  }
}

// Grids
// --------------------------------------------------

// Teaser grid

.view-teaser-grid .view,
.view.view-teaser-grid,
.view-teaser-grid-five .view,
.view.view-teaser-grid-five,
.view.view-teaser-grid-50-50,
.view-teaser-grid-50-50 .view {
  @include row();
}

.view-full-width {
  .views-row {
    margin: $default-block-margin-bottom-mobile 0;
    padding-bottom: $default-block-margin-bottom-mobile;
    border-bottom: $darker-border;
    &:last-child {
      border: none;
    }
    @include bp(md) {
      margin: $default-block-margin-bottom 0;
      padding-bottom: $default-block-margin-bottom;
    }
    &.views-row-last {
      margin-bottom: 0;
    }
  }
}

.view-teaser-grid-five {
  .views-row {
    @include col();
    @include bp(xs) {
      @include cols(6);
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    @include bp(sm) {
      @include cols(4);
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
    @include bp(md) {
      @include cols(2.4);
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: none;
      }
      &:nth-child(5n+1) {
        clear: left;
      }
    }
  }
  .view-header {
    @include col();
  }
}

.view-teaser-grid-50-50 {
  .views-row {
    @include col();
    @include bp(md) {
      @include cols(6);
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    @include bp(lg) {
      @include cols(6);
    }
  }
  .view-header, .view-filters {
    @include col();
  }
  .view-header {
    @include bp(md) {
      @include cols(5);
    }
  }
  .view-filters {
    @include bp(md) {
      @include cols(7);
      float: right;
    }
  }
}

.view-teaser-grid {
  .views-row {
    @include col();
    margin-bottom: $default-block-margin-bottom-mobile;
    @include bp(sm) {
      @include cols(6);
    }
    @include bp(md, max) {
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    @include bp(md) {
      margin-bottom: $default-block-margin-bottom;
    }
    @include bp(lg) {
      @include cols(4);
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }
  .view-header {
    @include col();
  }
}

.view.isotope {
  padding-bottom: 60px;
  // pager
  .item-list {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

// Profile views
// --------------------------------------------------
.view-profile {
  .view-content {
    background-color: $color-grey-light;
    padding: $gutter-mobile/2;
    @include bp(md) {
      padding: $gutter/2;
    }
  }
  .views-row + .views-row {
    margin-top: $default-block-margin-bottom-mobile/2;
    @include bp(md) {
      margin-top: $default-block-margin-bottom/2;
    }
  }
  .views-field {
    @include font-size(14, 22);
  }
  .views-field-contextual-links {
    .views-label {
      display: none;
    }
    text-transform: capitalize;
  }
  .views-field-created {
    position: relative;
    &:after {
      content: '\007C';
      margin-right: 0.75rem;
      padding-left: 0.75rem;
      font-size: 1.5rem;
    }
  }
  .pager {
    margin-top: $default-block-margin-bottom-mobile/2;
    @include bp(md) {
      margin-top: $default-block-margin-bottom/2;
    }
  }
  // news
  &-news {

  }
  // events
  &-events {

  }
}

// contact / Ledenlijst
.view-profile-info {
  // See 'components/civi/ledenlijst';
}

// Nieuws
// --------------------------------------------------
.view-nieuws {
  .view-header {
    margin-bottom: 0;
  }

  .view-empty {
    clear: both;
  }
}

// FAQ
// --------------------------------------------------
.view-faq-categories {
  .view-faq--category {
    margin-bottom: $default-block-margin-bottom;
  }
}

// BEF - Better exposed filters
// --------------------------------------------------
.exposed-event-types {
  .views-exposed-widget {
    float: none;
    padding: 0;
    margin: 2rem 0;
    > label {
      margin-bottom: 0.75rem;
      display: block;
    }
    .form-select-wrapper {
      width: 100%;
      &:hover {
        border-color: $color-secondary;
      }
    }
    .views-widget {
      @include clearfix();
    }
    select {
      height: 2.3125rem;
      width: 100%;
      text-transform: capitalize;

    }
    @include bp(sm, max) {
      margin-top: 0;
    }
  }
}

.views-exposed-form .form-radios {
  .form-type-radio {
    width: 50%;
    float: left;
    border-right: $white-border;
    @include bp(md) {
      border-right: none;
      width: 100%;
    }

    label {
      @include font-size(12);
      color: $color-text;
      background-color: $color-grey;
      text-transform: uppercase;
      padding: 0.75rem 1rem 0.75rem 2.5rem;
      border-bottom: $white-border;
      width: 100%;
      &:before {
        margin: 0.75rem 0;
        top: 0;
        bottom: 0;
        left: 0.75rem;
      }
      &:hover,
      &:focus,
      &:active {
        color: $color-white;
        background-color: $color-secondary;
        &:before {
          border-color: $color-secondary;
        }
      }
      @include bp(md) {
        @include font-size(14);
      }
    }
    input:checked + label {
      color: $color-white;
      background-color: $color-secondary;
      &:before {
        background: $color-secondary url(../images/sprite.png) -1px -17px no-repeat;
        background-size: auto;
        border-color: $color-white;
        html.svg & {
          background: $color-secondary url(../images/sprite.svg) -1px -17px no-repeat;
          border-color: $color-white;
        }
      }
    }
  }
}


.bef-select-as-checkboxes {
  .form-type-bef-checkbox {
    width: 50%;
    float: left;
    border-right: $white-border;
    @include bp(md) {
      border-right: none;
      width: 100%;
    }

    label {
      @include font-size(12);
      color: $color-text;
      background-color: $color-grey;
      text-transform: uppercase;
      padding: 0.75rem 1rem 0.75rem 2.5rem;
      border-bottom: $white-border;
      width: 100%;
      &:before {
        margin: 0.75rem 0;
        top: 0;
        bottom: 0;
        left: 0.75rem;
      }
      &:hover,
      &:focus,
      &:active {
        color: $color-white;
        background-color: $color-secondary;
        &:before {
          border-color: $color-secondary;
        }
      }
      @include bp(md) {
        @include font-size(14);
      }
    }
    input:checked + label {
      color: $color-white;
      background-color: $color-secondary;
      &:before {
        background: $color-secondary url(../images/sprite.png) -1px -17px no-repeat;
        background-size: auto;
        border-color: $color-white;
        html.svg & {
          background: $color-secondary url(../images/sprite.svg) -1px -17px no-repeat;
          border-color: $color-white;
        }
      }
    }
  }
}


// BEAN VIEWS
// --------------------------------------------------
.button-grid-view {
  @include row();
  .views-row {
    article {
      &:before {
        content: none;
      }
    }
  }
  .views-row-first {
    .ds-header {
      h3 {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 1.5rem;
      }
    }

    .ds-footer {
      position: relative !important;
    }
  }
  @include bp(xs, max) {
    .views-row-wrapper {
      article {
        border-bottom: $default-border;
        background-color: $color-grey-dark;
        &:hover, &:active, &:focus {
          background-color: lighten($color-grey-dark, 10);
        }
      }
      .ds-center, .ds-footer {
        display: none;
      }
      .ds-header {
        padding: 0.75rem;
        h3 {
          margin-bottom: 0;
          position: relative;
          @include icon('arrow-right', after) {
            position: absolute;
            right: 0;
            line-height: 1.625rem;
          }
          a {
            text-decoration: none;
          }
        }
      }
    }
    .views-row-first {
      margin-bottom: $default-block-margin-bottom-mobile;
      article {
        &:before {
          content: "";
          background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 60%, rgba(0, 0, 0, 0.65) 100%);
          background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 60%, rgba(0, 0, 0, 0.65) 100%);
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 60%, rgba(0, 0, 0, 0.65) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
        }
      }
      .field--bean-text {
        padding-bottom: $default-block-margin-bottom-mobile;
      }
      .field--bean-cta-multiple,
      .field--bean-cta {
        width: 100%;
        a {
          padding: 0.75rem;
          width: 100%;
        }
      }
    }

  }
  @include bp(sm) {
    .ds-header h3 {
      &:after {
        content: none !important;
      }
    }
    .view-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      direction: rtl;
    }
    .views-row {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      width: 50%;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      padding: $gutter-mobile/2;
      margin-bottom: 0;
      min-width: 100%;
      display: flex;
      align-items: stretch;

      article {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
      }
    }
    .views-row-first {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      min-width: 60%;
      width: 60%;
      article {
        &:before {
          content: "";
        }
      }
    }
    .views-row-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      max-width: none;
      width: 40%;
      direction: ltr;
      .views-row {
        max-width: 50%;
        margin-bottom: 0;
      }
    }
  }
  @include bp(md) {
    .views-row {
      min-width: 100%;
    }
    .views-row-first {
      min-width: 50%;
      width: 50%;
      .ds-header {
        padding-top: 40%;
      }
    }
    .views-row-wrapper {
      width: 50%;
    }
  }
  @include bp(sm, max) {
    .views-row-wrapper {
      article {
        background-image: none !important;
        &.bean-button-grid-secondary {
          a, h3, p {
            color: $color-white;
          }
        }
      }
    }
  }
  @include bp(lg) {
    .views-row {
      min-width: 50%;
      padding: $gutter/2;
    }
    .views-row-wrapper {
      .views-row {
        &:nth-child(3n) {
          min-width: 100%;
        }
      }
    }
    .views-row-first {
      .ds-header, .ds-center, .ds-footer {
        margin-left: 40%;
      }
    }
  }
}

// Search VIEWS
// --------------------------------------------------
.view-search {
  .view-content {
    padding: $gutter-mobile;
    border: $default-border;
    background-color: $color-grey-light;
    @include bp(md) {
      padding: $gutter;
    }
  }
  .item-list {
    margin-top: 1rem;
  }
  article {
    margin-bottom: 0;
  }
  .views-row {
    padding: $default-block-margin-bottom-mobile 0;
    border-bottom: $default-border;
    @include bp(md) {
      padding: $default-block-margin-bottom 0;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
    .field--title {
      h3 {
        @include font-size(14, 26);
      }
    }
  }
}
