// Social media
// --------------------------------------------------
#block-ie-net-social-media-1,
#block-ie-net-social-media-2 {
  .social-intro {
    display: inline-block;
    margin-right: 0.5rem;
  }
  a.social-block-facebook {
    &:hover:before {
      background-color: darken($color-social-facebook, 10%);
    }
    @include icon('facebook', 'before', true) {
      background-color: $color-social-facebook;
      color: $color-white;
    }
  }
  a.social-block-linkedin {
    &:hover:before {
      background-color: darken($color-social-linkedin, 10%);
    }
    @include icon('linkedin', 'before', true) {
      background-color: $color-social-linkedin;
      color: $color-white;
    }
  }
  a.social-block-twitter {
    &:hover:before {
      background-color: darken($color-social-twitter, 10%);
    }
    @include icon('twitter', 'before', true) {
      background-color: $color-social-twitter;
      color: $color-white;
    }
  }
  .content a {
    text-decoration: none;
    display: inline-block;
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
    &:before {
      @include font-size(22, 22);
      padding: 0.375rem;
    }
  }
}

#block-ie-net-social-media-1 {
  float: right;
}

#block-ie-net-social-media-2 {
  margin-bottom: 2rem;
  clear:both;

  &:before {
    @extend .clearfix;
  }
  .social-intro {
    @include bp(xs) {
      display: block;
      padding-bottom: 0.5rem;
    }
    @include bp(lg) {
      display: inline-block;
    }
  }
  @include bp(sm) {
    clear: none;
  }

  @include bp(md) {
    margin-bottom: 0;
    float: right;
  }
}

.block-seagull-socialshare,
.block--social-share,
.field--social-share {
  margin-top: $default-block-margin-bottom;
  text-align: center;
  h4 {
    line-height: 2rem;
    display: inline;
    margin: 0 1rem 0 0;
  }
}

// Sharing links
// --------------------------------------------------

ul.socialshare-menu {
  display: inline-block;
  li {
    display: inline-block;
    margin: 0 0.5rem 0 0;
    a {
      display: inline-block;
      color: $color-white;
      border-radius: 100%;
      width: 2rem;
      height: 2rem;
      padding: 0.4rem;
      span {
        display: none;
      }

      &:before,
      &:after {
        color: $color-text;
      }
    }
  }
  li:last-child {
    margin-right: 0;
  }
  a.facebook {
    //background-color: $color-social-facebook;
    &:hover {
      &:before {
        color: $color-social-facebook;
      }
    }
  }
  a.twitter {
    //background-color: $color-social-twitter;
    &:hover {
      &:before {
        color: $color-social-twitter;
      }
    }
  }
  a.linkedin {
    //background-color: $color-social-linkedin;
    &:hover {
      &:before {
        color: $color-social-linkedin;
      }
    }
  }
  a.google-plus {
    //background-color: $color-social-google;
    &:hover {
      &:before {
        color: $color-social-google;
      }
    }
  }
}
