// Node
// --------------------------------------------------

.node {
  position: relative;

  &.node-unpublished {
    padding: 1rem;
    //color: white;
    background: repeating-linear-gradient(
      45deg,
      $color-unpublished,
      darken($color-unpublished, 1%) 10px,
      darken($color-unpublished, 5%) 10px,
      darken($color-unpublished, 5%) 20px
    );
  }

}

.ds-onclick {
  cursor: pointer;
  transition: color $default-transition;
  &:hover {
    color: $color-link;
  }
}

// Labels
// --------------------------------------------------

label.field-label,
div.field-label,
span.field-label {
  @extend label;
  display: inline-block;
  margin-right: 0.5rem;
}

.label-inline {
  @extend label;
  label.field-label,
  div.field-label,
  span.field-label,
  div.field-content {
    display: inline-block;
  }
}

// Fields
// --------------------------------------------------

.field--title {
  clear: left;
  > span {
    display: inline-block;
    font-size: 0.5em;
    line-height: 1em;
    color: $color-muted;
  }
}

.field--afdeling {
  * {
    color: $color-grey-darker;
  }
  label {
    font-weight: normal;
  }
}

.field--datum {
  @include font-size(12);
  font-weight: 600;
  background-color: $color-grey-dark;
  color: $color-white;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
}

.field--thema,
.field--categorie .field-item,
.field--type {
  @include font-size(12);
  font-weight: 600;
  background-color: $color-grey;
  padding: 0.375rem 0.75rem;
  display: inline-block;
  text-transform: uppercase;
}

.field--type {
  background-color: $color-secondary;
  color: $color-white;

  a {
    color: $color-white;
  }
}

.field--body,
.field--kort-antwoord,
.field--paragraph-tekst,
.field--text,
.field--bean-text,
.field--body-paragraph-civi {
  line-height: 1.625rem;
  a {
    @extend .inline-link;
  }
  ul, ol, p, a, img {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.field--body,
.field--kort-antwoord,
.field--paragraph-tekst,
.field--body-paragraph-civi {
  @extend .ul-in-text;
}

.field--node-link {
  a {
    @extend .read-more;
  }
}

.field--faq-vraag {
  position: relative;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  line-height: 18px;
}

.field--kort-antwoord {
  @extend .fade--text;
  @extend .fade--text--small;
}

.field--node-pager {
  @include clearfix;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include row();

  a {
    display: block;
    text-decoration: none;
    position: relative;

    &:hover {
      text-decoration: underline;
    }

    &:after,
    &:before {
      text-decoration: none !important;
    }
  }

  h3.node--title {
    margin-bottom: 0;
    @include font-size(14);
    font-weight: 400;
  }

  span.field--created {
    display: block;
    margin-bottom: 0.35rem;
    @include font-size(12);
  }

  .node-pager-next,
  .node-pager-prev {
    @include col();
    @include cols(6);
  }

  .node-pager-prev {
    a {
      padding-left: 3rem;
      @include icon(arrow-left) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include font-size(42);
        left: 0;
      }

      .field--created,
      .node--title {
        display: none;

        @include bp(md) {
          display: block;
        }
      }
    }
  }

  .node-pager-next {
    text-align: right;
    a {
      padding-right: 3rem;
      @include icon(arrow-right, after) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include font-size(42);
        right: 0;
      }

      .field--created,
      .node--title {
        display: none;

        @include bp(md) {
          display: block;
        }
      }
    }
  }
}

.field--gelinkte-blokjes {
  margin: $gutter-mobile 0;
  @include bp(md) {
    margin: $gutter 0;
  }
  @include row();
  > .field-items {
    > .field-item {
      @include col();
      @include bp(sm, max) {
        margin-bottom: $gutter-mobile;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include bp(md) {
        @include cols(6);
        &:nth-child(2n+1) {
          clear: left;
        }
        margin-top: $gutter-mobile;
        &:nth-child(-n+2) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      @include bp(lg) {
        @include cols(4);
        margin-top: $gutter;
        &:nth-child(-n+2) {
          margin-top: $gutter-mobile;
        }
        &:nth-child(-n+3) {
          margin-top: 0;
          margin-bottom: 0;
        }
        &:nth-child(2n+1) {
          clear: none;
        }
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
  > article {
    @include col();
    @include bp(md) {
      @include cols(6);
    }
    @include bp(lg) {
      @include cols(4);
    }
    @include gutter(margin-bottom);
    &:nth-child(3n+1) {
      clear: left;
    }
  }
}

.field--file {
  .file-icon {
    display: none;
  }
  a {
    @extend .button;
    @extend .button-inverse;
    @include icon(arrow-down, after) {
      @include font-size(14);
      margin-left: 0.5rem;
    }
  }
}

.file-banner,
.file-image {
  picture {
    display: block;
  }
}

.field--gerelateerde-nieuws-artikels {
  margin-top: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-top: $default-block-margin-bottom;
  }
  .view-header {
    @include font-size(14, 48);
    text-transform: uppercase;
    margin-bottom: 1rem
  }
}

.field--laatste-magazines {
  @extend .extendfull;
  background-color: $color-grey;
  padding-top: $gutter-mobile;
  @include bp(md) {
    padding-top: $gutter;
  }
}

.preprocess-news-magazine {
  position: absolute;
  top: 0.5rem;
  background: $color-white;
  color: $color-text;
  left: 0.5rem;
  padding: 2px 4px 4px 4px;
  text-align: center;
  opacity: 0.7;
  @include bp(md) {
    right: 0.5rem;
    left: auto;
  }
  .inline-ilya {
    @include font-size(24, 28);
    font-weight: 700;
  }
  .inline-year, .inline-month {
    @include font-size(10, 12);
    font-weight: 600;
  }
}

.field--ie-net-contextual-become-member-cta {
  margin: $default-block-margin-bottom-mobile 0;
  z-index: 2;
  position: relative;
  @include bp(md) {
    margin: $default-block-margin-bottom 0;
  }
  .cta-become-member {
    @extend .button;
    @extend .button-cta;
    @extend .button-primary;
  }
}

.field--content-ref {
  > .field-items {
    @include row();
    > .field-item {
      @include col();
      @include bp(sm) {
        @include cols(6);
      }
      @include bp(md, max) {
        &:nth-child(2n+1) {
          clear: left;
        }
      }
      @include bp(lg) {
        @include cols(4);
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
  .p-column & {
    .field-item {
      width: 100%;
    }
  }
}

// Field groups
// --------------------------------------------------
.ds-header-group {
  position: absolute;
  bottom: -0.625rem;
}

// View modes
// --------------------------------------------------

// Full
.node--full,
.node--protected-content {
  > header, > .ds-center {
    .field--datum, .field--banner, .field--categorie {
      margin-bottom: $default-block-margin-bottom-mobile;
      @include bp(md) {
        margin-bottom: $default-block-margin-bottom;
      }
    }
  }
  > .ds-center {
    .field--body,
    .paragraphs-items:not(.paragraphs-items-field-paragraphs-columns) {
      margin: $default-block-margin-bottom-mobile 0;
      @include bp(md) {
        margin: $default-block-margin-bottom 0;
      }
    }
  }
}

// List

.node--list {

}

// Search result

.node--search-result {
  margin-bottom: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-bottom: $default-block-margin-bottom
  }
}

// Teaser

.node--teaser,
.node--protected-content-teaser {
  margin-bottom: $gutter-mobile;
  @include bp(md) {
    margin-bottom: $gutter;
  }
  .ds-header {
    position: relative;
  }
}

.node--small-teaser {
  margin: $gutter-mobile 0;
  @include bp(md) {
    margin: $gutter 0;
  }
  .ds-header {
    position: relative;
  }
}

.node--protected-content-teaser {
  .field--ie-net-contextual-become-member-cta {
    margin: 1rem 0 0 0;
    a {
      margin: 0;
    }
  }
}

// Teaser Large

.node--teaser-large {
  margin-bottom: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-bottom: $default-block-margin-bottom
  }
}

// Protected Content
.node--protected-content,
.view-mode-protected_content,
.node--protected-content-teaser {
  .field--body,
  .field--paragraph-tekst {
    @extend .fade--text;
  }
}

.front .node--magazine--protected-content {
  .field--body {
    &:before {
      background: -moz-linear-gradient(top, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f5f5f5', GradientType=0); /* IE6-9 */
    }
  }
}
