// Settings
// --------------------------------------------------

// Fonts

$font-path: '../fonts/';
$icon-font: 'icons';
$default-font: "Open Sans", sans-serif;
$title-font: 'Open Sans', sans-serif;

// Font sizes

$default-font-size: 14;
$icon-font-size: 18;
$line-height-factor: 1.2;

// Breakpoints

$xs-width: 392px;
$sm-width: 542px;
$md-width: 692px;
$lg-width: 992px;
$xl-width: 1292px;

// Grid

$grid-columns: 12;
$max-site-width: 1200px;
$gutter-mobile: 1.5rem;
$gutter: 2.75rem;
$default-block-margin-bottom-mobile: 1.75rem;
$default-block-margin-bottom: 2rem;

// Colors

$color-primary: #ca273b; // main brand color
$color-primary-hover: lighten($color-primary, 10%);
$color-secondary: rgb(68, 161, 178);//#48838b; // secondary brand color
$color-background: #fff; // page background
$color-footer: rgb(42, 42, 42);

$color-white: #ffffff;
$color-black: #021710;
$color-grey-light: rgba(245, 245, 245, 1);
$color-grey: rgb(240, 240, 240); // grey backgrounds like inactive tabs etc.
$color-grey-medium: rgb(193, 193, 193);
$color-grey-darker: rgb(153, 153, 153);
$color-grey-dark: rgba(67, 73, 86, 1);
$color-grey-darkest: rgb(51, 51, 51);

$color-text: rgb(102, 102, 102); // default text color
$color-muted: #888888; // muted text like descriptions etc.
$color-link: $color-grey-dark;
$color-link-hover: lighten($color-link, 10%);

$color-status: #48b76d; // status messages
$color-warning: #ffa956; // warning messages
$color-error: #f14d51; // error messages

$color-border: rgb(222, 222, 222); // default border color,
$color-border-opacity: rgba(222, 222, 222, 0.5);
$color-border-darker: #999999;
$color-form-border: $color-border; // border color for input elements

$color-social-facebook: #3b5998;
$color-social-twitter: #55acee;
$color-social-linkedin: #007bb5;
$color-social-google: #dd4b39;
$color-social-youtube: #bb0000;
$color-social-rss: #ff9900;

$color-unpublished: #fff4f4;

// Borders

$default-border: solid 1px $color-border;
$opacity-border: 1px solid $color-border-opacity;
$white-border: solid 1px $color-white;
$darker-border: solid 1px $color-border-darker;
$thicker-border: solid 2px $color-border;
$default-border-radius: 4px;

// Forms

$form-font-size: 15;
$form-padding: 0.6rem;
$form-border: solid 1px $color-form-border;
$form-border-radius: 4px;
$color-form-hover: $color-primary-hover;
$color-form-focus: $color-primary;
$color-form-shadow: $color-grey;
$box-shadow: 2px 2px 5px $color-form-shadow;

// Buttons
$color-button-primary: rgb(168, 30, 31);
$color-button: $color-grey-dark;
$button-border: solid 2px $color-grey-dark;
$button-border-radius: $form-border-radius;

// Pager

$pager-font-size: 15;
$pager-padding: 0.5rem;

// Transition
// More examples at http://easings.net/

$easeIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOut: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOut: cubic-bezier(0.645, 0.045, 0.355, 1);

$default-transition-time: 0.4s;
$slow-transition-time: 0.6s;
$default-transition: $easeOut $default-transition-time;
$slow-transition: $easeOut $slow-transition-time;

// Opacity
$default-opacity: 0.8;

// Text Shadow
$default-text-shadow: 1px 1px rgba(0, 0, 0, 1);

// Other

//Toolbar Height
$toolbar-height-sm: 2.2rem;
$toolbar-height-md: 2.5rem;

// Jobportaal specifics
$jp-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.349019607843137);

$jp-color-red: $color-primary;
$jp-color-red-dark: rgba(159, 31, 46, 1);

$jp-color-blue: rgba(67, 73, 85, 1);
