.p-item, .p-columns {
  margin: $gutter-mobile 0;
  @include bp(md) {
    margin: $gutter 0;
  }
  &.first {
    margin: 0;
  }
  .ds-center + .ds-footer {
    margin-top: 1rem;
    @include bp(md) {
      margin-top: 1.25rem;
    }
  }
}

// Text with image
.p-text-teks-links-afbeelding-rechts,
.p-text-afbeelding-links-tekst-rechts {
  .ds-first {
    margin-bottom: $gutter-mobile;
    @include bp(md) {
      margin-bottom: 0;
    }
  }
}

// Text - Quote
.p-text-quote, .p-columns .view-mode-quote {
  .field--paragraph-tekst {
    @include font-size(19, 38);
    max-width: $max-site-width / 2;
    margin: 0 auto;
    text-align: center;
    font-weight: 300;
    @include bp(md) {
      @include font-size(24, 48);
    }
  }
}

// Embed
.p-embed {
  margin: ($gutter-mobile + 1) 0;
  @include bp(md) {
    margin: ($gutter + 1) 0;
  }
}

// Columns

.p-columns {
  &-1 {
    @include col();
  }
  &-2, &-3 {
    .p-column {
      @include bp(sm, max) {
        margin: $gutter-mobile 0;
        float: none;

        &.first {
          margin-top: 0;
        }
        &.last {
          margin-bottom: 0;
        }
      }
      @include col();
    }
  }
  &-2 {
    .p-column {
      @include bp(md) {
        @include cols(6);
        &:nth-child(2n+1) {
          clear: left;
        }
      }
    }
  }
  &-3 {
    .p-column {
      @include bp(md) {
        @include cols(4);
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
}

// CTA
.p-cta {
  @include row();
  .paragraphs-item-cta {
    @extend .node--page--teaser;
    background-color: transparent;
    @include col();
    @include bp(sm) {
      @include cols(6);
    }
    @include bp(md, max) {
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    @include bp(lg) {
      @include cols(4);
      &:nth-child(3n+1) {
        clear: left;
      }
    }
    .field--text {
      color: $color-white
    }
    .image-wrapper,
    .content-wrapper {
      background-color: $color-grey-dark;
    }
  }
  .field--link {
    a {
      @extend .read-more;
      color: $color-white;
      &:after {
        color: $color-white;
      }
    }
  }
  &.p-column {
    .paragraphs-item-cta {
      width: 100%;
    }
  }
}

// VCARD
.p-civicrm-vcard {
  .contact-civicrm {
    .contact-item {
      border: $default-border;
      border-left: none;
      border-left: 0.25rem solid $color-primary;
      padding: 1rem 1rem 1rem 1.1875rem;
      position: relative;
      z-index: 1;
      margin: 1.75rem 0;
      max-width: 38.75rem;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .contact-item-field {
      font-weight: 300;
      @include font-size(14, 23);
      a {
        font-weight: 400;
      }
    }
    .contact-img-wrapper {
      display: inline-block;
    }
    .contact-info-wrapper {
      display: inline-block;
      vertical-align: top;
    }
    .contact-img-wrapper {
      margin-right: 1rem;
      vertical-align: middle;
    }
    .contact-img {
      max-width: 6.25rem;
    }
    .contact-name {
      font-weight: 600;
      padding-bottom: 0.25rem;
    }
  }
}

