// CRM-GROUP (checkboxes and radios)
//*****************************************
.crm-group {
  div.label {
    @include bp(sm, max){
      margin-bottom: 1rem;
    }
  }
}
// SELECT2
//*****************************************
.select2-container {
  width: 31rem !important;
  max-width: 100%;
  &.select2-allowclear {
    .select2-search-choice-close {
      //background: red;
      display: inline-block !important;
      background-image: none !important;
      height: 100%;
      width: auto;
      position: absolute;
      right: 1.5rem;
      top: 0;
      bottom: 0;
      @include icon(close) {
        font-weight: 700;
        @include font-size(12, 18);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        color: $color-primary;
      }
    }
    .select2-arrow {
      display: none !important;
    }
  }
  .select2-choice {
    background: $color-white;
    border: $form-border;
    border-radius: $form-border-radius;
    padding: $form-padding;
    font-weight: 400;
    color: $color-text;
    height: auto;
    box-shadow: none;
    &:hover, &:focus {
      border-color: $color-form-focus;
    }
    .select2-search-choice-close {
      //display: none !important;
    }
    .select2-arrow {
      display: inline-block;
      background-image: none !important;
      background: transparent;
      height: 100%;
      width: auto;
      position: absolute;
      right: 1.5rem;
      top: 0;
      bottom: 0;
      border: none;
      b {
        display: none;
      }
      @include icon(arrow-down) {
        font-weight: 700;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1.375rem;
      }
      &:hover {
        color: $color-primary;
      }
    }
  }
}
// ERROR
//*****************************************
.crm-container .crm-error:not(.messages),
.crm-container .crm-inline-error:not(.messages) {
  background: lighten($color-error, 30) none repeat scroll 0 0;
  border: none;
  color: $color-error;
  display: inline-block;
}
.crm-container .crm-error:not(.messages) {
  padding: 0.25rem;
}