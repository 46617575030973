
// Homepage Ingenieurs

.pane-bean-jp-homepage-vacancies-search-0 {
  background: $color-grey;
  padding-top: 2rem;
  margin-bottom: $gutter-mobile * -1;
  z-index: 100;

  @include bp(md) {
    margin-bottom: $gutter * -1;
  }

  .pane-title,
  .ds-header,
  .ds-center {
    padding: 0 2rem;
  }

  .pane-title,
  .ds-header {
    text-align: center;
  }

  .field--bean-cta-multiple,
  .field--bean-cta {
    background: $color-primary;
    padding: 1rem;
    text-align: center;

    .field-item {
      display: inline-block;
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        @include jp-button-primary-alt();
      }
    }
  }

  .views-exposed-widgets {
    .views-exposed-widget {
      width: 100%;
      padding-right: 0;

      @include bp(md) {
        padding-right: 1rem;
        width: 40%;
      }

      .form-select-wrapper,
      select,
      input {
        width: 100%;
      }

      .description {
        display: none;
      }
    }

    .views-exposed-options {
      float: left;
      padding-right: 0;
      width: 100%;

      @include bp(md) {
        width: 20% !important;
      }

      > .views-exposed-widget {
        width: 100% !important;

        &.views-submit-button {
          padding-right: 0 !important;
          width: 100% !important;

          > button {
            width: 100% !important;
          }
        }

        &.views-reset-button {
          display: none;
        }
      }
    }

    .form-submit {
      background: $jp-color-red;
      border-color: $jp-color-red;
      border-radius: 0;
      color: $color-white;
      margin-top: 0;
      height: 43.2px;
      width: 100%;

      &:hover {
        background-color: lighten($jp-color-red, 10%);
        border-color: lighten($jp-color-red, 10%);
      }
    }
  }
}


.pane-bean-jp-maak-je-cv-cta {
  background: $color-primary;
  padding: 2rem;

  @include bp(md) {
    padding: 2rem 4rem;
  }

  .ds-header,
  .pane-title {
    color: $color-white;
  }

  .ds-footer {
    text-align: center;
  }

  .field--bean-cta-multiple,
  .field--bean-cta {
    .field-item a {
      @include jp-button-primary-alt();
    }
  }
}

.pane-bean-jp-ie-net-ontdekken,
.pane-bean-jp-eenvoudig-solliciteren {
  background: $color-white;

  padding: $gutter-mobile;
  margin-top: $gutter-mobile;
  @include bp(md) {
    padding: $gutter;
    margin-top: $gutter;
  }

  .field--bean-cta-multiple,
  .field--bean-cta {
    .field-item a {
      @include jp-button-primary();
    }
  }
}

.pane-bean-jp-ie-net-ontdekken {
}

.pane-bean-jp-lid-worden-van-ie-net {
  color: $color-white;
  background: $color-primary;
  position: relative;
  padding: $gutter-mobile;
  margin-top: $gutter-mobile;

  @include bp(md) {
    margin-top: $gutter;
    width: 50%;
    padding: $gutter;
  };

  &:after {
    background: url('/sites/all/themes/seagull_theme/images/u169.png') no-repeat center right transparent;
    background-size: cover;
    content: "";

    display: block;
    width: 100%;
    width: calc(100% + 3rem);
    min-height: 300px;
    margin: -1.5rem;
    margin-top: 1.5rem;

    @include bp(md) {
      margin: 0;
      min-height: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 100%;
      width: 100%;
    }
  }

  h2 {
    color: $color-white;
  }

  .field--bean-cta-multiple,
  .field--bean-cta {
    .field-item a {
      @include jp-button-primary-alt();
    }
  }
}


.pane-bean-jp-voor-bedrijven {
  margin-top: $gutter-mobile;

  @include bp(md) {
    margin-top: $gutter;
    width: calc(100% - 330px);
    padding-right: 2rem;
  }

  &:after {
    background: url('/sites/all/themes/seagull_theme/images/u314.jpg') no-repeat center right transparent;
    background-size: cover;
    content: "";

    display: block;
    width: 100%;
    min-height: 300px;
    margin-top: 1.5rem;

    @include bp(md) {
      margin: 0;
      min-height: 1px;
      width: 330px;
      right: -330px;
      top: 0;
      position: absolute;
      height: 220px;
    }
  }

  .field--prefix p {
    line-height: 2;
  }
}


.pane-bean-jp-vacatures-in-de-kijker,
.pane-bean-jp-bedrijven-in-de-kijker {
  .field--bean-cta-multiple,
  .field--bean-cta {
    .field-item a {
      @include jp-button-primary();
    }
  }
}

.pane-bean-jp-bedrijven-in-de-kijker {
  .ds-footer {
    .field--jp-vacsol-edit-current-item,
    .field--bean-cta-multiple,
    .field--bean-cta {
      padding: 0 2rem;
    }
  }
}

.pane-bean-jp-vacatures-in-de-kijker {
  margin-top: $gutter-mobile * 2;

  @include bp(md) {
    margin-top: $gutter * 2;

    > .pane-content > article {

      > .ds-center {
        display: inline-block;
        position: relative;
        width: 100%;

        &:before {
          background: url('/sites/all/themes/seagull_theme/images/u320.png') no-repeat center right transparent;
          background-size: cover;
          content: "";

          position: absolute;
          left: 25%;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .field--embed-content {
          max-width: 70%;
          margin: $gutter/2 20% $gutter/2 10%;
        }
      }

      .field--bean-cta-multiple,
      .field--bean-cta {
        margin-top: $gutter/2;
        margin-left: 10%;
      }
    }
  }
}


// Homepage bedrijven

.pane-bean-jp-opleidingen,
.pane-bean-jp-studiedagen-en-evenementen {
  background: $jp-color-blue;
  color: $color-white;
  padding: $gutter-mobile;

  @include bp(md) {
    padding: $gutter;
  }

  h2 {
    color: $color-white;
  }

  .ds-footer .field--bean-cta-multiple a,
  .ds-footer .field--bean-cta a {
    @include jp-button-primary();

  }
}

.pane-bean-jp-ienet-staat-u-bij-in-uw-zoekt {
  background: $color-grey;
  padding: $gutter-mobile;

  @include bp(md) {
    padding: $gutter;

    max-width: 60%;
  }

  &:after {
    background: url('/sites/all/themes/seagull_theme/images/u918.png') no-repeat center right transparent;
    background-size: cover;
    content: "";
    display: inline-block;
    min-height: 300px;
    margin: -1.5rem;
    margin-top: 1.5rem;
    width: calc(100% + 3rem);

    @include bp(md) {
      position: absolute;
      bottom: 0;
      left: 100%;
      right: -66.5%;
      top: 0;
      margin: 0;
      min-height: 1px;
      width: auto;
    }
  }

  .field--prefix {
    ul {
      margin-bottom: 0;

      li {
        margin-bottom: $gutter/2;
        line-height: 1.6;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}


.pane-bean-jp-vraag-bedrijfsaccount-aan {
  color: $color-white;
  margin-top: $gutter-mobile;

  @include bp(md) {
    float: right;
    margin-top: $gutter;
    max-width: 50%;
  }

  &:before {
    background: url('/sites/all/themes/seagull_theme/images/u872.png') no-repeat center center transparent;
    background-size: cover;
    content: "";
    display: inline-block;
    min-height: 300px;
    margin: -1.5rem;
    margin-bottom: 1.5rem;
    width: calc(100% + 3rem);

    @include bp(md) {
      position: absolute;
      bottom: 0;
      left: -100%;
      right: 110%;
      top: 0;
      margin: 0;
      min-height: 1px;
      width: auto;
    }
  }

  h2 {
    color: $color-white;
  }

  .field--bean-cta-multiple,
  .field--bean-cta {
    .field-item a {
      @include jp-button-white();
    }
  }
}


.pane-bean-jp-bedrijven-home-ctas {
  .field--paragrafen {
    > .field-items {
      @include row();
      display: flex;
      flex-wrap: wrap;

      > .field-item {
        @include col();
        @include cols(12);
        margin: 0;
        display: flex;
        padding: $gutter-mobile/2;

        @include bp(md) {
          padding: $gutter/2;
          @include cols(6);
        }

        @include bp(lg) {
          @include cols(4);
        }

        > article {
          background: $color-grey;
          cursor: pointer;
          padding: $gutter-mobile/2;
          transition: $default-transition;

          @include bp(md) {
            padding: $gutter/2;
          }

          a {
            font-weight: bold;
            text-transform: uppercase;

            @include icon('arrow-right', after) {
              text-decoration: none;
            };
          }

          &:hover {
            background: $jp-color-blue;
            color: $color-white;

            a {
              color: $color-white;
            }
          }

          .field--paragraph-title {
            font-weight: bold;
            @include font-size(16);
          }

          .field--paragraph-tekst {
            margin-bottom: $gutter;
          }

          .field--link {
            position: absolute;
            bottom: $gutter-mobile;

            @include bp(md) {
              bottom: $gutter;
            }
          }
        }
      }
    }
  }
}


.pane-bean-het-ie-net-bedrijfspartnerschap {
  color: $color-white;
  margin-bottom: 0;

  h2 {
    color: $color-white;

    &.pane-title {
      display: none;
    }
  }

  .ds-header {
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile;

    @include bp(md) {
      margin-top: $gutter;
      margin-bottom: $gutter;

      display: flex;
      align-items: center;
    }

    .field--prefix {
      @include bp(md) {
        max-width: 380px;
      }

      p {
        line-height: 2;
      }
    }
  }

  .ds-footer {
    margin-bottom: $gutter-mobile * -1;

    .field--suffix {
      background: $jp-color-blue;
      padding: $gutter-mobile/2 $gutter-mobile;

      @include bp(md) {
        padding: $gutter/2 $gutter;
      }

      ul {
        list-style: none;
        margin: 0;

        li {
          padding-left: 32px;
          position: relative;
          line-height: 2;

          @include icon('check', before) {
            position: absolute;
            left: 0;
            top: 5px;
          };
        }
      }
    }

    .field--bean-cta-multiple,
    .field--bean-cta {
      background: $jp-color-blue;
      padding: 0 $gutter-mobile $gutter-mobile/2 $gutter-mobile;

      @include bp(md) {
        padding: 0 $gutter $gutter/2 $gutter;
      }

      a {
        background: $color-white;
        border-radius: 0;
        color: $color-black !important;
        display: inline-block;
        padding: 1rem;
        width: 100%;
      }
    }
  }

  @include bp(md) {
    .entity-bean {
      display: flex;
      @include row();
    }

    .ds-header,
    .ds-footer {
      @include col();
      @include cols(6);
    }

    .ds-footer {
      margin-bottom: $gutter * -1;
    }
  }
}


.pane-jp-vacsol-jp-company-profile-status {
  .pane-content {
    background: $color-grey-medium;
    color: #333;
    padding: $gutter-mobile/2;

    @include bp(md) {
      padding: $gutter/2;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}


.pane-jp-cta {
  @include jp-cta-block();
}

.pane-bean-jp-naar-cv-databank {
  .pane-content {
    background: $jp-color-blue;
    color: $color-white;
    padding: $gutter-mobile/2;
    text-align: center;

    @include bp(md) {
      padding: $gutter/2;
    }

    .field--bean-cta-multiple a,
    .field--bean-cta a {
      @include button();
      @include jp-button-primary();
      margin-bottom: 1rem !important;
    }

    .ds-footer p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.pane-jp-vacsol-jp-credits-counter {
  background: $color-grey-medium;
  padding: $gutter-mobile/2;

  @include bp(md) {
    padding: $gutter/2;
  }

  .pane-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left-col {
    padding-right: $gutter-mobile/2;

    @include bp(md) {
      padding-right: $gutter/2;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .right-col {
    a {
      @extend .button;
      @extend .button-grey-dark;
      margin-bottom: 0;
    }
  }
}

.pane-user-field-werkervaringen {
  > .pane-title {
    @include font-size(14);
    border-bottom: 1px solid $color-border;
    background: $color-grey;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 0;
    padding: 1rem 1.25rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: $default-transition;
    width: 100%;

    @include icon('minus', after) {
      @extend .field-set-before;
      position: absolute;
      right: 1rem;
      top: 11px;
    }

    &:hover,
    &:active {
      background-color: darken($color-grey, 10);
    }
  }

  &.open {
    > .pane-title {
      @include icon('plus', after) {
        @extend .field-set-before;
      }
    }
  }

  .pane-content {
    background: $color-grey;
    padding: 1.5rem;
  }
}


.pane-jp-vacsol-jp-add-new-vacancy {
  display: inline-block;
  width: 100%;
  z-index: 100;

  @include bp(md) {
    float: right;
    margin-bottom: 0;
    width: auto;
  }

  a.button-grey {
    @include button();
    @include jp-button-secondary();

    float: right;
    margin-bottom: 0;
  }
}

.pane-jp-vacsol-jp-candidate-profile-status {
  > .pane-content {
    display: flex;
    justify-content: space-between;
    background: $color-grey-medium;
    padding: $gutter-mobile/2;
    margin-bottom: $gutter-mobile;
    color: $color-black;

    @include bp(md) {
      margin-bottom: $gutter;
      padding: $gutter/2;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    a {
      @include button();
      @include jp-button-secondary();

      margin-bottom: 0;
    }

    .infobox-link {
      order: 2;
      display: flex;
      align-items: center;
    }

    .infobox-info {
      order: 1;
    }
  }
}

.pane-jp-vacsol-jp-company-edit {
  > .pane-content {
    > .left-col {
      background: $color-grey-medium;
      padding: $gutter-mobile/2;
      margin-bottom: $gutter-mobile;
      color: $color-black;

      @include bp(md) {
        margin-bottom: $gutter;
        padding: $gutter/2;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    a.button-grey {
      @include button();
      @include jp-button-secondary();

      margin-bottom: $gutter-mobile;

      @include bp(md) {
        margin-bottom: $gutter;
      }
    }
  }
}

.pane-jp-vacsol-jp-profile-views {
  position: absolute;
  top: 7px;
  right: 1.375rem;
  margin-bottom: 0;
}
