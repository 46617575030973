.view-display-id-webinars {

  .view-filters {

    .views-exposed-form {
      text-align: left;

      .views-exposed-widget {
        width: 100%;
        padding: 0;

        .views-widget {
          max-width: 100%;
          width: 100%;

          .form-select-wrapper {
            background-color: transparent;
          }

          .bef-select-as-links{

            .form-item {
              display: flex;
              background-color: transparent;
            }

            .form-item:hover {
              background-color: transparent;
            }

            .form-type-bef-link {
              display: inline-flex;
              text-align: left;
              width: auto;
              margin-right: 8px;

              a {
                color: #45A0B2;
                border: 1px solid #45A0B2;
                padding: 6px 18px 6px 18px;

                text-decoration: none;
              }

              .active {
                color: #FFF;
                background-color: #45A0B2;
                padding: 6px 10px 6px 18px;
              }

              .active:after {
                display: inline-block;
                content: '';
                background: url("../icons/close.svg") no-repeat center;
                background-size: 15px 15px;
                width: 15px;
                height: 15px;
                margin: 0 0 -3px 6px;

                // turns the SVG to #FFF (https://codepen.io/sosuke/pen/Pjoqqp)
                filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(106deg) brightness(101%) contrast(100%);
              }
            }
          }
        }
      }
    }

  }


  .view-content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;

    @include bp(md) {
      grid-template-columns: (100% / 3) (100% / 3) (100% / 3);

      .views-row-1, .views-row-3, .views-row-4, .views-row-6, .view-row-7 {
        padding-right: 15px;
      }

      .views-row-2, .views-row-4, .views-row-5, .views-row-7, .views-row-8 {
        padding-left: 15px;
      }
    }

    .views-row {
      justify-self: center;
      width: 100%;
      height: 390px;
      margin: auto auto 30px auto;

      article {
        width: 100%;
        height: 100%;
        color: #555555;
        background-color: #F0F0F0;
        justify-self: center;
        margin: 0 !important;
        cursor: pointer;

        .webinar-play-icon {
          position: absolute;
          top: 30%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-user-select: initial;
          z-index: 5;

          width: 80px;
          height: 80px;
        }

        .field--webinar-teaser-img {
          position: absolute;
          width: 100%;
          height: 50%;

          .field-items, .field-item {
            height: 100%;

            img {
              width:100%;
              height:100%;
              object-fit:cover;
            }
          }
        }

        .group-tags {
          display: flex;
          position: relative;
          margin: -20px 0 0 0;

          .field--tags, .field--webinar-duration {
            @include font-size(12);
            font-weight: 600;
            background-color: $color-grey;
            padding: 0.275rem 0.5rem;
            display: inline-block;
            text-align: center;
            min-width: 100px;
            color: white;
          }

          .field--tags {
            background-color: #45A0B2;
          }


          .field--webinar-duration {
            background-color: #434955;
          }
        }

        .description {
          position: absolute;
          height: 48%;
          top: 52%;

          h2 {
            @include font-size(20);
            margin: 10px 32px 0 32px;
            font-weight: bold;

            a:after {
              content: '';
              display: inline-block;
              background: url("../icons/arrow-right.svg") no-repeat;
              background-size: 20px 20px;
              width: 20px;
              height: 20px;
              margin: 0 0 -3px 6px;
              // turns the SVG to #555 (https://codepen.io/sosuke/pen/Pjoqqp)
              filter: invert(32%) sepia(1%) saturate(0%) hue-rotate(81deg) brightness(97%) contrast(89%);
            }
          }

          .field--webinar-summary {
            margin: 10px 32px 0 32px;
            height: inherit;

            .field-items {
              height: 100%;

              .field-item {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: inherit;
              }
            }
          }

          .p-item {
            margin: 0 !important;
          }

        }

        ul {
          display: none;
        }
      }

      article:hover {

        .webinar-play-icon {
          filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(106deg) brightness(101%) contrast(100%);
        }

        .description {
          h2 {
            a {
              text-decoration: underline;
            }
          }
        }
      }
    }

    // The first article
    .views-row-1 {
      @include bp(md) {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      article {
        color: white;
        background-color: transparent;
        position: relative;
        -webkit-user-select: none;

        .webinar-play-icon {
          top: 50%;
          left: 50%;
        }

        .field--webinar-teaser-img {
          height: 100%;
        }

        .field--webinar-teaser-img:after {
          position: absolute;
          top: 0;

          content: '';
          width:100%;
          height:100%;

          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#999999+0,999999+50,7db9e8+100 */
          background: transparent; /* Old browsers */
          background: -moz-linear-gradient(bottom, black, transparent 57%, transparent 0); /* FF3.6-15 */
          background: -webkit-linear-gradient(bottom, black, transparent 57%, transparent 0); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to top, black, transparent 57%, transparent 0); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='black',GradientType=0 ); /* IE6-9 */
        }

        .description {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          font-size: 1rem;

          position: absolute;
          top: 55%;
          height: 40%;
          width: 100%;
          color: white;
        }

        .group-tags {
          margin: 10px 0 0 32px;
        }

        h2 {
          color: white;

          a:after {
            // turns the SVG to #FFF (https://codepen.io/sosuke/pen/Pjoqqp)
            filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(106deg) brightness(101%) contrast(100%) !important;
          }
        }

        .field--webinar-summary, h2 {
          margin: 10px 32px;
        }

        .field--webinar-summary {
          height: 60px;
          margin: 0 32px 0 32px;
        }
      }
    }
  }
}

.field--webinars-to-promote {
  > .field-items {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;

    @include bp(md) {
      grid-template-columns: (100% / 3) (100% / 3) (100% / 3);

      > .field-item:nth-of-type(1), > .field-item:nth-of-type(2) {
        padding-right: 15px;
      }

      > .field-item:nth-of-type(2), > .field-item:nth-of-type(3) {
        padding-left: 15px;
      }
    }

    > .field-item {
      justify-self: center;
      width: 100%;
      height: 390px;
      margin: auto auto 30px auto;

      article {
        width: 100%;
        height: 100%;
        color: #555555;
        background-color: #F0F0F0;
        justify-self: center;
        margin: 0 !important;

        .webinar-play-icon {
          position: absolute;
          top: 30%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-user-select: initial;
          z-index: 5;

          width: 80px;
          height: 80px;
        }

        .field--webinar-teaser-img {
          position: absolute;
          width: 100%;
          height: 50%;

          .field-items, .field-item {
            height: 100%;

            img {
              width:100%;
              height:100%;
              object-fit:cover;
            }
          }
        }

        .group-tags {
          display: flex;
          position: relative;
          margin: -20px 0 0 0;

          .field--tags, .field--webinar-duration {
            @include font-size(12);
            font-weight: 600;
            background-color: $color-grey;
            padding: 0.275rem 0.5rem;
            display: inline-block;
            text-align: center;
            min-width: 100px;
            color: white;
          }

          .field--tags {
            background-color: #45A0B2;
          }


          .field--webinar-duration {
            background-color: #434955;
          }
        }

        .description {
          position: absolute;
          height: 48%;
          top: 52%;

          h2 {
            margin: 10px 32px 0 32px;
            font-weight: bold;

            a:before {
              width: 100%;
              height: 390px;
              position: absolute;
              left: 0;
              top: -109%;
              z-index: 10;
              content: '';
              display: block;
            }

            a:after {
              content: '';
              display: inline-block;
              background: url("../icons/arrow-right.svg") no-repeat;
              background-size: 20px 20px;
              width: 20px;
              height: 20px;
              margin: 0 0 -3px 6px;

              // turns the SVG to #555 (https://codepen.io/sosuke/pen/Pjoqqp)
              filter: invert(32%) sepia(1%) saturate(0%) hue-rotate(81deg) brightness(97%) contrast(89%);
            }
          }

          .field--webinar-summary {
            margin: 10px 32px 0 32px;
            height: inherit;

            .field-items {
              height: 100%;

              .field-item {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: inherit;
              }
            }
          }

          .p-item {
            margin: 0 !important;
          }

        }

        ul {
          display: none;
        }
      }
    }
  }
}

.node--webinar {

  .group-tags {
    display: flex;

    .field-group-format-wrapper{
      display: flex !important;

      .field--tags, .field--webinar-duration {
        @include font-size(12);
        font-weight: 600;
        background-color: $color-grey;
        padding: 0.275rem 0.5rem;
        display: inline-block;
        text-align: center;
        color: white;
        min-width: 100px;
      }

      .field--tags {
        background-color: #45A0B2;

        .term--webinar-tags {
          h2 {
            line-height: inherit;
            font-size: 14px;
            margin-bottom: 0;
            color: white;
          }
        }
      }

      .field--webinar-duration {
        background-color: #434955;

        .field--item {
          padding: 2px 8px 2px 8px;
        }
      }
    }
  }

  .field--title, .field--paragraph-webinar-speaker .field-label, .field--contact-info .field-label, .field--webinar-overzicht .title, .field--webinars-to-promote > .field-label {
    margin-top: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .field--webinar-summary {
    margin-bottom: 1.75rem;
  }

  .field--webinar-teaser-img {
    position: relative;
    margin-bottom: 1.75rem;

    .field-item {
      text-align: center;

      img {
        width: 800px;
        height: 450px;
        object-fit: cover;
      }
    }
  }

  .field--bean-cta {
    margin-top: 2.25rem !important;

    a {
      padding: 12px 36px;
      border-radius: 4px;
      background-color: #CA263B;
      color: $color-white;
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
    }

    a:hover {
      background-color: #E0341F;
    }
  }

  .field--paragraph-webinar-speaker {

    .field-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .field-items > div:first-of-type, .field-items > div:nth-of-type(4n) {
      flex: 0 1 100%;
    }

    .p-webinar-speaker-full {
      display: flex;
      flex: 0 1 100%;
      justify-content: space-between;

      margin: 0;
      padding: 3.25rem 0;
      border-top: none;

      @include bp(md) {
        flex: 0 1 47%;
      }

      .paragraphs-item-webinar-speaker {
        display: flex;

        .content {
          display: flex;

          .field--webinar-speaker-img {
            min-width: 120px;

            img {
              display: flex;
              margin: 0 auto;
              width: 120px;
              border-radius: 50%;
            }
          }

          .group-webinar-speaker-info {
            margin-left: 40px;

            h3 {
              display: none;
            }

            .field--webinar-speaker-name, .field--webinar-speaker-title {
              font-size: 1.1rem;
            }

            .field--webinar-speaker-name {
              font-weight: bold;

              margin-top: 0;
              margin-bottom: 1rem;
            }

            .field--webinar-speaker-title {
              color: #CA263B;

              margin-top: 0;
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }

    .p-webinar-speaker-full ~ .p-webinar-speaker-full {
      border-top: 0.75px solid $color-grey;
    }

  }

  .paragraphs-items-field-contact-info-full {

    .field-items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .p-civicrm-vcard {
        flex: 0 1 100%;
        margin: 1rem 0;

        @include bp(md) {
          flex: 0 1 48%;
        }
      }
    }
  }

  .group-webinar-paywall {
    margin-top: -40px;

    .field--webinar-contribute, .field--paywall-text {
      margin: 1.75rem;
    }

    .field--register-form, .field--login-form {
      margin: 1.75rem;
      display: inline-flex;
      width: 100%;

      @include bp(md) {
        width: 42.5%;
      }
    }
  }
}

.node--full-no-access {

  .group-webinar-content {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 85%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 85%);
    overflow-y: scroll;
  }
}
