// Spontaan solliciteren: 'Are you sure?'-dialog
.modal--confirm  {
  z-index: 100;

  .btns-wrapper {
    > button {
      margin-bottom: 0;
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .save-btn {
      @include jp-button-primary();
    }

    .cancel-btn {
      @include jp-button-secondary();
    }
  }
}

// User login + register modal
#modalContent {
  #language-dependend-node-form {
    width: 100%;
    padding: 1.5rem;

    .file-image-form {
      margin-bottom: 0;
    }

    .field-type-text {
      margin-bottom: 1.5rem;
    }

    .form-item-files-replace-upload,
    .form-item-replace-keep-original-filename,
    .manualcrop-thumblist,
    .vertical-tabs {
      display: none;
    }
  }

  .modal-header {
    height: 0;
    min-height: 0;
    padding: 0;

    .clear-block,
    .modal-title {
      display: none;
    }

    .close,
    .popups-close {
      position: absolute;
      float: none;
      z-index: 2000;
      background: $color-white; //rgba(244, 244, 244, 1);
      text-indent: 200%;
      overflow: hidden;
      top: $gutter-mobile/2;
      right: $gutter-mobile/2;

      @include bp(md) {
        top: $gutter/2;
        right: $gutter/2;
      }

      @include icon('close') {
        position: absolute;
        left: 0;
        text-indent: 0;
        @include font-size(26);
      };
    }
  }

  //.ctools-modal-content,
  .modal-content {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
  }

  button,
  .button-grey {
    margin-bottom: 0;
  }

  .modal-content {
    display: flex;
    flex-wrap: wrap;

    h2 {
      font-weight: bold;
      @include font-size(24);
    }

    p,
    li {
      line-height: 24px;
    }

    .cta--login-form,
    .cta--register-engineer,
    .cta--register-company {
      padding: $gutter-mobile/2;

      @include bp(md) {
        padding: $gutter/2;
      }
    }

    .cta--login-form,
    .cta--register-engineer {
      background: rgba(244, 244, 244, 1);
      width: 50%;
      margin-bottom: 0;
    }

    .cta--login-form {
      .form-actions {
        > a {
          display: none;
        }
      }
    }

    #user-login {
      .item-list {
        float: right;
      }

      ul,
      li{
        margin: 0;
        list-style: none;
      }

      a {
        line-height: 39px;
      }
    }

    .cta--register-engineer {
      position: relative;

      a.button-grey {
        @include button();
        @include jp-button-primary();
      }

      &:before {
        content: "";
        border-left: 1px solid $color-grey;
        position: absolute;
        left: 0;
        top: $gutter-mobile/2;
        bottom: $gutter-mobile/2;

        @include bp(md) {
          top: $gutter/2;
          bottom: $gutter/2;
        }
      }

      ul {
        margin-left: 0;
        list-style: none;
      }

      li {
        padding-left: 24px;
        position: relative;

        @include icon('check') {
          color: rgba(204, 203, 203, 1);
          position: absolute;
          left: 0;
          top: 3px;
        };
      }
    }

    .cta--register-company {
      background: rgba(204, 203, 203, 1);
      width: 100%;

      @include bp(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .infobox-info {
        p:last-of-type {
          margin-bottom: 0;
        }

        @include bp(md) {
          padding-right: 1.5rem;
        }
      }

      .infobox-link a {
        @include button();
        @include jp-button-secondary();

        margin-bottom: 0;
        margin-top: 1rem;
        min-width: 200px;

        @include bp(md) {
          margin-top: 0;
        }
      }
    }
  }

  @include bp(md, max) {
    left: -40% !important;
    margin-left: 50%;
    max-width: 80%;
    height: auto;

    .ctools-modal-content {
      max-width: 100%;
      height: auto !important;
    }

    .cta--login-form,
    .cta--register-engineer {
      width: 100% !important;
    }
  }
}
