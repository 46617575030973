// Basic Page
// --------------------------------------------------

.node-page.view-mode-full {
}

.node--page--teaser {
  position: relative;
  margin-bottom: 0;
  background-color: $color-grey-light;
  @include bp(sm, max) {
    max-width: 23.75rem;
    margin-right: auto;
    margin-left: auto;
  }

  .content-wrapper {
    padding: 1.5rem 2rem 3rem;
  }

  .field--lees-meer {
    position: absolute;
    bottom: 2rem;
    left: 2rem;

    a {
      font-weight: 400;
      text-transform: uppercase;
      @include icon(arrow-right, after) {
        text-decoration: none;
        margin-left: 0.375rem;
      }

    }
  }
}