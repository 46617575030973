// Forms
// --------------------------------------------------

// Form item

.form-item,
fieldset {
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}

// Required

.form-required {
  color: $color-error;
}

// Disabled

.form-disabled {
  opacity: .5;

  input {
    cursor: not-allowed;

    &:active,
    &:focus,
    &:hover {
      border-color: $color-border;
      box-shadow: none;
    }
  }
}

// Description

.form-item .description {
  margin-top: 0.5rem;
  @include font-size(12);
}

// Labels

.form-item,
.price-set-row,
.membership_amount-section {
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.form-item.label-inline,
.webform-container-inline {
  @include bp(sm) {
    //letter-spacing: -0.25rem; // Remove the space between inline elements
    /*> * {
      letter-spacing: initial;
    }*/

    label {
      display: inline-block !important;
      margin-top: 0.5rem;
      margin-bottom: 0;
      width: 10rem;
    }
    input {
      vertical-align: top;
    }
    .description {
      margin-left: 12rem;
    }
    .form-textarea-wrapper {
      display: inline-block;
    }
    &.form-type-textarea label {
      vertical-align: top;
    }
  }
  .description {
    font-weight: 400;
  }
}

.webform-container-inline div,
.webform-container-inline div.form-item {
  display: inline-block;
}

.compact-form-wrapper {
  position: relative;
}

.compact-form-label {
  position: absolute;
  color: $color-muted;
  cursor: text;
  font-weight: 400;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: $color-muted;
  opacity: 1;
}

// Text fields and text areas

input,
textarea,
input.big.crm-form-text {
  width: 31rem;
  max-width: 100%;
  border: $form-border;
  border-radius: $form-border-radius;
  padding: $form-padding;
  @include appearance(none);
  font-weight: 400;
  color: $color-text;

  &:hover {
    outline: none;
    border-color: $color-form-hover;
    box-shadow: 0 0 2px 1px rgba($color-form-shadow, 0.15);
  }
  &:active,
  &:focus {
    outline: none;
    border-color: $color-form-focus;
    box-shadow: 0 0 2px 1px rgba($color-form-focus, 0.25);
  }
  &.error {
    border: 1px solid $color-error;
    box-shadow: 0 0 2px 1px rgba($color-error, 0.25);
  }
  &[size="4"] {
    width: 4rem;
  }
}

input {
  @include font-size($form-font-size, 0);
  line-height: normal;
  transition: border-color $default-transition;
}

textarea {
  width: 100%;
  @include font-size($form-font-size, 22);
  transition: border-color $default-transition;
}

// max character counter
.form-item {
  .counter {
    @include description();
    padding: 0.5rem 1rem;
    text-align: right;
    strong {
      color: $color-black;
    }
    &.error {
      color: $color-text;
    }
  }
}

// Grippie for textareas

.grippie {
  display: none;
}

// File field

input[type="file"] {
  line-height: 1;
  vertical-align: top;
  border-radius: $form-border-radius 0 0 $form-border-radius;
  border-width: 1px 0 1px 1px;
  @include font-size(11, 0); // Best cross-browser results

  + button {
    padding: 0.725rem 0.85rem;
    border-radius: 0 $form-border-radius $form-border-radius 0;
    line-height: 1;
    @include font-size(12, 0); // line-height needs to be unit-less.
  }
}

.form-type-managed-file {
  > .form-managed-file > .file {
    float: left;
    line-height: 37.6px;
    margin-right: 1rem;
  }
}

// Image field

input[type="image"] {
  vertical-align: middle;
}

// Radio & Checkbox
.form-checkboxes,
.form-radios {
  &.columns {
    &-2 {
      @include bp(md) {
        column-count: 2;
        columns: 2;
        column-gap: $default-block-margin-bottom;
      }
    }
    &-3 {
      @include bp(md) {
        column-count: 3;
        columns: 3;
        column-gap: $default-block-margin-bottom;
      }
    }
  }
}

.form-type-radios,
.form-type-checkboxes,
.form-type-bef-checkbox,
.crm-group {
  .form-item,
  .price-set-row {
    margin-bottom: 0.5rem;
  }
}

.form-type-radio,
.form-type-checkbox,
.form-type-bef-checkbox,
.price-set-row {
  input {
    @extend .element-invisible;
    &:focus + label:before,
    &:hover + label:before {
      border: solid 1px $color-primary-hover;
      transition: border-color $default-transition;
    }
  }
  label {
    margin: 0;
    position: relative;
    display: inline-block;
    padding-left: 1.5rem;
    @include font-size($form-font-size, 0);
    letter-spacing: 0;
    text-transform: none;
    font-weight: 400;
    cursor: pointer;
    margin-right: 0.625rem;
    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 0.1rem;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: $color-white;
      border: solid 1px $color-form-border;
    }
  }
}

.form-type-radio,
.price-set-row {
  label:before {
    border-radius: 50%;
  }
  input:checked + label:before {
    background: $color-primary url(../images/sprite.png) -1px -1px no-repeat;
    border-color: $color-primary;
    background-size: auto;
    html.svg & {
      background: $color-primary url(../images/sprite.svg) -1px -1px no-repeat;
    }
  }
}

.form-type-checkbox,
.form-type-bef-checkbox {
  label:before {
    border-radius: 0.125rem;
  }
  input:checked + label:before {
    background: $color-primary url(../images/sprite.png) -1px -17px no-repeat;
    background-size: auto;
    border-color: $color-primary;
    html.svg & {
      background: $color-primary url(../images/sprite.svg) -1px -17px no-repeat;
      border-color: $color-primary;
    }
  }
}

// Select
select {
  font-weight: normal;
}

.form-type-select {
  label {
    display: block;
  }
}

.form-select-wrapper {
  position: relative;
  border: $form-border;
  border-radius: $form-border-radius;
  background: $color-white url(../images/sprite.png) 91.5% -40px no-repeat;
  background-size: auto;
  display: inline-block;
  min-width: 8rem;
  max-width: 100%;
  //width: 100%;

  &:hover {
    outline: none;
    border-color: $color-form-hover;
    box-shadow: 0 0 2px 1px rgba($color-form-shadow, 0.15);
    transition: border-color $default-transition;
  }
  &:active,
  &:focus {
    outline: none;
    border-color: $color-form-focus;
    box-shadow: 0 0 2px 1px rgba($color-form-focus, 0.25);
  }
  &.error {
    border: 1px solid $color-error;
    box-shadow: 0 0 2px 1px rgba($color-error, 0.25);
  }
  html.svg & {
    //background: $color-white url(../images/sprite.svg) 91.5% -40px no-repeat;
  }
  html.ie & {
    background-image: none;
  }
  select {
    width: 100%;
    border: 0 none;
    border-radius: 0px;
    background: transparent;
    @include font-size($form-font-size, 0);
    line-height: normal;
    padding: $form-padding 2rem $form-padding $form-padding;
    @include appearance(none);
    &:active,
    &:focus {
      outline: 0;
    }
    &:hover {
      cursor: pointer;
    }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $color-black;
    }
    &::-ms-expand {
      display: none;
    }
    html.ie & {
      padding: 0.9375rem 1rem;
    }
  }
}

// Date

.form-type-date {
  .form-item {
    display: inline;
  }
  input[type="image"] {
    background: transparent;
    border: none;
  }
}

// Calendar

.webform-calendar {
  width: auto;
  background: $color-white;
  border: none;
  vertical-align: middle !important;
  &:hover, &:active, &:focus {
    box-shadow: $box-shadow;
  }
}

.webform-datepicker {
  display: inline-block;
}

// Time

.form-type-webform-time {
  .form-type-select {
    display: inline;
  }
}

// Fieldsets
.field-set-before {
  line-height: 1.675rem !important;
  font-weight: 700 !important;
  text-decoration: none !important;

  @include bp(md, max) {
    float: right;
  }

}

fieldset {
  min-width: 0; // override chrome default
  margin-bottom: $default-block-margin-bottom-mobile;
  padding: 0;
  border: none;
  border-radius: $form-border-radius;
  + fieldset {
    margin-top: $default-block-margin-bottom-mobile
  }

  legend {
    margin-left: 0;
    margin-bottom: $default-block-margin-bottom-mobile;
    @include bp(md, max) {
      display: block;
      width: 100%;
    }
  }

  html.js &.collapsible {
    height: auto;
    .fieldset-legend a {
      @extend h3;
      margin: 0;
      padding: 0;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      position: relative;
      display: inline-block;
      @include icon('minus') {
        @extend .field-set-before;
      }
      @include bp(md, max) {
        display: block;
        background-color: $color-grey;
        padding: 0.75rem;

        &:hover, &:active {
          background-color: darken($color-grey, 10);
        }
      }
    }
  }
  html.js &.collapsible {
    &.open,
    &.collapsed {
      height: auto;
      .fieldset-legend a {
        @include icon('plus') {
          @extend .field-set-before;
        }
      }
    }
  }
  html.js &.fieldset-civi.collapsible {
    legend {
      h2 {
        margin-bottom: 0;
        @include font-size(16);
        font-weight: 600;
      }
      .fieldset-legend a {
        &:before {
          font-size: 1rem;
          line-height: 1.3125rem !important;
        }
      }
      @include bp(lg) {
        .fieldset-legend a {
          &:before {
            position: absolute;
            left: -2rem;
            margin-right: 0.25rem;
          }
        }
      }
    }
  }
  html.js body.page-jobportaal-cv-databank &.field-group-fieldset,
  html.js body.page-profiel &.form-wrapper.collapsible,
  html.js &.webform-component-fieldset,
  html.js body.page-jobportaal-bedrijven &.field-group-fieldset,
  html.js &.form-fieldset {
    legend {
      border-bottom: $default-border;
      margin-bottom: 0;
      width: 100%;
      display: block;
    }
    .fieldset-legend a {
      @include font-size(14, 22);
      display: block;
      background-color: $color-grey;
      padding: 0.75rem;
      @include bp(md) {
        &:before {
          float: right !important;
          position: static !important;
        }
      }

      &:hover, &:active {
        background-color: darken($color-grey, 10);
      }
    }

    .fieldset-wrapper {
      background-color: $color-grey;
      padding: $gutter-mobile;
    }
  }
  html.js &.webform-component-fieldset:not(.collapsible) {
    .fieldset-legend {
      @extend h3;
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      @include font-size(14, 22);
      display: block;
      background-color: $color-grey;
      padding: 0.75rem;
    }
  }

  .fieldset-wrapper {
    clear: both;
  }
}

.fieldset-no-border {
  padding: 0;
  border: 0 none;
  > legend {
    @extend h3;
    margin-bottom: 2rem;
    color: $color-primary;
  }
}

.fieldset-description {
  margin-bottom: 2rem;
}

// Clearable fields

.clearable-action {
  color: $color-white;
  background: $color-primary;
  position: absolute;
  right: $form-padding;
  top: $form-padding;
  cursor: pointer;
  display: none;
  width: 1rem;
  height: 1rem;
  @include font-size(12, rtp(1));
  text-align: center;
  border-radius: 2px;
}

.clearable-wrapper {
  position: relative;
  display: inline-block;

  input {
    padding-right: 2rem;
  }
}

// Autocomplete

#autocomplete,
ul.better-autocomplete {
  border: 1px solid $color-border;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

#autocomplete ul,
ul.better-autocomplete {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

#autocomplete li,
ul.better-autocomplete li.result {
  padding: 0.2rem $form-padding !important;
  background: $color-white;
  color: $color-text;
  cursor: default;
  white-space: pre;
  zoom: 1; /* IE7 */
  line-height: 1.2;
  // border-bottom: $default-border;
  // &:last-child {
  //   border-bottom: 0 none;
  // }
  &:hover {
    background-color: $color-primary;
    cursor: pointer;
  }
}

ul.better-autocomplete {
  background: $color-white;
  li {
    border-bottom: $default-border;
    h3,
    h4,
    p {
      margin: 0;
      @include font-size(14);
    }
  }
  li.group {
    padding: 0.2rem $form-padding !important;
    background: $color-grey;
  }
  li.result {

  }
}

// Actions

.form-actions {
  button {
    margin-right: 1rem;
  }
}

// tabledrag

.tabledrag-toggle-weight-wrapper {
  display: none;
}

table.tabledrag-processed {
  th, td {
    padding: 0.5rem 0.5rem 0.5rem 0;
    border: 0 none;
  }
}

.draggable {
  line-height: 2.5rem;
  .handle {
    float: left;
    cursor: move;
    //@include icon('handle');
  }
}

td.field-multiple-drag {
  width: 2.5rem;
}

.tabledrag-changed {
  display: none;
}

// multiple files 

.field-type-file {
  fieldset {
    margin-bottom: 0;
  }
  table {
    width: 32.5rem;
    max-width: 100%;
    thead {
      display: none;
    }
    tr.draggable {
      line-height: 1;
    }
    tr td:last-child {
      width: 4rem;
    }
    .file-widget {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include font-size(14);
    }
    .form-submit {
      @extend .button-small;
    }
  }
}

// datepicker

.ui-datepicker, #ui-datepicker-div {
  background: $color-white;
  border: $default-border;

  .ui-datepicker-header {
    background: $color-white;
    border: none;
    border-radius: 0;

    .ui-datepicker-title,
    .ui-datepicker-prev,
    .ui-datepicker-next {
      background: $color-white;
      border: none;
      padding: 0;
    }
    .ui-datepicker-next {
      right: 0;
      &-hover {
        top: inherit;
        cursor: pointer;
      }
    }
    .ui-datepicker-prev {
      left: 0;
      &-hover {
        top: inherit;
        cursor: pointer;
      }
    }
  }
  table.ui-datepicker-calendar {
    background: $color-white;
    td {
      a {
        &.ui-state-hover {
          background: $color-primary;
          color: $color-white;
        }
      }
    }
  }
  .ui-datepicker-current-day {
    font-weight: 800;
    a {
      border: 1px solid $color-primary;
    }
  }
  .ui-state-active {
    background: $color-primary;
    color: $color-white;
  }
}

// Chosen
//--------------------------

// reset
.chosen-container-single .chosen-drop,
.chosen-container.chosen-with-drop .chosen-drop,
.chosen-container-single .chosen-single,
.chosen-container-active.chosen-with-drop .chosen-single {
  background: $color-white;
  box-shadow: none;
  border: none;
}

.chosen-container-multi .chosen-choices {
  border: 0 none;
}

.chosen-container {
  width: 100% !important;
  @include font-size($form-font-size, 0);
}

.chosen-container-single .chosen-single {
  width: 100%;
  height: auto;
  padding: 0.4rem $form-padding;
  font-weight: 400;
  background: none;
  > div {
    display: none;
  }
}

.chosen-container-multi .chosen-choices {
  background: none;
  li.search-field input[type="text"] {
    width: 100%;
    height: auto;
    padding: $form-padding;
    @include appearance(none);
    font-weight: 400;
  }
}

.chosen-container-single .chosen-drop,
.chosen-container.chosen-with-drop .chosen-drop {
  border: $form-border;
  border-bottom-right-radius: $form-border-radius;
  border-bottom-left-radius: $form-border-radius;
}

.chosen-container-single .chosen-search {
  padding: 0;
  input[type="text"] {
    border: none;
    border-bottom: $form-border;
  }
}

// dropdown with results
.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
  li {
    color: $color-text;
  }
  li.highlighted {
    color: $color-text;
    background: $color-primary;
  }
}

// selected results in input field
.chosen-container-multi .chosen-choices li.search-choice {
  margin: 7px 0 7px 7px;
  background: $color-grey;
  border: $form-border;
  box-shadow: none;
  color: $color-text;
}

.chosen-container .search-choice-close {
  transition: none;
}

// Honeypot
// --------------------------------------------------

.url-textfield {
  display: none !important;
}

.crm-form-radio {
  width: 1rem;
  height: 1rem;
}

// Disable ios to zoom on formelement focus
// https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone


select,
textarea,
input {
  @include bp(md, max) {
    @include font-size(16);
  }
}

// Fix password strength indicator on mobile

.password-strength {
  float: none;
  margin-bottom: 15px;

  @include bp(sm) {
    float: right;
    margin-bottom: 0;
  }
}

// override civicrm newsletter block styling

.optin-civicrm .optin-item {
  border: 0;
  padding: 0;
}
