.page-node-submission {
  .user--soll-profile-anonymous,
  .user--soll-profile {
    > .ds-footer,
    > .ds-header {
      display: none;
    }

    + .webform-component--cv {
      margin-top: $default-block-margin-bottom-mobile;

      @include bp(md) {
        margin-top: $default-block-margin-bottom-mobile;
      }
    }
  }

  .webform-component--cv {
    .form-item.label-inline {
      font-weight: normal;

      label {
        width: 100%;
        display: block;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0.5rem;
      }
    }
  }

  .webform-submission-actions,
  .webform-submission-navigation {
    a {
      @include button();
      @include jp-button-primary-alt();
    }

    span {
      display: none;
    }
  }

  .webform-submission {
    margin-bottom: $gutter-mobile/2;

    @include bp(md) {
      margin-bottom: $gutter/2;
    }
  }

  fieldset,
  .webform-submission-info {
    padding: 0;

    > legend {
      @extend h3;
      @include font-size(14, 22);

      border-bottom: $default-border;
      width: 100%;
      display: block;
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      background-color: $color-grey;
      padding: 0.75rem;
      transition: $default-transition;

      &:hover,
      &:active {
        background-color: darken($color-grey, 10) !important;

        > .fieldset-legend {
          background-color: darken($color-grey, 10) !important;
        }
      }

      > .fieldset-legend {
        padding: 0 !important;
        background-color: $color-grey !important;
        transition: $default-transition !important;
      }
    }

    > div {
      background-color: $color-grey;
      padding: $gutter-mobile;
    }
  }

  #webform-component-include-cv {
    margin-bottom: 0;
    padding: 0 1.5rem;

    > label {
      @include font-size(14);

      margin: 0 -1.5rem 1rem !important;
      padding: 0.75rem;
      transition: $default-transition;
      width: calc(100% + 3rem);

      &:hover,
      &:active {
        background-color: darken($color-grey, 10) !important;
      }
    }
  }

  .download-cv {
    background-color: #f0f0f0;
    //padding: 1.5rem;
    //margin-bottom: 1.75rem;
  }

  .user--soll-profile-anonymous,
  .user--soll-profile {
    & + #webform-component-include-cv {
      margin-top: 1.75rem;
    }
  }

  .webform-submission--title-header {
    @include bp(md) {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    b {
      margin-bottom: 1rem;
      display: inline-block;
    }
  }
}
