// Fields
// --------------------------------------------------
.field--title {
  .event-teasers-short & {
    @include font-size(14, 22);
  }
}

.field--thema,
.field--categorie .field-item,
.field--type {
  a {
    text-decoration: none;
  }
}

.field--date-event-start,
.field--date-event-end {
  @include font-size(13, 26);

  .day-month-year {
    font-weight: 600;
    position: relative;
    display: block;
    text-transform: uppercase;
    @include icon(calendar) {
      margin-right: 0.25rem;
    }
  }
}

.field--date-event-start + .field--date-event-end {
  margin-top: 1rem;
}

.field--address-civi,
.event_address-section {
  @include font-size(13, 26);
  span.adr {
    span:first-child {
      font-weight: 600;
      position: relative;
      display: block;
      text-transform: uppercase;
      @include icon(marker) {
        vertical-align: top;
        margin-right: 0.25rem;
      }
    }

  }
  span {
    display: block;
    line-height: 1.425rem;
    clear: both;
    &.locality {
      clear: none;
      float: left;
      margin-right: 0.25rem;
    }
    &.postal-code {
      display: inline-block;
      clear: right;
    }
  }

}

.field--body-paragraph-civi,
.field--text-civi {
  line-height: 1.625rem;
  a {
    @extend .inline-link;
  }
  ul, ol, p, a, img {
    &:last-child {
      margin-bottom: 0;
    }
  }
  @extend .ul-in-text;
}

.field--title-paragraph-civi {
  font-weight: 400;
}

.field--read-more,
.field--register {
  a {
    @extend .button;
    @extend .button-cta;
  }
}

.field--read-more {
  a {
    @extend .button-cta;
  }
}

.field--register {
  a {
    @extend .button-primary;
  }
}

.field--contact-civi {
  max-width: 38rem;
  .contact-item {
    border: $default-border;
    border-left: none;
    padding: 1rem 1rem 1rem 1.1875rem; // padding-left = 16px + 3px ::before-border
    position: relative;
    z-index: 1;
    margin: $default-block-margin-bottom-mobile 0;
    @include bp(md) {
      margin: $default-block-margin-bottom 0;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: ' ';
      position: absolute;
      left: -3px;
      top: -1px;
      bottom: -1px;
      border: 3px solid $color-primary;
      z-index: 2;
    }
  }
  .contact-item-field {
    font-weight: 400;
    @include font-size(14, 23);
    a {
      font-weight: 400;
    }
  }
  .contact-img-wrapper,
  .contact-info-wrapper {
    display: inline-block;
  }
  .contact-info-wrapper {
    vertical-align: top;
  }
  .contact-img-wrapper {
    margin-right: 1rem;
    vertical-align: middle;
    margin-top: 7px;
  }
  .contact-img {
    max-width: 6.25rem;
  }
  .contact-name {
    font-weight: 400;
    padding-bottom: 0.25rem;
  }
}

// CRM Container overrides
// --------------------------------------------------
.crm-container {
  .field--datum {
    float: left;
  }
  // Buttons
  a.crm-register-button,
  input[type="submit"] {
    @extend .button-primary;
    width: auto;
  }
  .crm-button_qf_Participant_1_next_skip input {
    @extend .button-grey;
  }
  .description {
    display: block;
    margin-top: 0.5rem;
  }
  table {
    @extend .striped;
    margin-top: 0.5rem;
  }
}

// Overrides
// --------------------------------------------------
#civicrm-menu input {
  width: inherit !important;
}

.crm-public-footer {
  display: none;
}

#crm-main-content-wrapper {
  margin-bottom: $gutter-mobile;
  @include bp(md) {
    margin-bottom: $gutter;
  }
}

#printer-friendly {
  display: none;
}

.fa-check,
.fa-chevron-left,
.fa-fast-forward {
  &:before {
    content: none;
  }
}
.ui-icon-fa-check {
  display: none !important;
  &:before {
    content: none;
  }
}

html body.page-civicrm.html {
  padding-top: 0 !important;
}

// CIVI: Checkboxes and radios
// --------------------------------------------------
input.crm-form-radio,
input.crm-form-checkbox {
  @extend .element-invisible;
  &:focus + label:before,
  &:hover + label:before {
    border: solid 1px $color-primary-hover;
    transition: border-color $default-transition;
  }

  + label {
    margin: 0;
    position: relative;
    display: inline-block;
    padding-left: 1.5rem;
    @include font-size($form-font-size, 0);
    letter-spacing: 0;
    text-transform: none;
    font-weight: 400;
    cursor: pointer;
    margin-right: 0.625rem;
    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 0.1rem;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: $color-white;
      border: solid 1px $color-form-border;
    }
  }
}

input.crm-form-radio {
  + label:before {
    border-radius: 50%;
  }
  &:checked + label:before {
    background: $color-primary url(../images/sprite.png) -1px -1px no-repeat;
    border-color: $color-primary;
    background-size: auto;
    html.svg & {
      background: $color-primary url(../images/sprite.svg) -1px -1px no-repeat;
    }
  }
}

input.crm-form-checkbox {
  + label:before {
    border-radius: 0.125rem;
  }
  &:checked + label:before {
    background: $color-primary url(../images/sprite.png) -1px -17px no-repeat;
    background-size: auto;
    border-color: $color-primary;
    html.svg & {
      background: $color-primary url(../images/sprite.svg) -1px -17px no-repeat;
      border-color: $color-primary;
    }
  }
}

.ienet-user-cv-link-wrapper {
  padding-top: 1rem;
  border-top: $default-border;
}