
// Grid Columns
// --------------------------------------------------

#grid-columns-container {
  position: fixed;
  z-index: 200;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  div, span {
    position: relative;
    margin-top: -20px;
    height: 110%;
  }
  .col > span {
    display: block;
    // background: rgba(#ff0000, 0.5);
    border: dashed 1px rgba(#ff0000, 0.5);
  }
}