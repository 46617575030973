
// Document
// --------------------------------------------------

// Box Sizing

*,
*:before,
*:after {
    box-sizing: border-box;
}

// Prevent scrollbars

html, body {
  overflow-x: hidden; // necessary for extend classes
}
html  {
  -webkit-text-size-adjust: none; // Prevent font scaling in landscape
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
}
::selection {
  background: rgba($color-primary, 0.9); /* WebKit/Blink Browsers */
  color: $color-white;
}

::-moz-selection {
  background: rgba($color-primary, 0.9); /* Gecko Browsers */
  color: $color-white;
}
body {
  overflow: hidden;
}

// Document settings

body {
    font-family: $default-font;
    font-weight: 400;
    line-height: 1.6;
    color: $color-text;
    background-color: $color-background;
    @include font-smoothing(antialiased);  
    font-variant-ligatures: none; // turn off ligatures
    @include font-size(14);
}