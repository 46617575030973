.region-page-category {
  margin-bottom: $gutter-mobile;
  .page-category-text {
    background-color: $color-grey;
    color: $color-grey-dark;
    padding: 1rem 0;
    @include font-size(14);
    @include bp(md) {
      padding: 1.5rem 0;
      @include font-size(16);
    }
  }
  @include bp(md) {
    margin-bottom: $gutter;
  }
}