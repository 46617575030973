.field--jp-vacsol-ds-soll-profile-perc {
  background: $color-grey-medium;
  padding: 2rem;

  .infobox-perc {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .infobox-info {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .infobox-link {
    float: right;
    margin-top: 8px;

    a {
      @extend .button;
      @extend .button-grey-dark;
      margin-bottom: 0;
    }
  }

  & + .field--title {
    margin-top: $default-block-margin-bottom-mobile;

    @include bp(md) {
      margin-top: $default-block-margin-bottom;
    }
  }
}

.page-profiel-jobportaal-mijn-vacatures {
  .pane-custom.pane-1 {
    border-bottom: 1px solid $color-grey;
    margin-bottom: $gutter-mobile;
    @include bp(md) {
      margin-bottom: $gutter;
    }
  }
}

#user-profile-form {
  .btn {
    @include button();
    @include jp-button-secondary();
  }

  .form-submit,
  .field-add-more-submit {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
}

.page-jobportaal-cv-databank {
  div.modal-forms-modal-content .popups-container {
    overflow: auto;
  }

  .sidebar {
    margin-bottom: $gutter-mobile;
    @include bp(md) {
      margin-bottom: $gutter;
    }

    > .panel-pane {
      margin: 0;
      a {
        @include font-size(14);
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        padding: 1rem 1.25rem;
        border-bottom: 1px solid $color-border;
        background-color: transparent;

        &:hover, &.active {
          color: $color-text;
          background-color: $color-grey;
        }
      }
    }
  }
}


.pane-user-profile {
  .pane-title {
    border-bottom: $default-border;
    padding-bottom: 1rem;
  }

  .user > .ds-header > .field-label-inline {
    font-weight: bold;
    position: absolute;
    right: 0;
    top: -56px;
  }
}


.user--soll-profile-teaser-anonymous,
.user--soll-profile-teaser {
  border-bottom: 1px solid $color-grey;
  padding: $gutter-mobile/2;
  margin: 0;
  background: $color-white;

  @include bp(md) {
    padding: $gutter/2;
  }

  &.node--sticky {
    border: 1px solid $color-grey;
    border-left: 3px solid $color-primary;
  }

  h3 {
    margin-bottom: .5rem;
    font-weight: normal;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .field {
    margin-bottom: .5rem;
  }

  .teaser-card-center {
    margin-bottom: 1rem;
  }
}

.user--soll-profile-anonymous {
  .field--anonymous-profile-info {
    background: #f0f0f0;
    padding: 1.375rem;
    margin: 2px 2px 1.375rem 2px;
    border: none;

    @include icon('exclamation') {
      font-size: 52px;
      margin-right: 1.375rem;
      float: left;
      color: #333;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.user--soll-profile-anonymous,
.user--soll-profile {
  .webform-client-form.soll-spontaneous-dl-submit-processed & {
    > .ds-footer,
    > .ds-header {
      display: none;
    }
  }

  > .ds-footer,
  > .ds-header {
    > .flag-outer,
    > .field {
      float: right;
    }

    > .flag-outer {
      margin-left: 1rem;
    }

    a.ctools-modal-modal-popup-large,
    a.flag {
      @include button();
      @include jp-button-secondary();
    }
  }

  > .ds-footer {
    margin-top: $default-block-margin-bottom-mobile;
  }

  @include bp(lg) {
    .group-personal {
      .fieldset-wrapper {
        > .field:not(.field-label-inline) {
          float: right;

          > .field-items {
            width: 100% !important;
          }
        }
      }
    }
  }

  .group-education,
  .group-personal {
    &.field-group-fieldset {
      .field-group-fieldset {
        border-bottom: $default-border;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        &:last-of-type {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    > .fieldset-wrapper {
      > .field-group-fieldset > .field,
      > .field {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        ul,
        &:last-of-type {
          list-style: none;
          margin-bottom: 0;
          margin-left: 0;
        }

        > .field-label {
          width: 100%;
          margin-right: 0;
          padding-right: 1rem;

          @include bp(md) {
            width: 200px;
          }
        }

        > .field-items {
          width: 100%;

          @include bp(md) {
            width: calc(100% - 200px);
          }
        }
      }
    }
  }

  .group-languages {

  }
}


// Form in Solicitation modal
#contact-personal-form {
  width: 100%;
  padding: $gutter-mobile;

  > div {
    @include row();
  }

  .form-actions,
  .form-item {
    @include col();

    input,
    textarea {
      width: 100%;
    }
  }

  @include bp(md) {
    padding: $gutter;

    .form-item-name,
    .form-item-mail {
      width: 50%;
    }
  }
}

// Werkervaringen
#werkervaringen-edit-form {
  .werkervaringen-descr {
    margin-bottom: 1rem;
  }

  table th.field-label {
    padding: 0.5rem;
  }

  .paragraphs-item-werkervaring {
    @include font-size(14);

    > div > div {
      margin-bottom: .5rem !important;
    }
  }

  .paragraphs-add-more-submit,
  .form-actions {
    margin-top: 1rem;
  }

  .paragraphs-add-more-submit {
    @include jp-button-secondary();
  }

  > div > .form-actions {
    .form-submit {
      @include jp-button-primary();
    }
  }

  .form-item input {
    width: 100%;
  }

  .field--werkervaring-functie {
    @include font-size(16);

    font-weight: bold;
    line-height: 2.5rem;
  }

  tr {
    td {
      .form-actions {
        button:first-of-type {
          @include jp-button-secondary();
        }
      }

      &:nth-of-type(2) {
        padding-right: 30px;
        padding-left: 30px;

        @include bp(lg) {
          padding-left: 0;
        }

        > div:first-of-type {
          display: none;
        }
      }
    }
  }

  .form-item label {
    margin-bottom: 0;
  }
}

.draggable a.tabledrag-handle {
  margin-left: 0;
}

a.tabledrag-handle .handle {
  height: 15px;
  margin: 0;
  width: 15px;
  background-position-x: 0;
  background-position-y: 0;
}

a.tabledrag-handle-hover .handle {
  background-position: 0 -20px;
}

form .field-multiple-table td.field-multiple-drag a.tabledrag-handle {
  padding-left: .5em;
  height: 15px;
  top: 14px;
  position: relative;
}
