.content--left, .content--main {
  @include col();
}

.content--left {
  @include bp(sm) {
    @include cols(12);
  }
  @include bp(md) {
    @include cols(4);
  }
  @include bp(lg) {
    @include cols(4);
  }

}

.content--main {
  @include bp(sm) {
    @include cols(12);
    &:nth-child(2n+1) {
      clear: left;
    }
  }
  @include bp(md) {
    @include cols(8);
  }
  @include bp(lg) {
    @include cols(8);
  }
}