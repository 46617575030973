.pane-ienet-ads {
  :not(.sidebar) > & {
    @extend .extendfull;
    background-color: $color-grey-light;
    position: relative;
    margin-top: -7rem;
    margin-bottom: 8rem !important;

    .view-ienet-ads {
      margin-top: 60px;
      bottom: -60px;
      position: relative;
    }

    .field-slideshow-slide img {
      display: block;
      margin: 0 auto;
      float: none;
    }
  }

  .sidebar > & {
    .field-slideshow-slide img {
      width: 100%;
    }
  }
}
