// Classes
// --------------------------------------------------

// Clearfix

.clearfix {
  @include clearfix();
}

// Floats

.float-left {
  float: left;
}

.float-right {
  float: right;
}

// Hidden Elements

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

.hide-icon-text {
  @include hide-icon-text();
}

// Show / hide on mobile

.hide-mobile {
  @include bp(sm, max) {
    display: none !important;
  }
}

.show-mobile {
  @include bp(md, min) {
    display: none !important;
  }
}
// Table

.display-table {
  display: table;
}
.display-table-row {
  display: table-row;
}
.display-table-cell {
  display: table-cell;
}

// Extends

.full-width-mobile {
  @include gutter(margin-left, false, true);
  @include gutter(margin-right, false, true);
  @include bp(md) {
    margin-left: 0;
    margin-right: 0;
  }
}

.extendfull {
  @include bp(sm, max) {
    padding-left: 300px !important;
    margin-left: -300px !important;
    padding-right: 300px !important;
    margin-right: -300px !important;
  }
  @include bp(md) {
    padding-left: 3000px !important;
    margin-left: -3000px !important;
    padding-right: 3000px !important;
    margin-right: -3000px !important;
  }
}

.extendleft {
  @include bp(sm, max) {
    padding-left: 300px !important;
    margin-left: -300px !important;
    padding-right: 300px !important;
    margin-right: -300px !important;
  }
  @include bp(md) {
    padding-left: 3000px !important;
    margin-left: -3000px !important;
    padding-right: 0;
    margin-right: 0;
  }
}

.extendright {
  @include bp(sm, max) {
    padding-left: 300px !important;
    margin-left: -300px !important;
    padding-right: 300px !important;
    margin-right: -300px !important;
  }
  @include bp(md) {
    padding-left: 0;
    margin-left: 0;
    padding-right: 3000px !important;
    margin-right: -3000px !important;
  }
}

// Colors
.color-background {
  color: $color-background;
}
.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.color-black {
  color: $color-black;
}

.color-grey {
  color: $color-grey;
}

.color-white {
  color: $color-white;
}

.color-text {
  color: $color-text;
}

.color-muted {
  color: $color-muted;
}

.color-link {
  color: $color-link;
}

.color-status {
  color: $color-status;
}

.color-warning {
  color: $color-warning;
}

.color-error {
  color: $color-error;
}

// Background colors

.background-background {
  background-color: $color-background;
}

.background-primary {
  background-color: $color-primary;
}

.background-secondary {
  background-color: $color-secondary;
}

.background-black {
  background-color: $color-black;
}

.background-grey {
  background-color: $color-grey;
}

.background-grey-light {
  background-color: $color-grey-light;
}

.background-white {
  background-color: $color-white;
}

.background-text {
  background-color: $color-text;
}

.background-muted {
  background-color: $color-muted;
}

.background-link {
  background-color: $color-link;
}

.background-status {
  background-color: $color-status;
}

.background-warning {
  background-color: $color-warning;
}

.background-error {
  background-color: $color-error;
}

// Border

.default-border {
  border: $default-border;
}

.default-border-top {
  border-top: $default-border;
}

.default-border-right {
  border-right: $default-border;
}

.default-border-bottom {
  border-bottom: $default-border;
}

.default-border-left {
  border-left: $default-border;
}

// Margin

.margin-top {
  margin-top: 0.5rem !important;
}

.margin-bottom {
  margin-top: 0.5rem !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-vertical {
  margin-top: 0;
  margin-bottom: 0;
}

.no-margin-horizontal {
  margin-left: 0;
  margin-right: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-left {
  margin-left: 0;
}

// Block margin, padding & gutter
.default-block-margin-top {
  margin-bottom: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-top: $default-block-margin-bottom;
  }
}

.default-block-margin-bottom {
  margin-bottom: $default-block-margin-bottom-mobile !important;
  @include bp(md) {
    margin-bottom: $default-block-margin-bottom !important;
  }
}

.default-gutter-margin-bottom {
  margin-bottom: $gutter-mobile !important;
  @include bp(md) {
    margin-bottom: $gutter !important;
  }
}

.default-gutter-padding-bottom {
  padding-bottom: $gutter-mobile !important;
  @include bp(md) {
    padding-bottom: $gutter !important;
  }
}
.default-block-padding-bottom {
  padding-bottom: $default-block-margin-bottom-mobile !important;
  @include bp(md) {
    padding-bottom: $default-block-margin-bottom !important;
  }
}

// Padding
.padding-bottom {
  padding-bottom: 1rem !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0;
}

.no-padding-horizontal {
  padding-left: 0;
  padding-right: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-left {
  padding-left: 0;
}

// List

.ul-in-text {
  ul {
    list-style: none;
    margin-left: 1.75rem;
    li {
      margin-bottom: 0;
      position: relative;
      line-height: 1.75rem;
      &:before {
        content: '\2022';
        color: $color-text;
        position: absolute;
        left: -1.75rem;
        bottom: 0;
        top: 0;
        @include font-size(24, 28);
      }
    }
  }
}

.check-list {
  list-style: none;
  li {
    margin-bottom: 0.5rem;
    position: relative;
    line-height: 1.75rem;
    padding-left: 2.25rem;
    @include icon('check') {
      position: absolute;
      left: 0;
      top: 5px;
      color: $color-grey-darker;
      margin-right: 1rem;
    }
  }
}

// Text

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// CSS3 Text fade

.fade--text {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
  }
}
.fade--text--70pc {
  @extend .fade--text;
  &:before {
    height: 70%;
  }
}

.fade--text--80pc {
  @extend .fade--text;
  &:before {
    height: 80%;
  }
}

.fade--text--small {
  @extend .fade--text;
  &:before {
    height: 5rem;
  }
}
