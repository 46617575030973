
// Icons: AUTOMATICALLY GENERATED BY GULP
// --------------------------------------------------

$icon-font-token: 'v2ov4m7k';


.icon:before, [class^="icon-"]:before, [class^="icon-"]:after, [class*=" icon-"]:before, [class*=" icon-"]:after {
	display: inline-block;
	font-family: "icons";
  speak: none;
  @include font-size(18, 0);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 1;
}

.icon-arrow-down-double:before,
.icon-arrow-down:before,
.icon-arrow-left-double:before,
.icon-arrow-left:before,
.icon-arrow-right-double:before,
.icon-arrow-right:before,
.icon-arrow-up-double:before,
.icon-arrow-up:before,
.icon-briefcase:before,
.icon-brochure:before,
.icon-calendar:before,
.icon-check:before,
.icon-circle:before,
.icon-clipboard:before,
.icon-close:before,
.icon-coin-euro:before,
.icon-comment:before,
.icon-comments:before,
.icon-cookie:before,
.icon-dashboard:before,
.icon-edit:before,
.icon-exclamation:before,
.icon-ext-link:before,
.icon-facebook:before,
.icon-file-cv:before,
.icon-file:before,
.icon-files:before,
.icon-grid:before,
.icon-handle:before,
.icon-handshake:before,
.icon-heart:before,
.icon-help:before,
.icon-home:before,
.icon-license:before,
.icon-lightning:before,
.icon-linkedin:before,
.icon-list:before,
.icon-lock-open:before,
.icon-lock:before,
.icon-logout:before,
.icon-mail:before,
.icon-marker:before,
.icon-menu:before,
.icon-minus:before,
.icon-more:before,
.icon-newspaper:before,
.icon-phone:before,
.icon-play-video:before,
.icon-plus:before,
.icon-price-tag:before,
.icon-print:before,
.icon-read-more:before,
.icon-reset:before,
.icon-right-chevron:before,
.icon-search:before,
.icon-social-facebook:before,
.icon-social-google-plus:before,
.icon-social-linkedin:before,
.icon-social-twitter:before,
.icon-star-fill:before,
.icon-star-half:before,
.icon-star:before,
.icon-stats:before,
.icon-status-error:before,
.icon-status-help:before,
.icon-status-info:before,
.icon-status-warning:before,
.icon-tag:before,
.icon-time:before,
.icon-trash:before,
.icon-triangle-down:before,
.icon-triangle-left:before,
.icon-triangle-right:before,
.icon-triangle-up:before,
.icon-twitter:before,
.icon-user-plus:before,
.icon-user:before,
.icon-wrench:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


.icon-arrow-down-double:before {
	content: "\E001";
}

.icon-arrow-down:before {
	content: "\E002";
}

.icon-arrow-left-double:before {
	content: "\E003";
}

.icon-arrow-left:before {
	content: "\E004";
}

.icon-arrow-right-double:before {
	content: "\E005";
}

.icon-arrow-right:before {
	content: "\E006";
}

.icon-arrow-up-double:before {
	content: "\E007";
}

.icon-arrow-up:before {
	content: "\E008";
}

.icon-briefcase:before {
	content: "\E009";
}

.icon-brochure:before {
	content: "\E00A";
}

.icon-calendar:before {
	content: "\E00B";
}

.icon-check:before {
	content: "\E00C";
}

.icon-circle:before {
	content: "\E00D";
}

.icon-clipboard:before {
	content: "\E00E";
}

.icon-close:before {
	content: "\E00F";
}

.icon-coin-euro:before {
	content: "\E010";
}

.icon-comment:before {
	content: "\E011";
}

.icon-comments:before {
	content: "\E012";
}

.icon-cookie:before {
	content: "\E013";
}

.icon-dashboard:before {
	content: "\E014";
}

.icon-edit:before {
	content: "\E015";
}

.icon-exclamation:before {
	content: "\E016";
}

.icon-ext-link:before {
	content: "\E017";
}

.icon-facebook:before {
	content: "\E018";
}

.icon-file-cv:before {
	content: "\E019";
}

.icon-file:before {
	content: "\E01A";
}

.icon-files:before {
	content: "\E01B";
}

.icon-grid:before {
	content: "\E01C";
}

.icon-handle:before {
	content: "\E01D";
}

.icon-handshake:before {
	content: "\E01E";
}

.icon-heart:before {
	content: "\E01F";
}

.icon-help:before {
	content: "\E020";
}

.icon-home:before {
	content: "\E021";
}

.icon-license:before {
	content: "\E022";
}

.icon-lightning:before {
	content: "\E023";
}

.icon-linkedin:before {
	content: "\E024";
}

.icon-list:before {
	content: "\E025";
}

.icon-lock-open:before {
	content: "\E026";
}

.icon-lock:before {
	content: "\E027";
}

.icon-logout:before {
	content: "\E028";
}

.icon-mail:before {
	content: "\E029";
}

.icon-marker:before {
	content: "\E02A";
}

.icon-menu:before {
	content: "\E02B";
}

.icon-minus:before {
	content: "\E02C";
}

.icon-more:before {
	content: "\E02D";
}

.icon-newspaper:before {
	content: "\E02E";
}

.icon-phone:before {
	content: "\E02F";
}

.icon-play-video:before {
	content: "\E030";
}

.icon-plus:before {
	content: "\E031";
}

.icon-price-tag:before {
	content: "\E032";
}

.icon-print:before {
	content: "\E033";
}

.icon-read-more:before {
	content: "\E034";
}

.icon-reset:before {
	content: "\E035";
}

.icon-right-chevron:before {
	content: "\E036";
}

.icon-search:before {
	content: "\E037";
}

.icon-social-facebook:before {
	content: "\E038";
}

.icon-social-google-plus:before {
	content: "\E039";
}

.icon-social-linkedin:before {
	content: "\E03A";
}

.icon-social-twitter:before {
	content: "\E03B";
}

.icon-star-fill:before {
	content: "\E03C";
}

.icon-star-half:before {
	content: "\E03D";
}

.icon-star:before {
	content: "\E03E";
}

.icon-stats:before {
	content: "\E03F";
}

.icon-status-error:before {
	content: "\E040";
}

.icon-status-help:before {
	content: "\E041";
}

.icon-status-info:before {
	content: "\E042";
}

.icon-status-warning:before {
	content: "\E043";
}

.icon-tag:before {
	content: "\E044";
}

.icon-time:before {
	content: "\E045";
}

.icon-trash:before {
	content: "\E046";
}

.icon-triangle-down:before {
	content: "\E047";
}

.icon-triangle-left:before {
	content: "\E048";
}

.icon-triangle-right:before {
	content: "\E049";
}

.icon-triangle-up:before {
	content: "\E04A";
}

.icon-twitter:before {
	content: "\E04B";
}

.icon-user-plus:before {
	content: "\E04C";
}

.icon-user:before {
	content: "\E04D";
}

.icon-wrench:before {
	content: "\E04E";
}


