// Messages
// --------------------------------------------------

.messages,
.status-messages,
.filter-guidelines {
  padding: 2rem 2rem;
  border: 2px solid;
  border-color: $color-border;
  background-color: $color-grey-light;
  color: $color-black;
  @include icon(exclamation) {
    @include font-size(25);
    margin-right: 2rem;
    float: left;
  }
  //&.status,
  //&.status--status {
  //  border-color: $color-status;
  //  background-color: lighten($color-status, 30%);
  //}
  &.warning,
  &.status--warning,
  &.crm-warning {
    border-color: $color-warning;
    background-color: lighten($color-warning, 20%);
  }
  &.error,
  &.crm-error,
  &.status--error {
    border-color: $color-error;
    background-color: lighten($color-error, 30%);
  }
}

.messages {
  @extend .ul-in-text;
  margin-bottom: 1rem;
  @include font-size(16, 22);

  ul {
    margin-bottom: 0;
    padding-left: 2rem;

    li {
      &:before {
        content: "";
      }
    }
  }

  p {
    padding-left: 2rem;
  }
}

.status-messages {
  margin-bottom: $default-block-margin-bottom;
}

.filter-guidelines {
  margin-bottom: $default-block-margin-bottom;
  ul {
    margin: 0;
  }
  li {
    @include description();
  }
}