
// Pager
// --------------------------------------------------

.item-list {
  @extend .clearfix;
}

ul.pager {

  // Globals
  list-style: none;
  position: relative;
  display: block;
  float: left;
  left: 50%;
  @include font-size($pager-font-size, $pager-font-size);
  li {
    position: relative;
    display: block;
    float: left;
    right: 50%;
    margin: 0;
    text-align: center;
    a {
      display: block;
    }
  }
  li:not(:last-child) {
    margin-right: 0.375rem;
  }
  li.pager-first a,
  li.pager-previous a, 
  li.pager-next a,
  li.pager-last a,
  li.pager-item a,
  li.pager-ellipsis,
  li.pager-current {
    padding: $pager-padding;
    min-width: ptr($pager-font-size) + ($pager-padding * 2);
    text-decoration: none;
  }

  // Icons
  li.pager-first a {
    @include icon('arrow-left-double') {
      @include font-size(14, 0);
    }
  }
  li.pager-previous a {
    @include icon('arrow-left'){
      font-weight: 700;
      @include font-size(18, 0);
    }
  }
  li.pager-next a {
    @include icon('arrow-right'){
      font-weight: 700;
      @include font-size(18, 0);
    }
  }
  li.pager-last a {
    @include icon('arrow-right-double') {
      @include font-size(14, 0);
    }
  }
  
  // Controls
  li.pager-first,
  li.pager-previous, 
  li.pager-next,
  li.pager-last {
    a {
      //@extend .button;
      margin: 0;
      text-decoration: none;
    }
    span {
      @extend .element-invisible;
    }
  }

  // Pages
  li.pager-item {
    a {

    }
  }

  // Ellipis
  li.pager-ellipsis {
    color: $color-muted;
  }

  // Active item
  li.pager-current {
    color: $color-white;
    background-color: $color-grey-dark;
  }
  
}

// Mini pager

// @TODO create class for ul.pager.mini-pager
// for now, add occurences of your mini pagers below
ul.mini-pager {
  li.pager-current {
    padding: $pager-padding;
    min-width: 0;
    color: $color-text;
    background: none;
  }
}
