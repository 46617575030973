#views-exposed-form-civi-cv-databank-cv-databank-pane {
  .views-exposed-form .views-exposed-widget {
    transition: $default-transition;
    width: 100%;
    padding: 0;

    &.views-reset-button,
    &.views-submit-button {
      margin-right: 1.375rem;
      margin-bottom: 1.375rem;
      width: auto;
    }

    &.views-reset-button {
      margin-bottom: 1.375rem * 2;
    }

    legend {
      margin-bottom: 1rem;
      width: 100%;

      a {
        @include font-size(18);

        cursor: pointer;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        padding: 0;
        background: transparent;
        border: 0;
        display: inline-block;
        width: 100%;

        @include icon('arrow-up', after) {
          position: absolute;
          right: 0;
          transition: $default-transition;
        };
      }
    }

    > fieldset {
      border-bottom: 1px solid $color-grey;
      margin-bottom: $gutter-mobile/2;
      padding-bottom: $gutter-mobile/2;

      @include bp(md) {
        margin-bottom: $gutter/2;
        padding-bottom: $gutter/2;
      }

      &.closed {
        > legend {
          margin-bottom: 0;
        }

        .fieldset-title:after {
          transform: rotate(180deg);
        }
      }
    }

    .form-type-bef-checkbox {
      input {
        appearance: checkbox;
      }

      label {
        display: inline-block;
        text-transform: initial;
        width: auto;

        &:before {
          content: none;
        }

        &:hover {
          color: $jp-color-red;
        }
      }
    }

    .bef-checkboxes {
      display: inline-block;
      width: 100%;

       .form-item {
         margin-bottom: 0.25rem;
         position: relative;
       }
    }

    .readmore-link {
      padding: 0;
      border-bottom: 0;
      text-transform: initial !important;

      display: inline-block;
      margin-top: .5rem;
      text-decoration: none;

      @include icon('arrow-down', after) {
        margin-left: .25rem;
        transition: $default-transition;
      };

      &.open {
        &:after {
          transform: rotate(180deg);
        }
      }

      &:hover {
        background: transparent;
      }
    }

    .bef-select-as-checkboxes .form-type-bef-checkbox label,
    .bef-select-as-checkboxes .form-type-bef-checkbox div.field-label,
    .bef-select-as-checkboxes .form-type-bef-checkbox span.field-label,
    .bef-select-as-checkboxes .form-type-bef-checkbox .label-inline {
      background: transparent;
      padding: 0;
      padding-left: 23.5px;
      border-bottom: 0;
    }

    .bef-select-as-checkboxes .form-type-bef-checkbox input:checked + label,
    .bef-select-as-checkboxes .form-type-bef-checkbox input:checked + div.field-label,
    .bef-select-as-checkboxes .form-type-bef-checkbox input:checked + span.field-label,
    .bef-select-as-checkboxes .form-type-bef-checkbox input:checked + .label-inline {
      color: $color-text;
    }

    .form-type-radio input,
    .form-type-checkbox input,
    .form-type-bef-checkbox input {
      position: absolute !important;
      top: 0;
      left: 0;
      height: 1rem;
      width: 1rem;
      clip: initial;
      margin-right: .25em;
    }

    &.views-submit-button {
      padding-bottom: 0;
      border: 0;
    }

    .form-submit {
      margin-top: 0;
    }
  }
}
