// Region: Header
// --------------------------------------------------

.region--header {
  position: relative;
  margin-bottom: $default-block-margin-bottom;
  background: $color-white;
  top: 2.3125rem;
  padding-top: 1rem;

  @include bp(md) {
    padding: 0;
    margin-bottom: $default-block-margin-bottom;
  }
}

// Sticky header

$header-height: 7.8125rem;
$header-height-small: 5rem;
@include bp(md) {
  #site-wrapper {
    padding-top: 10.125rem;
  }

  .region--header {
    z-index: 100;
    position: absolute;
    left: 0;
    top: 2.3125rem;
    width: 100%;
    height: $header-height;
    margin: 0;
    transition: all $default-transition;
    .admin-menu &,
    .adminimal-menu & {
      top: 4.125rem;
    }
    .header-small & {
      height: $header-height-small;
      padding: 1rem 0;
    }
    > .container {
      height: 100%;
    }
  }

  .site-logo {
    img, svg {
      height: 4.5rem;
      transition: height $default-transition;
      .header-small & {
        height: 3rem;
      }
    }
  }
}


// Branding

.branding {
  float: left;
  margin-bottom: 0;
  padding-bottom: 1rem;
  .site-name,
  .site-slogan {
    display: none;
  }
  .site-logo-desktop {
    display: none;
  }
  .site-logo {
    path {
      transition: fill $default-transition;
    }
  }
  .site-logo:hover {
    path {
      fill: $color-link-hover;
    }
  }
  @include bp(md) {
    padding-bottom: 0;
    margin-right: 3rem;
    line-height: $header-height;
    img {
      vertical-align: middle;
    }
    .site-logo-desktop {
      display: block;
    }
    .site-logo-mobile {
      display: none;
    }
  }
}

// wrapper for blocks

.header-region {
  @include bp(md) {
    @include clearfix;
  }
}


// Mobile
@include bp(md) {
  .top-menu-mobile {
    display: none;
  }
}
@include bp(sm, max){
  .top-menu {
    display: none;
  }
  .branding {
    float: left;
    .site-logo img {
      max-height: 2.25rem;
    }
  }
  .header-region {
    float: right;
  }

}

