// Blocks
// --------------------------------------------------
.eventsreg-container {
  @extend .memberreg-container;
}

.eventsreg-block {
  @extend .memberreg-block;
}

.eventsreg-intro {
  @extend .memberreg-intro;
}

.eventsreg-preview {
  @extend .memberreg-preview;
}

.eventsreg-footer {
  @extend .memberreg-footer;
}

#eventsreg-price {
  .crm-price-amount-label {
    font-weight: 400 !important;
  }
  #pricesetTotal {
    margin-top: 2rem;
  }
  #pricevalue {
    @include font-size(16);
    font-weight: 600;
  }
}
#eventsreg-eventfee {
  table, .header-dark {
    max-width: 31.25rem;
  }
}

// Billing
// --------------------------------------------------
#payment_information {
  background-color: $color-grey;
  padding: $gutter-mobile;
  fieldset {
    padding: $default-block-margin-bottom-mobile 0;
    border-bottom: $default-border;
    &:first-child {
      margin-top: 0;
    }
    @include bp(md) {
      padding: $default-block-margin-bottom 0;
    }
    legend {
      @extend h3;
      margin-bottom: 0;
    }
    .crm-section {
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
      label {
        display: inline-block;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.credit_card_type-section {
  .form-select-wrapper {
    display: none;
  }
}

// Progress Pager
// --------------------------------------------------
.event-registration-pager {
  @extend .clearfix;
  margin-bottom: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-bottom: $default-block-margin-bottom;
  }
  .progress-bar {
    list-style: none;
    overflow: hidden;
    margin: 0;
    padding: 0;

    li {
      text-decoration: none;
      padding: 0 1.125rem 0 2.375rem;
      position: relative;
      display: block;
      float: left;
      border: $default-border;
      @include font-size(13, 38);
      color: $color-grey-darker;
      text-transform: uppercase;

      @include bp(sm, max) {
        &:not(.active) {
          max-width: 39px;
          height: 39px;
        }
      }
    }
    li:after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 1.1875rem solid transparent; /* Go big on the size, and let overflow hide */
      border-bottom: 1.1875rem solid transparent;
      border-left: 1.1875rem solid $color-white;
      position: absolute;
      top: 50%;
      margin-top: -1.1875rem;
      left: 100%;
      z-index: 2;
    }
    li:before {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 1.1875rem solid transparent; /* Go big on the size, and let overflow hide */
      border-bottom: 1.1875rem solid transparent;
      border-left: 1.1875rem solid $color-border;
      position: absolute;
      top: 50%;
      margin-top: -1.1875rem;
      margin-left: 1px;
      left: 100%;
      z-index: 1;
    }
    li:first-child {
      padding-left: 1.625rem;
    }
    li.active {
      background-color: $color-grey-dark;
      color: $color-white;
      &:after {
        border-left-color: $color-grey-dark;
      }
    }

    /* li:nth-child(2) {
       background: $color-white;
     }
     li:nth-child(2):after {
       border-left-color: $color-white;
     }
     li:nth-child(3) {
       background: $color-white;
     }
     li:nth-child(3):after {
       border-left-color: $color-white;
     }
     li:nth-child(4) {
       background: $color-white;
     }
     li:nth-child(4):after {
       border-left-color: $color-white;
     }
     li:nth-child(5) {
       background: $color-white;
     }
     li:nth-child(5):after {
       border-left-color: $color-white;
     }*/

  }
}