.node--company {
  .group-company-details {
    background: $color-grey;
    padding: $gutter-mobile;
    margin-bottom: $gutter-mobile;

    @include bp(md) {
      padding: $gutter/2;
      margin-bottom: $gutter;
    }

    h3 {
      display: none;
    }

    > .field-group-format-wrapper {
      > div {
        margin-bottom: $gutter-mobile/2;

        @include bp(md) {
          margin-bottom: $gutter/2;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .field-label {
      margin-bottom: .25rem;
    }

    .field--twitter,
    .field--facebook,
    .field--linkedin {
      display: inline-block;
      margin-right: 1rem;

      a {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-indent: 40px;
        width: 32px;
        text-decoration: none;
        height: 32px;

        &:before {
          text-indent: 0;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .field--twitter a {
      @include icon('twitter') {
        @include font-size(32, 32);
      };
    }
    .field--facebook a {
      @include icon('facebook') {
        @include font-size(32, 32);
      };
    }
    .field--linkedin a {
      @include icon('linkedin') {
        @include font-size(31, 31);
      };
    }
  }


  &--vertical-teaser {
    background: $color-white;
    border: 1px solid $color-grey;
    box-shadow: 0 0 0 transparent;
    display: flex;
    flex-wrap: wrap;
    transition: $default-transition;
    width: 100%;

    &:hover {
      box-shadow: $jp-box-shadow;
    }

    .ds-image {
      display: flex;
      align-items: center;
    }

    .image-wrapper,
    .content-wrapper {
      padding: 2rem;
    }

    .image-wrapper {
      display: flex;
      height: 205px;
      justify-content: center;
      width: 100%;

      & + .content-wrapper {
        height: calc(100% - 205px);
      }
    }

    .content-wrapper {
      background: $color-grey;
      display: flex;
      height: 100%;
      transition: $default-transition;
      width: 100%;
    }

    .field--title h3 a {
      font-weight: normal;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }

  &--small-teaser {
    .ds-center {
      display: flex;
      flex-wrap: wrap;
    }

    .ds-first,
    .ds-second {
      display: flex;
    }

    @include bp(sm, max) {
      .ds-first {
        margin-bottom: 1.5rem;
      }
    }

    .field--logo,
    .group-inner-wrapper {
      border: 1px solid $color-grey;
      padding: $gutter-mobile/2;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @include bp(md) {
        padding: $gutter/2;
      }
    }

    .field--logo {
      align-content: center;
      justify-content: center;
    }
    
    .field--title {
      a {
        text-decoration: underline;
      }
    }

    &[type="Recruiting"],
    &[type="Bedrijf"] {
      .field--node-link {
        display: none;
      }
    }

    .field--node-link {
      margin-top: 1rem;

      a {
        font-size: 85%;
        text-transform: none;

        &:after {
          content: none;
        }
      }
    }
  }


  &--teaser {
    padding: 1.375rem;
    border-bottom: 1px solid $color-grey;

    padding-bottom: $gutter-mobile/2;
    @include bp(md) {
      padding-bottom: $gutter/2;
    }

    &.node--sticky {
      border: 1px solid $color-grey;
      border-left: 3px solid $color-primary;
    }

    .field--title a {
      text-decoration: underline;

      &:hover {
        color: $jp-color-red;
      }
    }

    .field--node-link {
      margin-top: 1rem;

      a {
        text-transform: none;

        &:after {
          content: none;
        }
      }
    }

    > .ds-center {
      display: flex;

      > .ds-second {
        display: flex;
        align-items: center;
      }
    }
  }

  &--company-employees,
  &--company-application,
  &--company-vacancies,
  &--full {
    > .ds-header {
      position: relative;
      margin-bottom: $gutter-mobile;
      @include bp(md) {
        margin-bottom: $gutter;
      }

      .field--img {
        img {
          object-fit: cover;
          min-height: 245px;
          vertical-align: top;
        }
      }

      .field--logo {
        position: absolute;
        top: 54px;
        bottom: 54px;
        left: 50%;
        margin-left: -100px;
        display: flex;
        align-items: center;

        @include bp(md) {
          left: 54px;
          margin-left: 0;
        }
      }
    }

    .field--ds-contact + .field--testimonies {
      border-top: 1px solid $color-grey;
      padding-top: $gutter-mobile/2;
      margin-top: $gutter-mobile/2;

      @include bp(md) {
        padding-top: $gutter/2;
        margin-top: $gutter/2;
      }
    }

    .field--testimonies {
      label {
        margin-bottom: 1rem;
        font-weight: 400;
        font-family: $title-font;
        color: $color-grey-dark;

        @include font-size(17, 30);

        @include bp(md) {
          @include font-size(20, 30);
        }
      }
    }
  }


  &--company-vacancies {
    .field--views-embed {
      margin-top: $gutter-mobile;
      @include bp(md) {
        margin-top: $gutter;
      }
    }
  }

  &--company-vacancies,
  &--company-application {
    > .ds-header {
      min-height: 245px;
    }
  }


  &--full {
    .field .field-label {
      font-weight: normal;
    }

    > .ds-header {
      min-height: 245px;
    }

    > .ds-center {

      > .ds-first,
      > .ds-second {
        margin-bottom: $gutter-mobile;
        @include bp(md) {
          margin-bottom: $gutter;
        }
      }

      .field--jp-vacsol-ds-companypartner-menu {
        .field-item img {
          display: none;
        }
      }

      .field--jp-vacsol-custom-link-text-2 {
        float: right;
        line-height: 30px;
      }

      .field--company-website {
        border-bottom: 1px solid #eee;
        padding-bottom: $gutter-mobile;
        margin-bottom: $gutter-mobile;
        @include bp(md) {
          padding-bottom: $gutter;
          margin-bottom: $gutter;
        }
      }

      .field--video {
        margin-bottom: $gutter-mobile;
        @include bp(md) {
          margin-bottom: $gutter;
        }
      }

      .field--company-images {
        .field--img {
          .field-items {
            @include row();
          }

          .field-item {
            @include col();
            @include cols(6);

            &:first-of-type {
              display: none;
            }
          }
        }
      }

      .field--jp-vacsol-custom-link-text-4,
      .field--views-embed {
        margin-bottom: $gutter-mobile/2;
        @include bp(md) {
          margin-bottom: $gutter/2;
        }
      }

      .field--jp-vacsol-custom-link-text-3 {
        border-top: 1px solid $color-grey;
        padding-top: $gutter-mobile/2;
        margin-top: $gutter-mobile/2;
        margin-bottom: $gutter-mobile/2;

        @include bp(md) {
          padding-top: $gutter/2;
          margin-top: $gutter/2;
          margin-bottom: $gutter/2;
        }
      }
    }
  }

  &--company-basic {
    > .ds-header {
      @include row();

      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1.375rem;

      > .field--logo {
        @include col();

        @include bp(md) {
          width: (100/3)*1%
        }

        .field-items {
          border: 1px solid $color-grey;
          padding: $gutter-mobile/2;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;

          @include bp(md) {
            padding: $gutter/2;
          }
        }
      }

      > .group-wrapper {
        @include col();

        @include bp(md) {
          width: ((100/3)*2)*1%
        }

        .group-inner-wrapper {
          border: 1px solid $color-grey;
          padding: $gutter-mobile/2;
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          align-content: flex-start;

          @include bp(md) {
            padding: $gutter/2;
          }
        }
      }
    }
  }
}


.node-company-form {
  .group-header,
  .group-left,
  .group-right {
    margin-bottom: $gutter-mobile/2;
    width: 100% !important;
    padding-right: 0 !important;

    @include bp(md) {
      margin-bottom: $gutter/2;
    }

    .form-wrapper {
      margin-bottom: $gutter-mobile/2;

      @include bp(md) {
        margin-bottom: $gutter/2;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ctools-modal-media-file-edit {
    display: none;
  }

  .form-item {
    select,
    input {
      width: 100%;
    }
  }

  .form-select-wrapper {
    width: 100%;
  }

  .image-widget-data {
    width: calc(100% - 110px);

    .file-size {
      margin-right: 1rem;
    }

    button {
      margin-bottom: 1rem;
    }

    > .form-item {
      display: none;
    }
  }

  .field-name-field-textarea-1,
  .field-name-body {
    .ckeditor_links,
    .filter-wrapper {
      display: none !important;
    }
  }

  .vertical-tabs-panes {
    padding-bottom: 1em;
    padding-top: 1em;
  }

  .fieldset-wrapper .fieldset-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;;
  }

  .location .form-item {
    label.option {
      display: block;
      float: left;
      text-align: left;
      width: 100%;
      padding-right: 10px;
    }

    input,
    .form-select-wrapper {
      display: inline-block;
      width: 100%;
      margin-bottom: 5px;
    }

    &.form-item-field-location-und-0-re-geocode-location,
    &.form-item-field-location-und-0-delete-location {
      display: none;
    }
  }

  #edit-preview {
    display: none;
  }
}

.field--jp-vacsol-custom-link-text-1 {
  border-bottom: 1px solid $color-grey;
  padding-bottom: $gutter-mobile/2;
  margin-bottom: $gutter-mobile/2;
  text-align: center;

  @include bp(md) {
    padding-bottom: $gutter/2;
    margin-bottom: $gutter/2;
    text-align: left;
  }

  > .field-items {
    background: $color-grey;
    padding: $gutter-mobile/2;

    @include bp(md) {
      padding: $gutter/2;

      > .field-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .infobox-info {
    p:last-of-type {
      margin-bottom: 0;
    }

    @include bp(md) {
      padding-right: 1.5rem;
    }
  }

  .infobox-link a {
    @include button();
    @include jp-button-secondary();
    margin-bottom: 0;
    margin-top: 1rem;

    @include bp(md) {
      margin-top: 0;
    }
  }
}
