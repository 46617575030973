// CIVI event - Globals
.civi-event {
  .second {
    .crm-section {
      padding-bottom: 1.5rem;
      margin: 1.5rem 0;

      &.event_fees-section {
        .content {
          @include font-size(13);
        }
      }

      #google_map {
        width: 100% !important;
      }
    }
  }
  .civi--component {
    margin-bottom: 0;
    @include bp(md){
      margin-bottom: $default-block-margin-bottom;
    }
  }
  // CRM-section
  .crm-section {
    border-bottom: $default-border;
    padding-bottom: $default-block-margin-bottom-mobile;
    margin: $gutter-mobile 0;
    @include bp(md) {
      margin: $gutter 0;
      padding-bottom: $default-block-margin-bottom;
    }

    &.field--image-event-cover,
    &.crm-actionlinks-top {
      border-bottom: none;
      padding-bottom: 0 !important;
    }
  }
  .event_fees-section {
    ul {
      margin: 0;
      li {
        list-style: none;
        @include font-size(13, 23);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .label label {
      line-height: 1.625rem;
      font-weight: 600;
      position: relative;
      display: block;
      text-transform: uppercase;
      @include icon(price-tag) {
        margin-right: 0.5rem;
      }
    }
  }
}

// CIVI event - Teasers

// Event-info-top
.event-top-info {
  @extend .clearfix;
  margin-bottom: $default-block-margin-bottom-mobile;
  @include bp(md) {
    margin-bottom: $default-block-margin-bottom;
  }
}

// Register section
.register_link-section {
  text-align: center;
  background-color: $color-primary;
  color: $color-white;
  padding: 1.75rem 1rem;
  .price {
    @include font-size(20, 26);
    margin-bottom: 0.75rem;
  }
}

// Event intro
.event-intro {
  margin-bottom: $gutter-mobile;
  @include bp(md) {
    margin-bottom: $gutter;
  }
}