// Nieuws teaser
.node--nieuws--teaser {
  .ds-header, .ds-center {
    margin-bottom: $default-block-margin-bottom;
    @include bp(md) {
      margin-bottom: $default-block-margin-bottom-mobile;
    }
  }
  .field--title * a:hover {
    color: $color-primary;
    text-decoration: underline;
  }
}

.node--nieuws--full, .node--nieuws--protected-content {
  .group-footer-wrap {
    padding: 2rem 0;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    > * {
      @include col();
    }
  }
  .field--afdeling,
  .field--social-share {
    @include bp(md) {
      @include cols(6);
    }
  }
  .field--afdeling {
    text-align: center;
    margin-top: 0.5rem;
    @include bp(md) {
      text-align: left;
    }
  }
  .field--social-share {
    text-align: center;
    @include bp(md) {
      float: right;
      margin: 0;
      text-align: right;
    }
  }
}

.node--nieuws--search-result {
  .field--datum,
  .field--title {
    float: left;
    clear: none;
  }
  .field--datum {
    margin-right: 1rem;
  }
}