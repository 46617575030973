// Region: Footer
// --------------------------------------------------

.region--footer {
  padding: $default-block-margin-bottom-mobile 0;
  background: $color-footer;
  color: $color-grey;
  @include bp(md) {
    padding: $default-block-margin-bottom 0;
  }
  .nieuwsbrief-block,
  .footer-menu-block {
    a.button {
      text-transform: none;
      font-weight: 400;
    }
    @include bp(sm) {
      float: left;
      margin-bottom: 2rem;
      min-height: 12.25rem;
    }
  }
  .nieuwsbrief-block {
    padding: 0.25rem 0;

    .optin-item-title {
      @include font-size(15, 30);
      color: $color-grey-darker;
      display: block;
      font-weight: 400;
    }

    label {
      font-weight: 400;
    }

    .optin-item .optin-item-content button.form-submit {
      @extend .button-primary;
    }
  }
  .footer-menu-block {
    .menu-block-wrapper {
      > ul.menu {
        width: 100%;
        > li {
          @include bp(xs) {
            padding-right: 1rem;
            float: left;
            width: 50%;
          }
          @include bp(md) {
            float: left;
            width: 25%;
          }
        }
      }
    }
    @include bp(sm) {
      width: 70%;
    }
    @include bp(md) {
      width: 80%;
    }
  }
  .nieuwsbrief-block {
    clear: both;

    @include bp(sm) {
      width: 30%;
      clear: none;
    }
    @include bp(md) {
      width: 20%;
    }
  }
  ul.menu {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 0.25rem 0;

      span.nolink, a {
        color: $color-grey;
      }
      span.nolink {
        @include font-size(15, 30);
        color: $color-grey-darker;
        margin-bottom: 0.5rem;
        display: block;
      }
      a, a.button {
        text-decoration: none;
        font-weight: 400;
        @include font-size(13);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  #block-menu-menu-copyright {
    @extend .clearfix;
    border-top: 1px solid $color-grey-darker;
    padding-top: 2rem;
    width: 100%;
    clear: both;
    ul.menu {
      li {
        padding: 0;
        margin: 0;
        display: inline-block;
        &:after {
          content: "\002D";
          margin: 0 4px;
          color: $color-grey-darker
        }
        &.last:after {
          content: none;
        }
        * {
          @include font-size(12);
          color: $color-grey-darker
        }
        .nolink {
          display: inline;
        }

      }
    }
  }
  .quicklinks {
    .content a {
      @include font-size(13);
    }
    .content a:hover {
      color: $color-grey !important;
    }
    .content a.button{
      @extend .button-primary;
      padding: 0.625rem 1.5rem;
      @include font-size(13,12);
      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .block-masquerade {
    margin-top: 25px;

    h2 {
      color: $color-white;
    }

    input[type='text'] {
      padding: 6px;
    }

    button {
      @extend .button;
      @extend .button-primary;
    }

    .description {
      @include font-size(16);
      color: $color-grey;

      a {
        color: $color-white;
      }

      #quick_switch_links {
        margin-top: 10px;
        ul {
          list-style: none;
          margin: 10px 0 0;

          a {
            @extend .button;
            @extend .button-primary;
            text-transform: lowercase;
            font-weight: 400;
          }
        }
      }
    }
  }
}