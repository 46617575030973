// Buttons
// --------------------------------------------------

.button, button,
input[type="submit"], input[type="reset"], input[type="button"] {
  @include button();
}

// Small
.button-small {
  @include font-size(14);
  padding: 0.5rem 0.75rem;
  &:before {
    margin-right: 0.2rem;
  }
  &:after {
    margin-left: 0.2rem;
  }
}

.button-inverse {
  background-color: $color-grey-dark;
  border: solid 2px $color-grey-dark;
  color: $color-white;
}

// Large
.button-large {
  @include font-size(14);
  padding: 0.8rem 2rem;
}

// Primary
.button-primary,
.field--body .button-primary{
  color: $color-white;
  background-color: $color-button-primary;
  border: solid 1px $color-button-primary;
}

.field--body .button-primary:hover,
.field--body .button-primary:focus,
.button-primary:hover,
.button-primary:focus {
  color: $color-white;
  background-color: lighten($color-button-primary, 10%);
  border-color: lighten($color-button-primary, 10%);
}

// Secondary
.button-secondary {
  color: $color-white;
  background-color: $color-secondary;
  border: solid 1px $color-secondary;
}

.button-secondary:hover,
.button-secondary:focus {
  color: $color-white;
  background-color: lighten($color-secondary, 10%);
  border-color: lighten($color-secondary, 10%);
}

// CTA
.button-cta {
  @include icon(arrow-right, after) {
    margin-left: 0.25rem;
  }
}

// Grey
.button-muted, .button-grey {
  color: $color-white !important;
  background-color: $color-muted;
  border: solid 1px $color-muted;
}

.button-muted:hover,
.button-grey:hover,
.button-muted:focus,
.button-grey:focus {
  color: $color-white !important;
  background-color: lighten($color-muted, 10%);
  border-color: lighten($color-muted, 10%);
}
.button-grey-dark {
  color: $color-white !important;
  background-color: $color-grey-darkest;
  border: solid 1px $color-grey-darkest;
}
.button-grey-dark:hover,
.button-grey-dark:focus {
  color: $color-white !important;
  background-color: lighten($color-grey-darkest, 10%);
  border-color: lighten($color-grey-darkest, 10%);
}
// White-border transparent
.button-transparent-white {
  @extend .button-cta;
  border: 2px solid $color-white !important;
  color: $color-white !important;
  background: transparent !important;
}
.button-transparent-white:hover,
.button-transparent-white:focus {
  color: $color-text !important;
  background-color: $color-white !important;
}




// Error
.button-error {
  color: $color-white;
  background-color: $color-error;
  border: none;
}

.button-error:hover,
.button-error:focus {
  color: $color-white;
  background-color: lighten($color-error, 10%);
}

// Warning
.button-warning {
  color: $color-white;
  background-color: $color-warning;
  border: none;
}

.button-warning:hover,
.button-warning:focus {
  color: $color-white;
  background-color: lighten($color-warning, 10%);
}

// Status
.button-status {
  color: $color-white;
  background-color: $color-status;
  border: none;
}

.button-status:hover,
.button-status:focus {
  color: $color-white;
  background-color: lighten($color-status, 10%);
}

.button-circle {
  display: block;
  position: relative;
  padding: 0;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border: solid 2px $color-muted;
  border-radius: 100%;
  background: transparent;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0 !important;
    color: $color-muted;
    text-align: center;
  }
  &:hover {
    border-color: $color-link-hover;
    background: transparent;
    &:before {
      color: $color-link-hover;
    }
  }
  > span {
    @extend .element-invisible;
  }
}

// JP Primary
.button-jp-primary {
  @include jp-button-primary();
}

.button-jp-primary-alt {
  @include jp-button-primary-alt();
}
