// Navigation
// --------------------------------------------------
.region--navigation {
  background-color: $color-grey;
  display: none;
  width: 100% !important;
  border-bottom: 2px solid $color-grey-darker;
  @include bp(sm, max) {
    display: none !important;
  }
  @include bp(md) {
    position: relative;
    overflow: hidden;
  }
}

// Top menu
// --------------------------------------------------


#block-ie-net-user-userprofile-dropdown-menu {
  display: none;
  background-color: white;
  position: absolute;
  right: 0;
  top: 37px;
  z-index: 300;
  color: $color-grey-dark;

  &.open {
    display: block;
  }

  .not-logged-in & {
    display: none;
  }

  .content {
    padding: 20px;
    border: 1px solid $color-grey-medium;
  }

  .top {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-grey-medium;
    span {
      display: block;
    }

    .username {
      color: $color-black;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .userfunction {
      color: $color-grey-medium;
      font-weight: 700;
    }
  }

  .notices {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-grey-medium;
    p {
      color: $color-black;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .review-count {
      background-color: $color-primary;
      color: $color-white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: inline-block;
      line-height: 25px;
      text-align: center;
      margin-left: 10px;
    }
  }
}

#block-menu-menu-top-menu {
  float: right;
  @include bp(md) {
    display: inline-block;
  }
}

.toolbar-region-wrapper {
  ul.menu {
    float: right;
    li {
      display: block;
      float: left;
      margin: 0;
      padding: 0;
      &.last {
        a {
          margin-right: 0;
        }
      }
      a, a.active {
        @include font-size(13);
        display: block;
        color: $color-white;
        text-decoration: none;
        padding: 0.6875rem 0.75rem;
        &.dark-primary {
          background-color: darken($color-primary, 10%);
        }
        &.dark-grey {
          background-color: $color-grey-dark;
        }
        &:hover {
          background-color: darken($color-primary, 15%);
        }
      }
    }
  }
}

// Main menu
// --------------------------------------------------
#main-menu-anchor {
  @include font-size(13);
  position: relative;
  float: right;
  text-decoration: none;
  line-height: $header-height;
  padding: 0 3.25rem 0 1.25rem;
  display: none;
  margin-left: 2px;
  font-weight: 600;
  color: $color-text;
  .open, .close {
    transition: all $slow-transition;
    position: absolute;
    right: 1.25rem;
  }
  .open {
    opacity: 1;
    @include icon('menu', after, false) {
      margin-left: 0.5rem;
      font-weight: bold;
    }
  }
  .close {
    opacity: 0;
    @include icon('close', after, false) {
      margin-left: 0.5rem;
      font-weight: bold;
    }
  }
  &.active {
    .close {
      opacity: 1;
    }
    .open {
      opacity: 0;
    }
  }
  &:hover, &.active {
    background-color: $color-grey;
    color: $color-text;
  }
  @include bp(md) {
    display: block;
  }
}

.main-menu {
  @include bp(md) {
    border-bottom: 2px solid $color-border;
    margin-bottom: 2rem;
    .menu-block-wrapper {
      margin: 2rem 0;
      > ul.menu {
        padding: 1.5rem 0;
        display: table;
        > li {
          @include font-size(18);
          display: table-cell;
          border-right: $thicker-border;
          padding: 0rem 1rem;
          // This will make sure they can't add a 4th level 0 main menu item.
          &:nth-child(1n+4) {
            display: none;
          }
          &.last {
            border-right: none;
            padding-right: 0;
          }
          &.first {
            padding-left: 0;
          }
          > ul.menu {
            margin-top: 1rem;

            li {
              @include clearfix;
              @include font-size(14);
              margin-bottom: 0.8rem;
              margin-top: 0.8rem;
              position: relative;
              padding-left: 1.7rem;

              @include icon('right-chevron') {
                @include font-size(12);
                position: absolute;
                left: 0;
                top: 0;
                html.no-appearance & {
                  top: 50%;
                }
              }

              a {
                text-decoration: none;
                //float: left;
                //max-width: 80%;
              }

            }

          }
        }

      }
    }
    ul.menu {
      width: 100%;
      li {
        list-style: none;
        a {
          color: $color-text;
          &:hover {
            text-decoration: underline;
            color: $color-primary;
          }
        }
      }
    }
  }
  @include bp(lg) {
    .menu-block-wrapper {
      > ul.menu {
        > li {
          padding: 0 4rem;
          > ul.menu {
            li {
              @include icon('right-chevron') {
                @include font-size(12);
                position: absolute;
                left: 0;
              }
            }
          }
        }
        li {
          &:nth-child(2n) {
            ul.menu {
              columns: 2;
              -webkit-columns: 2;
              -moz-columns: 2;
            }
          }
        }
      }
    }
  }
}

// Sub Main menu
// --------------------------------------------------
.sub-main-menu {
  float: right;
  ul.menu {
    li {
      list-style: none;
      display: inline-block;
      margin-bottom: 0;
      a {
        font-size: 0;
        line-height: 0;
        text-decoration: none;
        color: $color-text;
        display: block;
        padding: 0 0.375rem;
        font-weight: 600;
        max-width: 45px;
        &:before {
          line-height: 2.5rem;
        }
        &:hover {
          color: $color-primary;
        }
        @include bp(md) {
          @include font-size(13);
          padding: 0 1rem;
          text-transform: uppercase;
          line-height: $header-height;
          max-width: none;
          &:before {
            content: none;
          }
          &:hover {
            background-color: $color-grey;
            color: $color-text;
          }
        }
      }
    }
  }
}

// Quicklinks
// --------------------------------------------------

.quicklinks {
  float: left;
  ul.menu {
    li {
      list-style: none;
      display: inline-block;
      margin-right: 0.75rem;
      &.last {
        margin-right: 0;
      }
      a, a.button {
        @include font-size(14);
        text-transform: none;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}

// User menu
// --------------------------------------------------

.block--user-menu {
  ul.menu {

  }
}


// CTA menu (op zoek naar)
// --------------------------------------------------

.cta-menu {
  ul.menu {
    @extend .clearfix;
    @include row();
    li {
      @include col();
      @include bp(sm) {
        @include cols(6);
      }
      @include bp(md) {
        @include col();
        @include cols(3);
      }
      a {
        &.cta-menu-item {
          @include font-size(15,24);
          background-color: $color-grey;
          border: $default-border;
          border-radius: $default-border-radius;
          text-decoration: none;
          text-align: center;
          padding: 1rem 0.75rem 1rem 0.75rem;
          display: block;
          color: $color-grey-dark;
          text-transform: uppercase;
          @include bp(md) {
            padding: 2rem 1rem 2rem 1rem;
          }
          @include icon('ext-link');
          &.cta-user {
            @include icon('user-plus');
          }
          &.cta-ilya {
            @include icon('newspaper');
          }
          &.cta-handshake{
            @include icon('handshake');
          }
          &.cta-brochure {
            @include icon('brochure');
          }
          &.cta-engineer {
            @include icon('wrench');
          }

          &:before {
            display: block !important;
            @include font-size(38, !important);
            margin-bottom: 1rem !important;
            color: $color-primary !important;
          }
          &:hover {
            background-color: darken($color-grey, 5);
            border-color: $color-form-hover;
          }

        }
      }
    }
  }
}

// Footer menu
// --------------------------------------------------

.block--footer-menu {
  ul.menu {

  }
}

// Mobile menu
// --------------------------------------------------

#mobile-menu-anchor {
  padding-left: 0.5rem;
  float: right;

  &:before {
    float: right;
    line-height: 2.5rem;
  }
}

#mobile-menu {
  .mobile-menu-inner {
    //border-top: $default-border;
    background-color: $color-primary;
  }
  .mobile-block {
    float: none;
    padding: 0.75rem 1.25rem 0 1.25rem;
  }
  .mobile-block:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
  }
  .block-menu-block, .block-menu {
    ul.menu {
      > li {
        border-bottom: 1px solid lighten($color-primary, 15%);
        &.last {
          border-bottom: none;
        }
      }
      li {
        margin: 0;
        list-style: none;
        a {
          @include font-size(14);
          display: block;
          padding: 0.75rem 0;
          color: $color-white;
          text-decoration: none;
          margin: 0;
          &.button.button-grey {
            background-color: darken($color-primary, 10%);
            border: none;
            margin-top: 1rem;
          }
        }
        ul {
          li {
            border-bottom: none;
            display: none;
            &.show-mobile {
              background-color: lighten($color-primary, 5%);
              display: block;
              padding: 0rem 0.5rem;
              a {
                padding: 0.875rem 0.5rem;
                border-bottom: 1px solid lighten($color-primary, 15%);
              }
              &.last {
                a {
                  border-bottom: none;
                }
              }
              &:first-child {
                margin-top: 1rem;
              }
            }
          }
        }
      }
      li.active-trail {
        > ul {
          display: block;
        }
      }
    }
  }
  .block-title {
    display: none;
  }
}