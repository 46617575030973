// Fonts
// --------------------------------------------------

// Font weights; more info at https://www.w3.org/TR/css-fonts-3/
// 100 - Thin
// 200 - Extra Light (Ultra Light)
// 300 - Light
// 400 - Normal
// 500 - Medium
// 600 - Semi Bold (Demi Bold)
// 700 - Bold
// 800 - Extra Bold (Ultra Bold)
// 900 - Black (Heavy)
@mixin font-face($name, $font-weight, $font-style, $filename, $token: '') {
  @if ($token != '') {
    @font-face {
      font-family: $name;
      src: url('#{$font-path}/#{$filename}.eot?itok=#{$token}');
      src: url('#{$font-path}/#{$filename}.eot?itok=#{$token}#iefix') format('embedded-opentype'),
        // url('#{$font-path}/#{$filename}.woff2') format('woff2'),
      url('#{$font-path}/#{$filename}.woff?itok=#{$token}') format('woff'),
      url('#{$font-path}/#{$filename}.ttf?itok=#{$token}') format('truetype'),
      url('#{$font-path}/#{$filename}.svg?itok=#{$token}##{$filename}') format('svg');
      font-weight: $font-weight;
      font-style: $font-style;
    }
  } @else {
    @font-face {
      font-family: $name;
      src: url('#{$font-path}/#{$filename}.eot');
      src: url('#{$font-path}/#{$filename}.eot?#iefix') format('embedded-opentype'),
        // url('#{$font-path}/#{$filename}.woff2') format('woff2'),
      url('#{$font-path}/#{$filename}.woff') format('woff'),
      url('#{$font-path}/#{$filename}.ttf') format('truetype'),
      url('#{$font-path}/#{$filename}.svg##{$filename}') format('svg');
      font-weight: $font-weight;
      font-style: $font-style;
    }
  }
}

// ICON FONT
@include font-face('icons', normal, normal, 'icons', $icon-font-token);

// FROM GOOGLE FONTS
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700');
// CUSTOM FONT EXAMPLE
// Regular 400
//@include font-face('Raleway', 400, normal, 'raleway-regular');
//@include font-face('Raleway', 400, italic, 'raleway-regularitalic');
