// User && profile
// --------------------------------------------------

.user {
  position: relative;
}

// Login
body.not-logged-in {
  .region--content {
    @include bp(md) {
      min-height: calc(100vh - 162px - 455.8px);
    }
  }
}

body.not-logged-in.page-user {
  .region--content {
    background-color: $color-grey-dark;
    padding: $gutter-mobile 0;
    @include bp(md) {
      padding: $gutter 0;
    }
    *:not(input) {
      color: $color-white;
    }
  }

  h1 {
    display: none;
  }

  div.tabs {
    display: none;
  }
  form {
    .form-submit {
      background-color: $color-white;
      color: $color-text;
      &:hover {
        background-color: $color-grey;
      }
    }

    .form-submit ~ a {
      @extend .button;
      @extend .button-cta;
      background-color: $color-white;
      color: $color-text;
      &:hover {
        background-color: $color-grey;
        color: $color-text;
      }
    }
  }
  // Panels pane.
  .panel-region--cols-2 {
    .col {
      @include bp(md) {
        padding: 0 2rem;
        margin-top: $default-block-margin-bottom;
        &:last-child {
          border-left: $default-border;
          border-left: $opacity-border;
          margin-top: 0;
        }
      }
      @include bp(lg) {
        padding: 0 4rem;
      }
    }
  }
  .pane-user-login {
    padding-top: $default-block-margin-bottom-mobile;
    border-top: $default-border;
    border-top: $opacity-border;

    @include bp(sm, max) {
      border-bottom: $default-border;
      border-bottom: $opacity-border;
      margin-bottom: 0;
    }
    @include bp(md) {
      padding-top: $default-block-margin-bottom;
    }
  }
  .pane-custom {
    h2 {
      margin-bottom: 1rem;
    }
  }
  .view-full-width .views-row {
    border-color: $color-border;
    border-color: $color-border-opacity;
  }
}

// Profile
// --------------------------------------------------
body.page-profiel {
  .node--webform {
    h1 {
      display: none;
    }
  }
}

body.page-profiel-persoonlijk {
  .panel-region--content {
    > .container > .row > .col-md-8 {
      display: flex;
      flex-wrap: wrap;
      //align-items: center;
      justify-content: space-between;

      > .pane-ie-net-user-userprofile-context-webform {
        width: 100%;
      }
    }
  }
}

body.page-profiel-persoonlijk {
  .form-item-submitted-civicrm-1-contact-1-fieldset-fieldset-civicrm-1-contact-1-contact-birth-date {
    margin-bottom: 0;
  }
}

.memberships-list {
  background-color: $color-grey-light;
  padding: 1.5rem;
  &.empty {
    display: inline-block;
    background-color: $color-primary;
    padding: $gutter/2 2.375rem;
    text-align: center;
    float: right;
    clear: both;
    width: 100%;

    @include bp(md) {
      width: auto;
    }

    h3, div {
      color: $color-white;
    }
    h3 {
      position: relative;
      @include icon('exclamation', after) {
        font-size: 1rem;
        position: absolute;
        top: 0;
        margin-left: 0.375rem;
      }
    }
  }
  ul {
    margin-bottom: 0;
  }
  .intro {
    @include font-size(13, 26);
  }

  a.button {
    width: 100%;
  }
  .disclaimer {
    @include font-size(12);
  }
}

// Login form
// --------------------------------------------------
#user-login-form {
  label {
    @include font-size(12);
    font-weight: 400;
  }
  .form-submit {
    @extend .button-cta;
    font-size: 0.75rem;

  }
  .form-type-textfield input,
  .form-type-password input {
    width: 100%;
  }
  .item-list {
    ul {
      margin-left: 0;
    }
    li {
      list-style: none;
      margin: 0;
    }
  }
}
