// Region: Toolbar
// --------------------------------------------------

.region--toolbar {

  padding: 0;
  margin: 0;
  background-color: $color-grey-dark;
  color: $color-white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: all $default-transition;
  .admin-menu &,
  .adminimal-menu & {
    top: 1.8125rem;
  }
  .block {
    margin: 0;
  }
  .toolbar-region-wrapper {
    max-height: $toolbar-height-md;
    height: $toolbar-height-md;
    position: relative;
  }
  @include bp(md) {
    background-color: $color-primary;
  }
  @include bp(sm, max) {
    text-transform: uppercase;
    .container {
      padding: 0;
    }
    .toolbar-region-wrapper {
      display: table;
      width: 100%;
      max-height: $toolbar-height-sm;
      height: $toolbar-height-sm;
    }
    #block-ie-net-afdeling-afdelingen-dropdown-mobile,
    .top-menu-mobile {
      float: left;
      width: 50%;
      display: block;
      text-align: center;
      text-align-last:center;
    }

    .top-menu-mobile {
      p, a {
        margin: 0;
        padding: 0;
      }
      .content a {
        @include font-size(12);
        line-height: $toolbar-height-sm;
        text-decoration: none;
        color: $color-white;

        .logged-in & {
          &:first-child() {
            margin-right: 15px;


            &:after {
              content: "|";
              padding-left: 15px;
            }

            @include icon(user);
            @include hide-icon-text(20, $color-white);
          }
        }

        &:hover, &:active {
          background-color: lighten($color-grey-dark, 15%);
        }
      }
    }
  }

}

