// Status messages
.crm-container {
  .messages {
    margin-bottom: $default-block-margin-bottom;
    .icon, .crm-i {
      display: none;
    }
  }
  .help {
    @extend .messages;
    @extend .status;
    ul, ol, p, a, img {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .eventsreg-preview {
      border: 0;
      padding: 0;
      margin: 0;

      p {
        margin-bottom: 0.5rem;
        padding-left: 4rem;
      }
    }
  }
}