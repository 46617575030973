.node--magazine {
  .field--node-link,
  .field--file {
    display: inline-block;
    margin-right: 1rem;
    float: left;

    a {
      padding: 0.5rem 1rem;
      font-weight: 600;
      @extend .button;
      margin: 0;
    }

    &:hover {
      a:after {
        color: $color-white;
      }
    }
  }
  .field--file {
    a {
      @extend .button-inverse;
      height: 38px;

      @include icon(arrow-down, after) {
        @include font-size(14);
        margin-left: 0.5rem;
      }
    }
  }
}

.node--magazine--full {
  > .ds-center {
    margin-bottom: $default-block-margin-bottom-mobile;
    @include bp(md) {
      margin-bottom: $default-block-margin-bottom;
      display: table;
      .ds-second, .ds-first {
        display: table-cell;
        float: none;
      }
      .ds-first {
        vertical-align: top;
      }
      .ds-second {
        vertical-align: middle;
      }
    }
  }
}

.node--magazine--teaser,
.node--magazine--protected-content-teaser {
  border-bottom: 1px solid $color-grey;
  padding-bottom: 3rem;
  @include row();
  .image-wrapper,
  .content-wrapper {
    @include col();
  }
  @include bp(sm) {
    .image-wrapper {
      @include cols(3)
    }
    .image-wrapper + .content-wrapper {
      @include cols(9);
    }
    .content-wrapper {
      @include cols(12);
    }
  }
  @include bp(lg) {
    .image-wrapper {
      @include cols(2)
    }
    .image-wrapper + .content-wrapper {
      @include cols(10);
    }
    .content-wrapper {
      @include cols(12);
    }
  }

  .ds-header {
    margin-bottom: 1rem;
  }
  .field--body {
    margin-bottom: 2rem;
  }

  .field--title {
    * {
      @include font-size(19);
    }
  }
}

.node--magazine--small-teaser {
  > .ds-center {
    margin: 1rem auto;
  }
  h3 {
    @include font-size(14, 22);
    font-weight: 400;
  }
}
.node--magazine--homepage {
  @include bp(md) {
    .ds-first {
      @include cols(4);
    }
    .ds-second {
      @include cols(8);
    }
  }
  .field--body {
    margin-bottom: $default-block-margin-bottom-mobile;
    @include bp(md) {
      margin-bottom: $default-block-margin-bottom;
    }
  }
}

// only on magazine detail page

.node-type-magazine {
  .node--magazine {
    margin-bottom: $default-block-margin-bottom;
  }
}
