// Panels regions
// --------------------------------------------------
.panel-region--content {
  &.extendfull {
    padding-top: $gutter-mobile;
    padding-bottom: $gutter-mobile;
    @include bp(md) {
      padding-top: $gutter;
      padding-bottom: $gutter;
    }
  }
}

.panel-region--content-b,
.panel-region--content-c {
  .seagul-theme-panels-cols {
    padding-top: $gutter-mobile;
    @include bp(md) {
      padding-top: $gutter;
    }
    &:first-child {
      padding-top: 0;
    }
  }
}

.panel-region--content-b {
  .seagul-theme-panels-cols {
    @include bp(md) {
      padding-top: 0;
    }
  }
}

// Panels
// --------------------------------------------------
.panel-home {
  .panel-region--content {
    margin: $gutter-mobile 0;
    @include bp(md) {
      margin: $gutter 0;
    }
    &:first-child {
      margin-top: 0;
    }
    .panel-pane {
      &:first-child {
        margin-bottom: 0;
      }
    }

    ul.pager {
      margin-top: $default-block-margin-bottom;
    }
  }
  .panel-region--content-hero {
    //margin-top: 0;
  }
  .hero-content {
    position: absolute;
    top: $default-block-margin-bottom;
    z-index: 99;

    @include bp(lg) {
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
  }

  .word-lid-home-block {
    @include bp(md, max) {
      @include gutter(margin-left, true, true);
      @include gutter(margin-right, true, true);
      margin-bottom: 0;
    }
    @include bp(lg) {
      position: absolute;
      top: 0;
      right: $gutter;
      padding: 0;
      max-width: 36%;
    }
    @include bp(xl) {
      max-width: 27rem;
    }

    .pane-content {
      @extend .row;
      position: relative;
    }
    .entity-bean {
      padding: $gutter-mobile;
      background-color: $color-primary;
      color: $color-grey;
      @include bp(md) {
        padding: $gutter;
      }
      h2, h3, h4, h5, h6 {
        color: $color-white;
      }
      h3 {
        @include font-size(24, 48)
      }
      ul li {
        padding-left: 1.375rem;
        &:before {
          @include font-size (15, 20);
          color: $color-grey;
          font-size: 0.75rem;
        }
      }
      .field--bean-text {
        @include font-size (15, 36);
        font-weight: 400;
      }
      .field--bean-cta-multiple,
      .field--bean-cta {
        a {
          width: 100%;
          padding: 0.75rem;
          @extend .button-primary;
          &:hover {
            border: 1px solid darken($color-primary, 10);
          }
        }
      }
    }
  }
}

.panel-onecol-stacked {
  .panel-pane {
    &:last-child {
      //margin-bottom: 0;
    }
  }
  .panel-region--bottom {
    @extend .extendfull;
    background-color: $color-grey;
  }
}

// Panes
// --------------------------------------------------
.panel-pane {
  position: relative;
  margin-bottom: $gutter-mobile;
  @include bp(md) {
    margin-bottom: $gutter;
  }
}

/*.pane-title {
  @include font-size(21, 28);
  font-weight: 600;
  margin-bottom: 1rem;
  @include bp(md) {
    margin-bottom: 2rem;
  }
}*/

.pane-nieuws-laatste-nieuws {
  .pane-title {
    padding: 0;
  }
}

.pane-adverteren-info-bean-adverteren-ilya-beans {
  padding-top: $gutter-mobile;
  padding-bottom: $gutter-mobile;
  @include bp(md) {
    padding-top: $gutter;
    padding-bottom: $gutter;
  }
}
.pane-civicrm-contacts-current-user-civi-profile {
  @include bp(md) {
    margin-bottom: 3.75rem;
  }
}


.page-nieuws-ilya-magazine .panel-region--top .container {
  @include clearfix;
  border-bottom: $default-border;
  margin-bottom: 3rem;
}

.pane-ilya-intro,
.pane-ie-net-user-ilya-abo {
  @include col();
}
.pane-ilya-intro {
  padding-left: 0;
  @include cols(12);

  @include bp(md) {
    @include cols(9);
  }
}
.pane-ie-net-user-ilya-abo {
  @include cols(12);

  @include bp(md) {
    @include cols(3);
  }

  background-color: $color-primary;
  color: $color-white;
  padding: $gutter/2;
  text-align: center;
  @include font-size(18);

  .ilya-abo-price {
    @include font-size(24);
    //text-align: center;
  }
  .ilya-abo-link a {
    margin-top: 15px;
    @extend .button;
    @extend .button-cta;
    @extend .button-primary;
    display: block;
  }

}
