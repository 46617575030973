.node--faq--teaser, .view-faq {
  margin-bottom: 0;

  .field--title, .views-field-title {
    padding-left: 1.25rem;
    position: relative;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    line-height: 18px;

    @include icon(arrow-right) {
      position: absolute;
      left: 0;
      top: 5px;
      @include font-size(16);
    }
  }
}

// Faq contact block
.page-faq {

  .panel-region--bottom {
    background-color: $color-primary;
    color: $color-white;

    .panel-pane {
      margin: 0;
      @include col();
      @include bp(md) {
        @include cols(6);
      }
    }

    .node--webform {
      padding-top: 2rem;

      .field--title * {
        @include font-size(26);
        color: $color-white;
      }
      .field--body {
        padding-bottom: 2rem;
      }
    }

    .pane-content p {
      margin: 0;
      img {
        display: block;
      }
    }

    @include bp(sm, max) {
      .faq-contact-block-image {
        display: none;
      }
    }

    @include bp(md) {
      .container {
        display: flex;
      }
      .faq-contact-block-image .pane-content {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: 690px;
        width: 690px;
      }
    }

  }

}
