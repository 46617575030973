.page-jobportaal {
  #page-title {
    @include hide-element();
  }
}


.page-jobportaal-bedrijven {

  .region-page-category .page-category-text {
    background: $jp-color-blue;
    color: $color-white;
  }

  .panel-region--content-hero {
    min-height: 300px;
    background: url('/sites/all/themes/seagull_theme/images/u804.jpg') no-repeat center center transparent;
    background-size: cover;

    margin-bottom: $gutter-mobile * 2;

    @include bp(md) {
      margin-bottom: $gutter * 2;
    }
  }

  .panel-region--content-b {
    background: $jp-color-blue;
  }

  @include bp(md) {
    .panel-region--content-d {
      > .container {
        > .row {
          display: flex;
          flex-wrap: wrap;

          > .col {
            display: flex;

            > .pane-block {
              display: flex;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}


.page-jobportaal-vacatures {

  .region--content-info {
    background: $color-grey-light;

    > .container {
      padding-bottom: 1px;
    }
  }

  .region-page-category {
    margin-bottom: 0;
  }

  .panel-region--top {
    background: $color-grey-light;
    display: inline-block;
    margin-bottom: $gutter-mobile/2;
    width: 100%;

    @include bp(md) {
      margin-bottom: $gutter/2;
    }

    .panel-pane {
      margin-bottom: $gutter-mobile/2;

      @include bp(md) {
        margin-bottom: $gutter/2;
      }
    }
  }
}

.page-node-sollicitaties {
  .node--vacancy--vacancy-soll-admin {
    margin-bottom: $gutter-mobile;

    @include bp(md) {
      margin-bottom: $gutter;
    }

    fieldset {
      legend {
        margin-bottom: 0;
        width: 100%;
      }

      .fieldset-wrapper {
        table {
          margin-bottom: 0;
        }
      }
    }
  }
}

#webform-component-include-cv {
  background-color: #f0f0f0;

  > label,
  > .form-radios {
    background-color: #f0f0f0;
  }

  > label {
    border-bottom: solid 1px #dedede;
    color: $color-grey-dark;
    width: 100%;
    display: block;
    margin: 0;
    margin-top: 0 !important;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    @include font-size(14);
    background-color: #f0f0f0;
    padding: 0.75rem;
  }

  > .form-radios {
    padding: 1.5rem;
  }

  .form-item-submitted-include-cv {
    display: inline-block;
    width: auto;
    margin-right: 1rem;

    > label {
      width: auto;
    }
  }
}
