// Confirm
// --------------------------------------------------
.crm-event-confirm-form-block .eventsreg-block,
.crm-contribution-confirm-form-block .memberreg-block,
.crm-event-thankyou-form-block .eventsreg-block,
.crm-contribution-thankyou-form-block .memberreg-block {
  /*  .eventsreg-content,
    .memberreg-content {
      padding: $gutter-mobile;
    }*/
  .crm-profile-view-title,
  .crm-contribution-info-title,
  .crm-event-info-title {
    @extend h3;
  }
  .crm-event-info-item,
  .crm-public-form-item,
  .crm-contribution-info-item,
  .eventsreg-content .crm-section,
  .memberreg-content .crm-section {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    @include clearfix();
    @include bp(md) {
      @include row();
    }
    .label,
    .content {
      @include font-size(14, 22);
      @include bp(md) {
        @include col();
      }
    }
    .label {
      font-weight: 700;
      @include bp(md) {
        @include cols(3);
      }
      /*@include bp(xl) {
              @include cols(5)
            }*/
    }
    .content {
      @include bp(md) {
        @include cols(9);
      }
      /* @include bp(xl) {
              @include cols(7)
            }*/
    }
  }
}

.event_info_link-section {
  a {
    @extend .button;
    @extend .button-primary;
    &:after {
      content: none !important;
    }
  }
}

.pay_later_receipt-section {
  .content {
    width: 100% !important;
  }
  ul, ol, p, a, img {
    &:last-child {
      margin-bottom: 0;
    }
  }
}