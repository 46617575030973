
// Style guide
// ---------------------------------------------------------------------

.sg-intro {
  padding-bottom: 0;
  margin-bottom: 4rem;
  border-bottom: $default-border;
}

.sg-section {
  @extend .clearfix;
  margin-bottom: 4rem;
}

// INDEX

#sg-index-list {
  
}

// COLORS

.sg-color-block {
  float: left;
  margin: 0 1rem 1rem 0;
  padding: 0.5rem;
  border: $default-border;
  border-radius: 4px;
  width: 100px;
  height: 60px;
  @include bp(lg) {
    width: 100px;
    height: 100px;
  }
  span {
    display: block;
    clear: both;
    &.color-name {
      @include font-size(14);
      font-weight: 600;
      margin-bottom: 0.2rem;
    }
    &.color-hex {
      @include font-size(12);
      font-family: Consolas, Monaco, 'Andale Mono', monospace;
    }
  }
  &.white {
    color: $color-white;
  }
}
.sg-color-block-large {
  width: 140px;
  height: 140px;
  @include bp(lg) {
    width: 160px;
    height: 160px;
  }
}

// CODE EXAMPLES

.sg-example,
.sg-code {
  position: relative;
  padding: 3rem 1rem 1rem !important;
  margin: 0 0 1rem 0 !important;
  background: $color-white !important;
  border: dashed 1px $color-border;
  border-radius: 4px;
  &:before {
    display: block;
    position: absolute;
    left: -1px;
    top: -1px;
    padding: 0 1rem;
    
    font-family: 'Arial', 'Helvetica', 'sans-serif';
    @include font-size(12, 20);
    text-transform: uppercase;
    color: $color-muted;
    background: $color-white;
    border: dashed 1px $color-border;
    border-radius: 4px;
  }
}

.sg-example {  
  &:before {
    content: "Example";
  }
}
.sg-code {  
  &:before {
    content: "Code";
  }
}

// ICONS

.sg-icon {
  // float: left;
  position: relative;
  @include gutter(margin-bottom);
  background: $color-white;
  border: $default-border;
  border-radius: $default-border-radius;
  height: 100px;
  @include bp(lg) {
     height: 120px;
  }
  span {
    display: block;
    clear: both;
    padding: 0.5rem;
    &.icon-graph {
      text-align: center;
      &:before {
        display: block;
        @include font-size(24, 0);
        line-height: 1;
        margin: 0 !important;
      }
      @include bp(lg) {
        margin-top: 0.5rem;
        &:before {
          @include font-size(32, 0);
        }
      }
    }
    &.icon-name {
      position: absolute;
      bottom: 0;
      width: 100%;
      @include font-size(12);
      font-weight: 600;
      text-align: center;
      background: $color-grey;
      border-bottom-left-radius: $default-border-radius;
      border-bottom-right-radius: $default-border-radius;
    }
  }
}

// GRID

.sg-grid-col {
  @include gutter(margin-bottom);
  > span {
    display: block;
    width: 100%;
    height: ptr(50);
    @include font-size(12, 50);
    text-align: center;
    border: $default-border;
    background: $color-grey;  
    white-space: nowrap;
  }
  p {
    padding: 1rem;
    border: $default-border;
    background: $color-grey;  
  }
}

.sg-stripes-background {
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    rgba($color-black, 0.1) 10px,
    rgba($color-black, 0.1) 20px
  );
}
